import { gql } from "@apollo/client";

export const GET_ADMIN_CONVERSATION = gql`
  query ($limit: Int, $page: Int, $userType: UserType, $keyword: String) {
    adminGetConversations(
      paginationInput: { limit: $limit, page: $page }
      filterInput: { userType: $userType, keyword: $keyword }
    ) {
      data {
        id
        seen
        updatedAt
        createdAt
        type
        messages {
          id
          message
          seen
          user {
            id
            firstName
            middleName
            lastName
            profileImage
          }
        }
        users {
          id
          firstName
          middleName
          lastName
          profileImage
          createdAt
          roles
          phone
          email
          taskCount
          taskCompletedCount
          orderCount
          address {
            country
            city
            banchi
            municipality
            prefecture
            town
            buildingName
            roomNumber
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_ORDER_CONVERSATION = gql`
  query (
    $limit: Int
    $page: Int
    $orderNumberSearch: String
    $conversationId: String!
  ) {
    adminGetOrderConversations(
      conversationId: $conversationId
      paginationInput: { limit: $limit, page: $page }
      orderNumberSearch: $orderNumberSearch
    ) {
      data {
        id
        seen
        message
        conversation {
          id
          messages {
            id
            message
          }
        }
        order {
          id
          status
          orderNumer
          updatedAt
        }
        user {
          id
          firstName
          middleName
          lastName
          profileImage
        }
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;
