import React, { Dispatch, SetStateAction } from "react";

import { Table } from "Components";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { DefaultImg, TaskStatusComponent } from "ReuseableComponents";
import { getFullName } from "utils";

// import { columns } from "./columns";

interface TableComponentProps {
  data: Array<[]>;
  loading: boolean;
  rowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  metaData?: {
    limit: number;
    page: number;
    total: number;
  };
  currentPage: number;
}

export const TableComponent = ({
  data,
  loading,
  metaData,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}: // rowsPerPage,
TableComponentProps) => {
  //var
  const navigate = useNavigate();
  //columns
  const columns: Array<object> = [
    {
      name: "task",
      // selector: (row: { serviceName: string }) => row.serviceName,
      cell: (row: {
        task: { id: string; name: string };
        status: string;
        id: string;
        customer: { id: string };
        serviceName: string;
      }) => {
        const getServiceName = () => {
          let value = "";
          // if (item.name.toLowerCase() === "visa") {
          //   Object.keys(item?.metaData).forEach(
          //     (data, idx) => {
          //       if (
          //         data === "AbroadVisa" ||
          //         data === "JapanVisa"
          //       ) {
          //         value = data;
          //       } else {
          //         return;
          //       }
          //     }
          //   );
          // }
          // if (item.name.toLowerCase() === "passport") {
          //   value = item.serviceName;
          // }
          if (row.task.name.toLowerCase() === "design") {
            value = row.serviceName.replace(/\s/g, "").split("-")[1];
          }
          return value;
        };

        const getServiceTypeName = () => {
          let value = "";
          if (row.task.name.toLowerCase() === "visa") {
            value = row.serviceName.replace(/\s/g, "").split("-")[1];
          }
          // if (item.name.toLowerCase() === "visa") {
          //   Object.keys(item.metaData).forEach(
          //     (data, idx) => {
          //       if (data === "AbroadVisa") {
          //         value =
          //           item.metaData[data].country.name;
          //       } else if (data === "JapanVisa") {
          //         Object.keys(
          //           item.metaData[data]
          //         ).forEach((item) => {
          //           if (
          //             item === "coe" ||
          //             item === "visaRenewal"
          //           ) {
          //             value = item;
          //           }
          //         });
          //       } else {
          //         return;
          //       }
          //     }
          //   );
          // }

          return value;
        };

        const getServiceTypeListName = () => {
          let value = "";
          // if (item.name.toLowerCase() === "visa") {
          //   Object.keys(item.metaData).forEach(
          //     (data, idx) => {
          //       if (data === "JapanVisa") {
          //         Object.keys(
          //           item.metaData[data]
          //         ).forEach((list) => {
          //           value =
          //             item.metaData[data][list].type.name;
          //         });
          //       } else {
          //         return;
          //       }
          //     }
          //   );
          // }
          return value;
        };
        return (
          <Link
            to={"#"}
            // to={`/create-order/${
            //   row.task.name.toLowerCase() === "design" ||
            //   row.task.name.toLowerCase() === "passport" ||
            //   row.task.name.toLowerCase() === "visa" ||
            //   row.task.name.toLowerCase() === "translation"
            //     ? `?type=${row.task?.name.toLowerCase()}&`
            //     : "?"
            // }${
            //   row.task.name.toLowerCase() === "visa" ||
            //   row.task.name.toLowerCase() === "design" ||
            //   row.task.name.toLowerCase() === "passport"
            //     ? `serviceName=${getServiceName()}&`
            //     : ""
            // }${
            //   row.task.name.toLowerCase() === "visa"
            //     ? `serviceType=${getServiceTypeName()}&`
            //     : ""
            // }${
            //   row.task.name.toLowerCase() === "visa"
            //     ? `serviceTypeList=${getServiceTypeListName()}&`
            //     : ""
            // }tab=${
            //   row.task.name.toLowerCase() === "visa" &&
            //   getServiceTypeName() === "coe"
            //     ? "02"
            //     : "01"
            // }&taskId=${row.task.id}&userId=${
            //   row.customer.id
            // }&status=${getTaskStatus(row.status)}&orderId=${row.id}  `}
          >
            {row.serviceName}
          </Link>
        );
      },
    },
    {
      name: "Order Date",
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => {
        return format(new Date(row.createdAt), "dd MMMM, yyyy");
      },
    },

    {
      name: "client",
      cell: (row: {
        customer: {
          id: string;
          firstName: string;
          lastName: string;
          middleName: string;
          profileImage: string;
        };
      }) => {
        return (
          <Link
            to={`/clients/${row?.customer?.id}`}
            className="d-flex align-items-center"
          >
            {row?.customer?.profileImage ? (
              <img
                src={row?.customer?.profileImage}
                alt=""
                className="img-fluid me-2"
                style={{
                  borderRadius: "50%",
                  height: 28,
                  width: 28,
                }}
              />
            ) : (
              <DefaultImg
                className="p-2 me-2"
                style={{
                  height: 28,
                  width: 28,
                }}
              />
            )}

            <p>
              {getFullName(
                row?.customer?.firstName,
                row?.customer?.middleName,
                row?.customer?.lastName
              )}
            </p>
          </Link>
        );
      },
    },
    {
      name: "admin",
      cell: (row: {
        admin: {
          id: string;
          firstName: string;
          lastName: string;
          middleName: string;
          profileImage: string;
        };
      }) => {
        return (
          <Link
            to={`/teams/${row?.admin?.id}`}
            className="d-flex align-items-center text-decoration-none"
          >
            {row?.admin?.profileImage ? (
              <img
                src={row?.admin?.profileImage}
                alt=""
                className="img-fluid me-2"
                style={{
                  borderRadius: "50%",
                  height: 28,
                  width: 28,
                }}
              />
            ) : (
              <DefaultImg
                className="p-2 me-2"
                style={{
                  height: 28,
                  width: 28,
                }}
              />
            )}

            <p>
              {getFullName(
                row?.admin?.firstName,
                row?.admin?.middleName,
                row?.admin?.lastName
              )}
            </p>
          </Link>
        );
      },
    },
    {
      name: "status",
      cell: (row: { status: string }) => {
        return <TaskStatusComponent type={row.status} />;
      },
    },
  ];

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={metaData?.total || 0}
        pagination={true}
        handlePagination={handlePagination}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        variant="primary"
        showHeader={true}
        showSubHeader={false}
        loading={loading}
        setCurrentPage={setCurrentPage}
        name="All Task"
        // onRowClicked={(row: any) =>
        //   navigate(
        //     `/order/${row.id}?status=${getTaskStatus(row.status)}&tab=${"01"}`
        //   )
        // }
      />
    </>
  );
};
