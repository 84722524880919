import React from "react";

import { adminTaskStatus } from "utils";

export const TaskStatusComponent = ({
  type,
  showBadge,
}: {
  type: string;
  showBadge?: string;
}) => {
  //@ts-ignore
  const statusType = adminTaskStatus[type];

  return (
    <div
      className="d-flex align-items-center px-2 py-1"
      style={{
        backgroundColor: statusType?.bgColor,
        borderRadius: 4,
        color: statusType?.textColor,
      }}
    >
      {showBadge !== "no" && statusType?.icon}
      <p className="labelThree" style={{ color: statusType?.textColor }}>
        {statusType?.name}
      </p>
    </div>
  );
};
