import "./styles.scss";

import React, { useState } from "react";

import logoutIcon from "assets/icons/logout.svg";
import mailIcon from "assets/icons/mail.svg";
import appLogo from "assets/images/logo/logoSecond.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "ReuseableComponents";
import {
  navItems,
  removeLocalStorage,
  useChatContext,
  useUserContext,
  useUserRole,
} from "utils";
import client from "utils/graphql";

export const Sidebar = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const { userRole } = useUserRole();
  const { isSeen } = useChatContext();
  const [hoverState, setHoverState] = useState({
    hover: false,
    index: Number(),
  });
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    removeLocalStorage("token");
    removeLocalStorage("userType");
    setUser(null);
    client.resetStore();
    setShowModal(false);
    navigate("/login");
  };

  return (
    <>
      <div className="sidebar">
        <div className="logo text-center">
          <Link to="/">
            <img src={appLogo} alt="logo" className="img-fluid" />
          </Link>
        </div>
        <div className="sidebar-nav">
          <div className="flex-grow-1">
            {navItems.map((item, index) => {
              const isAccess = item.role.includes(userRole);
              return (
                <Link
                  key={index}
                  to={item.link}
                  className={`navItem d-flex bodyTwo align-items-center mb-1 ${
                    location.pathname === item.link && "active"
                  } ${!isAccess && "d-none"} ${
                    hoverState.hover && hoverState.index === index && "hover"
                  }`}
                  onMouseOver={() =>
                    setHoverState({
                      hover: true,
                      index: index,
                    })
                  }
                  onMouseLeave={() =>
                    setHoverState({
                      hover: false,
                      index: Number(),
                    })
                  }
                >
                  <div className="d-flex flex-grow-1">
                    {location.pathname === item.link ||
                    (hoverState.hover && hoverState.index === index)
                      ? item?.activeIcon || item.icon
                      : item.icon}
                    <p className="navItem-title"> {item.title}</p>
                  </div>
                  {item.title === "chat" && !isSeen && (
                    <div className="notSeen">
                      <img alt="mail" src={mailIcon} />{" "}
                    </div>
                  )}
                </Link>
                // </div>
              );
            })}{" "}
          </div>
        </div>
        <div className="sidebar-nav logout mt-0 position-fixed bottom-0 mb-4">
          <button
            onClick={() => setShowModal(true)}
            className="navItem border-0 bg-transparent d-flex"
          >
            <img src={logoutIcon} className="img-fluid me-2" alt="logout" />
            <p className="navItem-title"> Logout</p>
          </button>
        </div>
      </div>

      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Logout?"
        showFooter={true}
        btnPrimaryText="Yes, logout"
        btnSecondaryText="Go back"
        btnPrimaryClick={handleLogout}
      >
        <p>Are you sure you want to logout?</p>
      </ModalComponent>
    </>
  );
};
