import React from "react";

import { taskType } from "utils";

export const TaskTypeComponent = ({ type }: { type: string }) => {
  //@ts-ignore
  const taskTypeData = taskType[type.split(" ").join("_").toUpperCase()];

  return (
    <div
      className="d-flex align-items-center px-2 py-1 mx-1"
      style={{
        backgroundColor: taskTypeData?.bgColor,
        width: "fit-content",
        borderRadius: 4,
      }}
    >
      {taskTypeData?.icon}
      <p
        className={`labelThree task-name`}
        style={{
          color: taskTypeData?.textColor,
        }}
      >
        {taskTypeData?.name.replace(/_/g, " ")}
      </p>
    </div>
  );
};
