import React from "react";

import { DesignAndTranslationComponent } from "./Component/DesignAndTranslationComponent/DesignAndTranslationComponent";
import { PassportComponent } from "./Component/PassportComponent/PassportComponent";
import { TranslationComponent } from "./Component/TranslationComponent/TranslationComponent";
import { VisaComponent } from "./Component/VisaComponent/VisaComponent";

interface DetailsProps {
  taskData: any;
}

export const Details = ({ taskData }: DetailsProps) => {
  const taskTypeComponent = () => {
    switch (taskData?.task?.type) {
      case "DESIGN_AND_PRINTING":
        return (
          <DesignAndTranslationComponent
            details={{
              type: taskData.task.type,
              ...taskData.metaData.details,
            }}
          />
        );
      case "VISA":
        let uploadData = taskData.metaData.AbroadVisa
          ? taskData.metaData.AbroadVisa
          : taskData.metaData.JapanVisa;

        return (
          <VisaComponent
            details={{ ...uploadData, serviceName: taskData.serviceName }}
          />
        );
      case "TRANSLATION":
        return <TranslationComponent details={taskData} />;
      case "PASSPORT":
        return <PassportComponent details={taskData} />;
      default:
        break;
    }
  };
  return <div>{taskTypeComponent()}</div>;
};
