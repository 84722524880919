import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Card, Label } from "reactstrap";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import {
  getServiceTaskName,
  getStatusVal,
  splitByCapitalLetter,
  useFileUpload,
  useUserRole,
} from "utils";

interface UploadComponentProps {
  pageFormData?: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const UploadComponent = ({
  translationPostData,
  pageFormData,
  setTranslationPostData,
}: UploadComponentProps) => {
  const [fileList, setFileList] = useState({} as any);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const getTypeParams = searchParams.get("type");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParams = searchParams.get("serviceName");
  const serviceTypeParams = searchParams.get("serviceType");
  const serviceTypeListParams = searchParams.get("serviceTypeList");
  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const { uploadFile, uploadDataLoading, deleteFile } = useFileUpload();

  useEffect(() => {
    if (serviceNameParams === "JapanVisa" && serviceTypeParams) {
      if (
        translationPostData.metadata?.[serviceNameParams]?.[serviceTypeParams]
          .upload
      ) {
        setFileList(
          translationPostData.metadata?.[serviceNameParams]?.[serviceTypeParams]
            .upload
        );
      }
    }
    if (serviceNameParams === "AbroadVisa") {
      if (translationPostData.metadata?.[serviceNameParams]?.upload) {
        setFileList(translationPostData.metadata?.[serviceNameParams]?.upload);
      }
    }
  }, [translationPostData.metadata, serviceNameParams, serviceTypeParams]);

  const removeFile = (url?: string, parentName?: string, listName?: string) => {
    deleteFile({
      variables: {
        fileUrl: url,
      },
    }).then((res) => {
      let data = fileList;
      if (parentName && listName) {
        data[parentName][listName] = "";
      } else if (parentName && !listName) {
        data[parentName] = "";
      }
      setFileList(data);
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    parentName?: string
  ) => {
    try {
      uploadFile({
        variables: {
          image: e.target.files?.[0],
          prefix: "ORDER",
        },
      }).then((res) => {
        if (parentName) {
          setFileList({
            ...fileList,
            [parentName]: {
              ...fileList[parentName],
              [e.target.name]: res.data?.uploadFile,
            },
          });
        } else {
          setFileList({
            ...fileList,
            [e.target.name]: res.data?.uploadFile,
          });
        }
      });
    } catch (error) {}
  };

  const onSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (
        serviceNameParams &&
        serviceNameParams === "JapanVisa" &&
        serviceTypeParams
      ) {
        setTranslationPostData({
          ...translationPostData,
          metadata: {
            ...translationPostData.metadata,
            [serviceNameParams]: {
              [serviceTypeParams]: {
                ...translationPostData.metadata?.[serviceNameParams][
                  serviceTypeParams
                ],
                upload: fileList,
              },
            },
          },
        });
      } else if (serviceNameParams) {
        setTranslationPostData({
          ...translationPostData,
          metadata: {
            ...translationPostData.metadata,
            [serviceNameParams.split(" ").join("")]: {
              ...translationPostData?.metadata[
                serviceNameParams.split(" ").join("")
              ],
              upload: fileList,
            },
          },
        });
      }

      navigate(
        `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParams}&serviceType=${serviceTypeParams}&serviceTypeList=${serviceTypeListParams}&tab=03&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
          orderIdParams ? `&orderId=${orderIdParams}` : ""
        }&isAdmin=${isAdminParams}`
      );
    },
    [
      fileList,
      navigate,
      orderIdParams,
      getTypeParams,
      pathname,
      statusParams,
      setTranslationPostData,
      userIdParams,
      translationPostData,
      taskIdParams,
      serviceNameParams,
      serviceTypeListParams,
      serviceTypeParams,
      isAdminParams,
    ]
  );

  return (
    <div>
      <Card className="border-0 p-4">
        {serviceTypeParams === "coe" && (
          <div className="mb-4 d-flex">
            <h4 className="titleFive">
              {getServiceTaskName(
                serviceNameParams || "",
                serviceTypeParams || "",
                serviceTypeListParams || ""
              )}
            </h4>
          </div>
        )}
        {pageFormData?.upload &&
          Object.keys(pageFormData?.upload).map((item) => {
            return (
              <div>
                <h4 className="titleFour text-capitalize mb-3">
                  {" "}
                  {splitByCapitalLetter(item)}{" "}
                </h4>
                <div className="d-flex w-100 justify-content-between">
                  {typeof pageFormData.upload[item] === "string" ? (
                    <div className="w-100 me-3">
                      <PictureUpload
                        name={item}
                        onChange={(e) => handleChange(e)}
                        filePreview={fileList?.[item]}
                        handleDeleteClick={() =>
                          removeFile(fileList?.[item], item)
                        }
                        disabled={
                          statusParams === "completed" ||
                          !getStatusVal(
                            userRole,
                            orderIdParams || "",
                            isAdminParams || ""
                          )
                            ? true
                            : false
                        }
                      />
                      <Label className="bodyTwo text-capitalize">
                        {" "}
                        {splitByCapitalLetter(item)}{" "}
                      </Label>
                    </div>
                  ) : (
                    Object.keys(pageFormData.upload[item]).map((list) => {
                      return (
                        <div className="w-100 me-3">
                          <PictureUpload
                            name={list}
                            onChange={(e) => handleChange(e, item)}
                            filePreview={fileList?.[item]?.[list]}
                            handleDeleteClick={() =>
                              removeFile(fileList?.[item]?.[list], item, list)
                            }
                            disabled={
                              statusParams === "completed" ||
                              !getStatusVal(
                                userRole,
                                orderIdParams || "",
                                isAdminParams || ""
                              )
                                ? true
                                : false
                            }
                          />
                          <Label className="bodyTwo text-capitalize">
                            {" "}
                            {splitByCapitalLetter(list)}{" "}
                          </Label>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            );
          })}
      </Card>
      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mt-4 d-flex justify-content-end">
            <CustomButton
              btnName="Save"
              classNames="px-5"
              handleBtnClick={(e: MouseEvent<HTMLButtonElement>) => onSubmit(e)}
            />
          </div>
        )}
    </div>
  );
};
