import './styles.scss';

import React, { ComponentProps } from 'react';

import { Search } from 'lucide-react';
import { Input } from 'reactstrap';

interface FilterSearchProps {
  field?: ComponentProps<typeof Input>["field"];
  placeholder: string;
  classNames?: string;
}

export const FilterSearch = ({
  field,
  placeholder,
  classNames,
}: FilterSearchProps) => {
  return (
    <div className={`filter-search-input ${classNames}`}>
      <Input
        className="input"
        type="text"
        placeholder={placeholder}
        {...field}
      />
      <Search className="search-icon" />
    </div>
  );
};
