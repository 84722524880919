import React, { useEffect, useState } from "react";

import axios from "axios";
import { format } from "date-fns";
import { REJECT_ADMIN_ORDER, UPDATE_ADMIN_ORDER } from "queries/adminOrder";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Form } from "reactstrap";
import { CustomButton, InputText, ModalComponent } from "ReuseableComponents";
import { getFullName, getStatusVal, useUserRole } from "utils";

import { useMutation } from "@apollo/client";

interface OrderTaskBtnProps {
  showStartBtn: boolean;
  showRejectBtn: boolean;
  filesUrl?: any;
  completedDataVal: any;
}

interface InputProps {
  rejectReason: string;
}

export const OrderTaskBtn = ({
  showStartBtn,
  showRejectBtn,
  filesUrl,
  completedDataVal,
}: OrderTaskBtnProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderIdParams = searchParams.get("orderId");

  const statusParams = searchParams.get("status");

  const [loading, setLoading] = useState(false);

  const isAdminParams = searchParams.get("isAdmin");

  const [showModal, setShowModal] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);

  const { userRole } = useUserRole();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InputProps>({
    defaultValues: {
      rejectReason: "",
    },
  });

  const [
    rejectCustomerOrder,
    { loading: orderRejectLoading, data: orderRejectData },
  ] = useMutation(REJECT_ADMIN_ORDER, {
    errorPolicy: "all",
  });

  const [
    updateAdminOrder,
    { loading: orderUpdateLoading, data: orderUpdateData },
  ] = useMutation(UPDATE_ADMIN_ORDER, {
    errorPolicy: "all",
    context: {
      headers: {
        "Content-Type": "application/json",
        "x-apollo-operation-name": "updateAdminOrder",
      },
    },
  });

  const handleRejectTask = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (orderRejectData) {
      toast.success("Order Rejected");
      navigate("/");
    }
  }, [orderRejectData, navigate]);

  const handleTakeOrder = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/admin/orders", {
        orderId: orderIdParams,
      });

      navigate("/");

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onsubmit = (data: { rejectReason: string }) => {
    rejectCustomerOrder({
      variables: {
        orderId: orderIdParams,
        status: "REJECTED",
        rejectReason: data.rejectReason,
      },
    });
  };

  const handleFinishOrder = () => {
    let filterFile = filesUrl.map((item: any) => item.file);
    if (filterFile.length > 0) {
      updateAdminOrder({
        variables: {
          id: orderIdParams,
          status: "DONE",
          files: filterFile,
        },
      });
    } else {
      setShowFinishModal(true);
    }
  };

  const handleAddFiles = () => {
    setShowFinishModal(false);
  };

  const handleDone = () => {
    updateAdminOrder({
      variables: {
        id: orderIdParams,
        status: "DONE",
      },
    });
  };

  useEffect(() => {
    if (orderUpdateData) {
      toast.success("Task completed");
      navigate("/");
    }
  }, [orderUpdateData, navigate]);

  return (
    <>
      {statusParams === "completed" ? (
        <div className="px-3 mt-5">
          <Card className="border-0 p-4 mb-4">
            <p className="labelThree mb-2">Submission Date</p>
            <h4 className="titleFive">
              {completedDataVal?.order?.createdAt
                ? format(
                    new Date(completedDataVal?.order?.createdAt || ""),
                    "dd MMM, YYY"
                  )
                : ""}
            </h4>
          </Card>
          <Card className="border-0 p-4 mb-4">
            <p className="labelThree mb-2">Completed Date</p>
            <h4 className="titleFive">
              {completedDataVal?.order?.completedAt &&
                format(
                  new Date(completedDataVal?.order?.completedAt),
                  "dd MMM, YYY"
                )}
            </h4>
          </Card>
          <Card className="border-0 p-4">
            <p className="labelThree mb-2">Handled By:</p>
            <h4 className="titleFive">
              {getFullName(
                completedDataVal?.admin?.firstName || "",
                completedDataVal?.admin?.middleName || "",
                completedDataVal?.admin?.lastName || ""
              )}
            </h4>
          </Card>
        </div>
      ) : (
        <div>
          {getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
            <div>
              {statusParams !== "new" && (
                <CustomButton
                  type="button"
                  btnName={`Finish Task`}
                  classNames={`w-100 mt-3 `}
                  loading={orderUpdateLoading}
                  handleBtnClick={handleFinishOrder}
                />
              )}

              {/* {showRejectBtn && (
                <CustomButton
                  type="button"
                  btnName={`Reject Task `}
                  classNames="w-100 mt-3 tertiary errorColor error-border py-2"
                  handleBtnClick={handleRejectTask}
                />
              )} */}
            </div>
          )}
          {showStartBtn && (
            <CustomButton
              type="button"
              btnName={`Start Task`}
              classNames="w-100 mt-3 "
              loading={loading}
              handleBtnClick={handleTakeOrder}
            />
          )}
          {showRejectBtn && (
            <CustomButton
              type="button"
              btnName={`Reject Task `}
              classNames="w-100 mt-3 tertiary errorColor error-border py-2"
              handleBtnClick={handleRejectTask}
            />
          )}
        </div>
      )}

      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        btnPrimaryText="Yes, remove"
        // btnPrimaryClick={handleDelete}
      >
        <Form onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-4">
            <Controller
              name="rejectReason"
              control={control}
              render={({ field }) => (
                <InputText
                  type="textarea"
                  label="Reason"
                  placeholder="Type here "
                  field={{ ...field, rows: 10 }}
                  invalid={!!errors.rejectReason}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-end">
            <CustomButton
              btnName="Submit"
              classNames="px-4"
              loading={orderRejectLoading}
            />
          </div>
        </Form>
      </ModalComponent>

      <ModalComponent
        showModal={showFinishModal}
        setShowModal={setShowFinishModal}
        showFooter
        modalTitle="Finish Task?"
        btnPrimaryText="Attach Files"
        btnSecondaryText="Yes"
        btnPrimaryClick={handleAddFiles}
        secPrimaryClick={handleDone}
      >
        <span className="bodyTwo">
          Are you sure you want to finish this task without any attachments?
        </span>
      </ModalComponent>
    </>
  );
};
