import "./styles.scss";

import React from "react";

import notesIcon from "assets/icons/notesGray.svg";
import trashIcon from "assets/icons/trash.svg";
import { format } from "date-fns";
import { DefaultImg, IconButton } from "ReuseableComponents";
import { taskType, useUserRole } from "utils";

interface UserCardProps {
  fullName?: string;
  userName?: string;
  userImage?: string;
  totalCount?: string;
  todayCount?: string;
  joinedCount?: string;
  userId?: string;
  joinedDate?: string;
  email?: string;
  contact?: string;
  address?: string;
  showActions?: boolean;
  editClick?: () => void;
  deleteClick?: () => void;
  showDelete: boolean;
  roles?: string[];
  showEdit: boolean;
  showTotalCount: boolean;
  showTodayCount: boolean;
  showJoinedCount: boolean;
}

export const UserCard = ({
  fullName,
  userName,
  userImage,
  todayCount,
  totalCount,
  joinedCount,
  userId,
  email,
  address,
  contact,
  joinedDate,
  showActions,
  editClick,
  deleteClick,
  showDelete,
  roles,
  showEdit,
  showJoinedCount,
  showTodayCount,
  showTotalCount,
}: UserCardProps) => {
  const { userRole } = useUserRole();

  // let taskTypeData = taskType[data.split(" ").join("_").toUpperCase()];

  // let roles = ["VISA", "PASSPORT"];

  return (
    <div className="card userCard d-flex justify-content-center ">
      <div className="userImg d-flex justify-content-center">
        {userImage ? (
          <img
            src={userImage}
            alt="user"
            className="img-fluid"
            height={240}
            width={240}
          />
        ) : (
          <DefaultImg />
        )}
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center mt-3 userInfo">
        <h1 className="titleFour text-center"> {fullName} </h1>
        <p className="labelOne"> {userName} </p>
      </div>
      <div className="userRoles d-flex align-items-center justify-content-center mt-2 flex-wrap gap-2">
        {roles &&
          roles.map((item, i) => {
            let taskTypeData =
              //@ts-ignore
              taskType[item.split(" ").join("_").toUpperCase()];

            return (
              <div
                key={i}
                className="d-flex align-items-center px-2 py-1 mx-1 "
                style={{
                  backgroundColor: taskTypeData.bgColor,
                  width: "fit-content",
                  borderRadius: 4,
                }}
              >
                {taskTypeData.icon}
                <p
                  className={`labelThree task-name`}
                  style={{
                    color: taskTypeData.textColor,
                  }}
                >
                  {taskTypeData.name.replace(/_/g, " ")}
                </p>
              </div>
            );
          })}
      </div>
      <div className="d-flex justify-content-around  align-items-center mt-3 userTask w-100">
        {showTotalCount && (
          <div>
            <h4 className="titleThree text-center">{totalCount} </h4>
            <p className="labelTwo text-center"> Orders</p>
          </div>
        )}

        {showTodayCount && (
          <div>
            <h4 className="titleThree text-center">{todayCount} </h4>
            <p className="labelTwo text-center"> completed</p>
          </div>
        )}

        {showJoinedCount && (
          <div>
            <h4 className="titleThree text-center">{joinedCount}</h4>
            <p className="labelTwo text-center"> Joined</p>
          </div>
        )}
      </div>
      <div className="userDetails mt-3">
        {/* <div className="d-flex align-items-center justify-content-between mb-2">
          <h6 className="labelTwo ">user Id</h6>
          <p className="labelTwo flex-grow-1"> {userId}</p>
        </div> */}
        <div className="d-flex align-items-center  mb-2">
          <h6 className="labelTwo w-25 ">Joined</h6>
          <p className="labelTwo ">
            {joinedDate && format(new Date(joinedDate || ""), "dd MMMM, yyyy")}
          </p>
        </div>
        <div className="d-flex align-items-center  mb-2">
          <h6 className="labelTwo w-25 ">Email</h6>
          <p className="labelTwo ">{email} </p>
        </div>
        <div className="d-flex align-items-center  mb-2">
          <h6 className="labelTwo w-25 ">Contact</h6>
          <p className="labelTwo ">{contact}</p>
        </div>
        {address && (
          <div className="d-flex align-items-center  mb-2">
            <h6 className="labelTwo w-25">Address</h6>
            <p className="labelTwo "> {address} </p>
          </div>
        )}
        {showActions && (
          <div className="d-flex align-items-center justify-content-center ">
            {showDelete && (
              <IconButton
                className="icon"
                handleClick={deleteClick}
                disabled={userRole === "ADMIN"}
              >
                <img src={trashIcon} alt="view" />
              </IconButton>
            )}
            {showEdit && (
              <IconButton className="icon" handleClick={editClick}>
                <img src={notesIcon} alt="view" />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
