import React, { Dispatch, SetStateAction } from "react";

import { format } from "date-fns";
import { ModalComponent } from "ReuseableComponents";
import { adminTaskStatus, taskType } from "utils";

interface viewModalProps {
  createdAt: string;
  order: {
    orderNumer: string;
    status: string;
    completedAt: string;
    task: {
      id: string;
      createdAt: string;
      type: string;
      name: string;
    };
    customer: {
      firstName: string;
      lastName: string;
      middleName: string;
    };
  };
  admin: {
    firstName: string;
    lastName: string;
    middleName: string;
  };
}

interface TaskDetailsProps {
  showViewModal: boolean;
  setShowViewModal: Dispatch<SetStateAction<boolean>>;
  showViewModalData?: viewModalProps;
}

export const TaskDetails = ({
  showViewModal,
  setShowViewModal,
  showViewModalData,
}: TaskDetailsProps) => {
  //@ts-ignore
  const type = taskType[showViewModalData?.order?.task?.type];
  //@ts-ignore
  const statusType = adminTaskStatus[showViewModalData?.order?.status];

  return (
    <ModalComponent showModal={showViewModal} setShowModal={setShowViewModal}>
      <div>
        <p className="bodyTwo mb-3">
          Order Number: {showViewModalData?.order?.orderNumer}
        </p>
        <p className="bodyTwo mb-3">
          Category:{" "}
          <span
            style={{
              padding: "4px 12px",
              backgroundColor: type?.bgColor,
              borderRadius: 4,
              color: type?.textColor,
            }}
          >
            {type?.icon}
            {showViewModalData?.order?.task?.type.replace(/_/g, " ")}
          </span>
        </p>
        <div className="bodyTwo mb-3 d-flex">
          Status:
          <div
            className="mx-2 px-2"
            style={{
              borderRadius: 4,
              backgroundColor: statusType?.bgColor,
              color: statusType?.textColor,
            }}
          >
            {statusType?.icon}
            {statusType?.name}
          </div>
        </div>
        {/* <p className="bodyTwo mb-3">
      Client:{" "}
      {`${showViewModalData?.customer?.firstName} ${showViewModalData?.customer?.middleName} ${showViewModalData?.customer?.lastName}`}
    </p> */}
        <p className="bodyTwo mb-3">
          Handled By: {showViewModalData?.admin.firstName}{" "}
          {showViewModalData?.admin.middleName}{" "}
          {showViewModalData?.admin.lastName}
        </p>
        <p className="bodyTwo mb-3">
          Order Date:{" "}
          {showViewModalData?.createdAt &&
            format(
              new Date(showViewModalData.createdAt || ""),
              "dd MMMM, yyyy"
            )}
        </p>
        <p className="bodyTwo mb-3">
          Completed Date:{" "}
          {showViewModalData?.order.completedAt &&
            format(
              new Date(showViewModalData?.order.completedAt || ""),
              "dd MMMM, yyyy"
            )}
        </p>
      </div>
    </ModalComponent>
  );
};
