import React, { MouseEvent, useContext, useState } from "react";

import { TeamCard, UserCard } from "Components";
import { Pagination } from "Components/Pagination/Pagination";
import { format } from "date-fns";
import { X } from "lucide-react";
import { GET_ADMIN_TEAMS, GET_SUPER_ADMIN_TEAMS } from "queries/superAdminTeam";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { CustomButton, ModalComponent } from "ReuseableComponents";
import { ChatContext, getFullName, useUserRole } from "utils";

import { useQuery } from "@apollo/client";

import { SidebarComponent } from "./components/SidebarComponent/SidebarComponent";

interface showSidebarEditDataProps {
  roles: [];
  id: string;
}

export default function Teams() {
  const chatContext = useContext(ChatContext);

  const navigate = useNavigate();

  //states
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSidebarEditData, setShowSidebarEditData] =
    useState<showSidebarEditDataProps>();

  const [showViewModal, setShowViewModal] = useState(false);
  const [showModalData, setShowModalData] = useState({} as any);

  const [page, setPage] = useState(1);

  const { userRole } = useUserRole();

  //query
  const { data: teamsData, loading: teamsLoading } = useQuery(
    userRole === "SUPERADMIN" ? GET_SUPER_ADMIN_TEAMS : GET_ADMIN_TEAMS,
    {
      variables: {
        limit: 10,
        page: page,
      },

      fetchPolicy: "cache-and-network",
    }
  );

  const getKey = () => {
    if (userRole === "SUPERADMIN") {
      return `superadminGetTeams`;
    } else {
      return `adminGetTeams`;
    }
  };

  const handleEdit = (
    e: MouseEvent<HTMLButtonElement>,
    item: { roles: []; id: string }
  ) => {
    e.preventDefault();
    if (userRole === "SUPERADMIN") {
      setShowSidebar(true);
      setShowSidebarEditData({
        roles: item.roles,
        id: item.id,
      });
    }

    if (userRole === "ADMIN") {
      setShowViewModal(true);
      setShowModalData(item);
    }
  };

  const handleClose = () => {
    setShowViewModal(false);
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex justify-content-end mb-4">
        {userRole === "SUPERADMIN" && (
          <CustomButton
            btnName="Add a new user"
            classNames="px-5"
            handleBtnClick={() => {
              setShowSidebar(true);
              setShowSidebarEditData(undefined);
            }}
          />
        )}
      </div>
      <div className="flex-grow-1">
        {teamsLoading ? (
          <div className="content d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <Row>
            {teamsData?.[getKey()]?.data.map(
              (item: {
                id: string;
                profileImage: string;
                firstName: string;
                lastName: string;
                middleName: string;
                roles: [];
                status: {
                  active: boolean;
                };
                engagedIn: [];
              }) => {
                return (
                  <Col md={4} key={item.id} className="mb-4">
                    <TeamCard
                      id={item.id}
                      image={item?.profileImage}
                      name={getFullName(
                        item.firstName,
                        item.middleName,
                        item.lastName
                      )}
                      // roles={item?.roles
                      //   ?.join(", ")
                      //   .replace(/_/g, " ")
                      //   .toLowerCase()}
                      roles={item.roles}
                      status={item?.status?.active}
                      handleEdit={(e: MouseEvent<HTMLButtonElement>) =>
                        handleEdit(e, item)
                      }
                      taskName={item.engagedIn}
                      handleCreateConversation={() => {
                        if (
                          chatContext &&
                          chatContext.handleCreateConversation
                        ) {
                          chatContext.handleCreateConversation(item);
                          navigate(`/chat`);
                        }
                      }}
                    />
                  </Col>
                );
              }
            )}
          </Row>
        )}
      </div>

      <Pagination
        totalCount={teamsData?.[getKey()]?.meta.total}
        handlePagination={() => {}}
        rowsPerPage={10}
        currentPage={page}
        setCurrentPage={setPage}
      />
      {userRole === "SUPERADMIN" && (
        <SidebarComponent
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          showSidebarEditData={showSidebarEditData}
        />
      )}

      <ModalComponent
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        showModalTitle={false}
      >
        <X className="cursorPointer float-end" onClick={handleClose} />
        <UserCard
          fullName={getFullName(
            showModalData?.firstName,
            showModalData?.middleName,
            showModalData?.lastName
          )}
          email={showModalData?.email}
          userId={showModalData?.id}
          joinedDate={showModalData?.createdAt || new Date()}
          contact={showModalData?.phone}
          userImage={showModalData?.profileImage}
          joinedCount={
            showModalData?.createdAt
              ? format(new Date(showModalData.createdAt), "YYY")
              : ""
          }
          totalCount={showModalData?.taskCount}
          todayCount={showModalData?.taskCompletedCount}
          userName={showModalData?.userName}
          showActions={false}
          roles={showModalData?.roles}
          showJoinedCount={true}
          showTodayCount={true}
          showTotalCount={true}
          showEdit={false}
          showDelete={false}
        />
      </ModalComponent>
    </div>
  );
}
