import "./styles.scss";

import React, { Dispatch, ReactNode, SetStateAction } from "react";

import { X } from "lucide-react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { IconButton } from "ReuseableComponents/IconButton/IconButton";

interface SidebarModalProps {
  showSidebarModal: boolean;
  setShowSidebarModal: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  title: string;
}

export const SidebarModal = ({
  setShowSidebarModal,
  showSidebarModal,
  children,
  title,
}: SidebarModalProps) => {
  const handleToggle = () => {
    setShowSidebarModal(!showSidebarModal);
  };
  return (
    <Modal
      className="sidebar-modal"
      isOpen={showSidebarModal}
      contentClassName="right-slide-modal"
      size="md"
      centered
      toggle={handleToggle}
    >
      <ModalHeader>
        <div className="d-flex align-items-center justify-content-between ">
          <h4 className="titleFour"> {title} </h4>
          <IconButton handleClick={handleToggle} className="p-0">
            <X size={16} />
          </IconButton>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};
