import "./styles.scss";

import React, { useEffect } from "react";

import crossRed from "assets/icons/crossRed.svg";
import tickSuccess from "assets/icons/tickSuccess.svg";
import { AuthLayout } from "Components";
import { SET_PASSWORD_FORGET } from "queries/authQuery";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";
import { CustomButton, Logo, PasswordInput } from "ReuseableComponents";

import { useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { passwordSchema } from "./validationSchema";

interface LoginInput {
  newPassword: string;
  confirmPassword: string;
}

export default function SetPassword() {
  //var
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<LoginInput>({
    resolver: yupResolver(passwordSchema),
    // mode: "onChange",
  });

  //params
  const phone = searchParams.get("phone");
  const code = searchParams.get("code");

  //query
  const [setPasswordAfterForgetPassword, { data, loading, error }] =
    useLazyQuery(SET_PASSWORD_FORGET, {
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });

  //state

  const onSubmit = (data: LoginInput) => {
    setPasswordAfterForgetPassword({
      variables: {
        password: data.newPassword,
        phone: phone,
        code: Number(code),
      },
    });
  };

  useEffect(() => {
    if (data) {
      navigate("/login");
      reset();
    }

    if (error) {
      toast.error(error.message || "Something went wrong");
    }
  }, [data, error, navigate, reset]);

  return (
    <AuthLayout>
      <Row>
        <Col md={12}>
          <div className="setPasswordDetails">
            <Logo />
            <div className="d-flex">
              <div className=" containerWrapper flex-grow-1">
                <h1 className="titleTwo title">Set New Password</h1>
                <div className="formWrapper">
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="d-flex flex-column justify-content-center align-items-end"
                  >
                    <div className="defaultMargin w-100">
                      <Controller
                        name="newPassword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <PasswordInput
                            label="New Password"
                            placeholder="Password"
                            icon
                            field={field}
                            error={errors.newPassword?.message}
                          />
                        )}
                      />
                    </div>

                    <div className="defaultMargin w-100">
                      <Controller
                        name="confirmPassword"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <PasswordInput
                            label="Repeat Password"
                            placeholder="Password"
                            icon
                            field={field}
                            error={errors.confirmPassword?.message}
                          />
                        )}
                      />
                    </div>

                    <CustomButton
                      btnName="Reset password"
                      classNames="w-100"
                      color="primary"
                      loading={loading}
                    />
                  </Form>
                </div>
              </div>
              <div className="requirementsWrapper">
                <div className="mb-3">
                  <p className="titleFive">Requirements</p>
                </div>
                <div className="mb-3 d-flex">
                  <img
                    src={errors.newPassword?.message ? tickSuccess : crossRed}
                    className="img-fluid me-2"
                  />
                  <p className="bodyTwo">Minimum 8 characters</p>
                </div>
                <div className="mb-3 d-flex">
                  <img src={crossRed} className="img-fluid me-2" />
                  <p className="bodyTwo">At least 1 special character</p>
                </div>
                <div className="mb-3 d-flex">
                  <img src={tickSuccess} className="img-fluid me-2" />
                  <p className="bodyTwo">Contains uppercase</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </AuthLayout>
  );
}
