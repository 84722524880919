import "./styles.scss";

import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import mailIcon from "assets/icons/mail.svg";
import userIcon from "assets/icons/users.svg";
import { UserCard } from "Components";
import { format } from "date-fns";
import { Search, SendHorizontal } from "lucide-react";
import {
  GET_ADMIN_CONVERSATION,
  GET_ADMIN_ORDER_CONVERSATION,
} from "queries/chat";
import { Button, Card, Col, Form, Input, Row, Spinner } from "reactstrap";
import { DefaultImg, TaskStatusComponent } from "ReuseableComponents";
import { getFullName, isUrl, useChatContext, useUserContext } from "utils";

import { useLazyQuery } from "@apollo/client";

const chatTypeOptions = ["clients", "teams"];

export default function Chat() {
  const messageRef = useRef<HTMLDivElement>(null);
  const inputSendRef = useRef<HTMLInputElement | null>(null);

  const { user } = useUserContext();

  const [adminConvoOrderData, setAdminConvoOrderData] = useState([] as any);

  const [page, setPage] = useState(1);

  const {
    chatSettings,
    messagesList,
    setChatSettings,
    customerDetails,
    socket,
    conversations,
    joinConvoLoading,
    newIncomingMessage,
    setCustomerDetails,
    setNewIncomingMessage,
    getCustomerData,
    setConversations,
    filterKye,
    setFilterKey,
  } = useChatContext();

  const [
    getAdminConversation,
    { data: adminConvoData, loading: adminConvoLoading },
  ] = useLazyQuery(GET_ADMIN_CONVERSATION, {
    fetchPolicy: "cache-and-network",
  });

  const [getAdminOrderConvo, { data: adminOrderConversationData }] =
    useLazyQuery(GET_ADMIN_ORDER_CONVERSATION, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, [socket]);

  useEffect(() => {
    if (chatSettings.chatId && chatSettings.chatType === "clients" && page) {
      getAdminOrderConvo({
        variables: {
          conversationId: chatSettings?.chatId,
          // orderNumberSearch: "05eaa6f3-8754-427d-8a13-affa8e2f0634",
          page: page,
          limit: 10,
        },
      });
    }
  }, [chatSettings.chatId, chatSettings.chatType, getAdminOrderConvo, page]);

  useEffect(() => {
    if (adminConvoData) {
      setConversations({
        count: adminConvoData?.adminGetConversations.data.length,
        conversationsList: adminConvoData?.adminGetConversations.data,
      });
    }
  }, [adminConvoData]);

  useEffect(() => {
    if (filterKye && !chatSettings.chatId && !chatSettings.customerId) {
      let data: any = conversations?.conversationsList?.filter(
        (item: any) => item.type === filterKye
      );
      const customerData: any = getCustomerData(data?.[0]);
      setCustomerDetails(customerData);
      setChatSettings((prevState: any) => ({
        ...prevState,
        chatId: data?.[0]?.id,
        customerId: customerData?.id,
      }));
    }
  }, [filterKye, chatSettings.chatId, chatSettings.customerId]);

  useEffect(() => {
    const setData = async () => {
      let filterData =
        await adminOrderConversationData?.adminGetOrderConversations?.data.filter(
          (item: any) => item?.order
        );

      setChatSettings((prevState: any) => ({
        ...prevState,
        orderId: filterData[0]?.order?.id,
      }));

      setAdminConvoOrderData((prevState: any) => [...prevState, ...filterData]);
    };

    if (
      adminOrderConversationData?.adminGetOrderConversations?.data &&
      chatSettings?.chatType === "clients"
    ) {
      setData();
    }
  }, [adminOrderConversationData, chatSettings.chatType, setChatSettings]);

  const scrollToBottom = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (messagesList) {
      scrollToBottom(); // Scroll to bottom on initial render
    }
  }, [messagesList]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight &&
      adminConvoOrderData.length + 1 <
        adminOrderConversationData?.adminGetOrderConversations?.meta.total
    ) {
      setPage(page + 1);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputSendRef?.current?.value === "") {
      return;
    }

    let usersData = conversations?.conversationsList.find(
      (item: any) => item.id === chatSettings?.chatId
    ) as any;

    let args: any = {
      message: inputSendRef?.current?.value,
      conversation: {
        id: chatSettings?.chatId,
        users: usersData?.users,
      },
    };

    if (chatSettings?.chatType === "clients") {
      args = {
        ...args,
        order: {
          id: chatSettings?.orderId,
        },
      };
    }

    socket.emit("sendMessage", {
      ...args,
    });
    if (inputSendRef.current) {
      inputSendRef.current.value = "";
    }
    setNewIncomingMessage({});
  };

  const handleChangeChatType = async (item: any) => {
    let key = item === "teams" ? "ADMIN_ADMIN" : "ADMIN_CUSTOMER";
    let convoData: any = await conversations?.conversationsList?.filter(
      (item: any) => item.type === key
    );
    let customerData: any = await getCustomerData(convoData[0]);
    setCustomerDetails(customerData);
    await setAdminConvoOrderData([]);
    setChatSettings((prevState: any) => ({
      ...prevState,
      chatType: item,
      chatId: convoData?.[0]?.id,
      customerId: customerData?.id,
    }));
    setFilterKey(key);
  };

  return (
    <div className="chat">
      <Row>
        <Col md={4}>
          <Card className="border-0 px-4 py-4 usersList">
            <div className="position-relative searchInput mb-3">
              <Search className="search-icon position-absolute" size={16} />

              <Input
                className=" search-input"
                placeholder="Search by name"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  getAdminConversation({
                    variables: {
                      keyword: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="usersList-tab mb-3">
              {chatTypeOptions.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={`bg-transparent border-0 usersList-tab-btn textCapitalize   ${
                      chatSettings?.chatType === item && "active"
                    }`}
                    onClick={() => handleChangeChatType(item)}
                  >
                    <img alt="icon" src={userIcon} className="me-2" />
                    {item}
                  </button>
                );
              })}
            </div>
            <div>
              {conversations?.conversationsList
                ?.filter((item: any) => item.type === filterKye)
                .map((item: any, idx: number) => {
                  let customerData: any = getCustomerData(item);

                  return (
                    <Button
                      key={idx}
                      className={`d-flex  usersList-item py-3 justify-content-between align-items-center ${
                        item.id === chatSettings?.chatId && "active"
                      }`}
                      onClick={async (e) => {
                        e.preventDefault();
                        setAdminConvoOrderData([]);
                        setCustomerDetails(customerData);
                        setChatSettings((prevState: any) => ({
                          ...prevState,
                          chatId: item.id,
                          customerId: customerData?.id,
                          orderId: adminConvoOrderData?.[0]?.order?.id || "",
                        }));
                      }}
                    >
                      <div className="d-flex align-items-center flex-grow-1">
                        {customerData?.profileImage ? (
                          <img
                            className="img-fluid usersList-item-img me-2"
                            src={customerData?.profileImage}
                            alt="profileImg"
                          />
                        ) : (
                          <DefaultImg className="me-2" />
                        )}
                        <div className="">
                          <h4 className="titleSix">
                            {" "}
                            {getFullName(
                              customerData?.firstName,
                              customerData?.middleName,
                              customerData?.lastName
                            )}{" "}
                          </h4>
                        </div>
                      </div>
                      <div>
                        <p className="labelThree">
                          {" "}
                          {format(new Date(item.updatedAt), "HH:mm")}{" "}
                        </p>
                        {!item.seen && (
                          <div className="notSeen">
                            {" "}
                            <img
                              alt="mail"
                              src={mailIcon}
                              className="img-fluid"
                            />{" "}
                          </div>
                        )}
                      </div>
                    </Button>
                  );
                })}
            </div>
          </Card>
        </Col>
        <Col md={5}>
          {chatSettings.chatId && chatSettings.customerId ? (
            <Card className="border-0 message p-4">
              <>
                <div className="message-title pb-4">
                  <h4 className="titleFive">
                    {getFullName(
                      customerDetails?.firstName || "",
                      customerDetails?.middleName || "",
                      customerDetails?.lastName || ""
                    )}
                  </h4>
                </div>
                <div className="message-items">
                  {joinConvoLoading ? (
                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                      <Spinner />
                    </div>
                  ) : messagesList?.length > 0 ? (
                    Array.from(
                      new Map(
                        messagesList?.map((obj: any) => [obj?.id, obj])
                      ).values()
                    ).map((item: any, idx: number) => {
                      const isAdmin = item.user?.id === user?.id;
                      return (
                        <div
                          key={idx}
                          className={`d-flex align-items-center message-items-item mb-4  ${
                            isAdmin && " flex-row-reverse admin"
                          }`}
                        >
                          {item.user.profileImage ? (
                            <img
                              alt=""
                              src={item.user.profileImage}
                              className={`img-fluid userImg ${
                                isAdmin ? "mx-2" : "me-2"
                              }`}
                            />
                          ) : (
                            <DefaultImg className="mx-2" />
                          )}
                          <div className="msg p-2">
                            {isUrl(item.message) ? (
                              <a
                                className="bodyThree"
                                href={item.message}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: isAdmin ? "#fff" : "inherit",
                                  textDecoration: "underline",
                                }}
                              >
                                {" "}
                                {item.message}{" "}
                              </a>
                            ) : (
                              <p className="bodyThree"> {item.message} </p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    ""
                  )}

                  <div ref={messageRef} />
                </div>
              </>

              <Form onSubmit={handleSubmit}>
                <div className="message-inputText">
                  <input
                    type="text"
                    ref={inputSendRef}
                    className="inputField w-100"
                    placeholder="Type your text..."
                  />
                  <button
                    type="submit"
                    className="sendIcon border-0 bg-transparent"
                  >
                    <SendHorizontal
                    // onClick={handleSubmit}
                    />
                  </button>
                </div>
              </Form>
            </Card>
          ) : (
            <p>Please Select users to start conversation</p>
          )}
        </Col>
        <Col md={3}>
          {chatSettings.chatId &&
          chatSettings.customerId &&
          chatSettings?.chatType === "clients" ? (
            <Card className="border-0 clients p-4">
              {chatSettings?.chatId && chatSettings.customerId ? (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                    {customerDetails?.profileImage ? (
                      <img
                        alt="user-img"
                        className="img-fluid clients-image"
                        src={customerDetails?.profileImage}
                      />
                    ) : (
                      <DefaultImg className="clients-defaultImage p-0" />
                    )}
                    <h4 className="titleFive mt-2">
                      {" "}
                      {getFullName(
                        customerDetails?.firstName,
                        customerDetails?.middleName,
                        customerDetails?.lastName
                      )}
                    </h4>
                  </div>
                  <div>
                    <p className="capsTwo">CHAT HISTORY</p>
                    <div
                      id="clientsOrderHistory"
                      className="clients-order-history mt-3"
                      onScroll={(e: MouseEvent<HTMLDivElement>) =>
                        handleScroll(e)
                      }
                    >
                      {adminConvoOrderData.length > 0
                        ? Array.from(
                            new Map(
                              adminConvoOrderData.map((obj: any) => [
                                obj?.order?.id,
                                obj,
                              ])
                            ).values()
                          ).map((item: any) => {
                            return (
                              <Card
                                key={item.id}
                                className={`clients-order-history-item p-3 mb-3 bg-transparent cursorPointer ${
                                  chatSettings?.orderId === item.order.id &&
                                  "active"
                                }`}
                                onClick={() =>
                                  setChatSettings((prevState: any) => ({
                                    ...prevState,
                                    orderId: item.order.id,
                                  }))
                                }
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <TaskStatusComponent
                                    type={item?.order?.status}
                                    showBadge="no"
                                  />
                                  <p className="labelFour">
                                    {" "}
                                    {item?.order?.updatedAt
                                      ? format(
                                          new Date(item?.order?.updatedAt),
                                          "MMMd, yyyy"
                                        )
                                      : ""}{" "}
                                  </p>
                                </div>
                                <div className="my-3 d-flex justify-content-between flex-wrap">
                                  <p
                                    className="titleFive"
                                    style={{
                                      fontSize: 11,
                                    }}
                                  >
                                    {item?.order?.orderNumer}
                                  </p>
                                  {newIncomingMessage?.order?.id ===
                                    item?.order?.id &&
                                    !newIncomingMessage.seen && (
                                      <div className="notSeen">
                                        {" "}
                                        <img
                                          alt="mail"
                                          src={mailIcon}
                                          className="img-fluid"
                                        />{" "}
                                      </div>
                                    )}
                                </div>
                                <div className="d-flex align-items-center">
                                  <p className="labelThree me-2">Handled By:</p>
                                  <div className="d-flex align-items-center ">
                                    {user?.profileImage ? (
                                      <img
                                        alt="user"
                                        src={user?.profileImage}
                                        className="img-fluid user-img me-2"
                                      />
                                    ) : (
                                      <DefaultImg className="userDefault p-0 me-2" />
                                    )}

                                    <p className="labelThree">
                                      {" "}
                                      {getFullName(
                                        user?.firstName || "",
                                        user?.middleName || "",
                                        user?.lastName || ""
                                      )}{" "}
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Card>
          ) : (
            chatSettings.chatId &&
            chatSettings.customerId && (
              <UserCard
                fullName={getFullName(
                  customerDetails?.firstName,
                  customerDetails?.middleName,
                  customerDetails?.lastName
                )}
                email={customerDetails?.email}
                userId={customerDetails?.id}
                joinedDate={customerDetails?.createdAt || new Date()}
                address={`${
                  customerDetails?.address?.prefecture
                    ? customerDetails?.address?.prefecture
                    : ""
                } ${
                  customerDetails?.address?.municipality
                    ? `, ${customerDetails?.address?.municipality}`
                    : ""
                } ${
                  customerDetails?.address?.town
                    ? `, ${customerDetails?.address?.town}`
                    : ""
                } ${
                  customerDetails?.address?.banchi
                    ? `, ${customerDetails?.address?.banchi}`
                    : ""
                } ${
                  customerDetails?.address?.buildingName
                    ? `, ${customerDetails?.address?.buildingName}`
                    : ""
                } ${
                  customerDetails?.address?.roomNumber
                    ? `, ${customerDetails?.address?.roomNumber}`
                    : ""
                }`}
                contact={customerDetails?.phone}
                userImage={customerDetails?.profileImage}
                joinedCount={
                  customerDetails?.createdAt
                    ? format(new Date(customerDetails?.createdAt), "YYY")
                    : ""
                }
                totalCount={customerDetails?.taskCount}
                todayCount={customerDetails?.taskCompletedCount}
                userName={customerDetails?.userName}
                showActions={false}
                showDelete={false}
                showEdit={false}
                roles={customerDetails?.roles}
                showJoinedCount={true}
                showTodayCount={true}
                showTotalCount={true}
              />
            )
          )}
        </Col>
      </Row>
    </div>
  );
}
