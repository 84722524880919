import React from "react";

import { useUserRole } from "utils";

import { AdminDashboard } from "./Components/AdminDashboard/AdminDashboard";
import { SuperAdminDashboard } from "./Components/SuperAdminDashboard/SuperAdminDashboard";

export default function Dashboard() {
  // var
  const { userRole } = useUserRole();

  const getComponent = () => {
    if (userRole) {
      switch (userRole) {
        case "ADMIN":
          return <AdminDashboard />;
        case "SUPERADMIN":
          return <SuperAdminDashboard />;

        default:
          return null;
      }
    }
  };

  return <div className="dashboard">{getComponent()}</div>;
}
