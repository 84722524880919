import * as Yup from "yup";

export const notificationSchema = Yup.object().shape({
  headLine: Yup.string()
    .max(50, "Headline should not exceed more than 50 words")
    .required("headline is required"),
  body: Yup.string()
    .max(200, "Body should not exceed more than 200 words")
    .required("Body message is require"),
});
