import "./styles.scss";

import React from "react";

import topCatIcon from "assets/icons/dashboardCatIcon.svg";
import userDashboardIcon from "assets/icons/userDashboardIcon.svg";
import { getStatusClassName, getStatusIcon, taskType } from "utils";

interface DashboardCardProps {
  className?: string;
  label: string;
  totalNumber: string;
  growthNumber: string;
  status: string;
  type?: string;
  taskName?: string;
}

export const DashboardCard = ({
  className,
  label,
  totalNumber,
  growthNumber,
  status,
  type,
  taskName,
}: DashboardCardProps) => {
  let taskTypeData =
    //@ts-ignore
    taskType[taskName?.split(" ").join("_").toUpperCase() || ""];

  return (
    <div className={`dashboard-card ${className}`}>
      <img
        src={type === "category" ? topCatIcon : userDashboardIcon}
        alt="user-icon"
        className="img-fluid mb-2"
      />
      <div className="d-flex align-items-center flex-wrap justify-content-between">
        <p className="labelTwo"> {label} </p>
        {type === "category" && (
          <div
            className="d-flex align-items-center px-2 py-1 mx-1"
            style={{
              backgroundColor: taskTypeData?.bgColor,
              width: "fit-content",
              borderRadius: 4,
            }}
          >
            {taskTypeData?.icon}
            <p
              className={`labelThree task-name`}
              style={{
                color: taskTypeData?.textColor,
              }}
            >
              {taskTypeData?.name.replace(/_/g, " ")}
            </p>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <h2 className="titleTwo"> {totalNumber} </h2>
        <span className={`labelTwo growthNumber ${getStatusClassName(status)}`}>
          {" "}
          {getStatusIcon(status)}
          {Number(growthNumber).toFixed(0)}{" "}
        </span>
      </div>
    </div>
  );
};
