import "./styles.scss";

import React from "react";

import { Link } from "react-router-dom";
import { DefaultImg, SearchInput } from "ReuseableComponents";
import { getFullName, useUserContext, useUserRole } from "utils";

export const Header = () => {
  const { user } = useUserContext();
  const { userRole } = useUserRole();

  return (
    <div className="header d-flex align-items-center justify-content-between">
      <div className="search">
        <SearchInput />
      </div>
      <div className="rightContent d-flex align-items-center">
        <div className="d-flex align-items-center">
          <Link
            to={userRole === "ADMIN" ? "/profile" : "/my-account"}
            className="d-flex align-items-center"
          >
            <p className="bodyTwo whiteSpace-wrap me-3 text-capitalize">
              {getFullName(
                user?.firstName || "",
                user?.middleName || "",
                user?.lastName || ""
              )}
            </p>
            <div className="avatar">
              {user?.profileImage ? (
                <img
                  src={user?.profileImage}
                  className="img-fluid"
                  alt="user-img"
                />
              ) : (
                <DefaultImg />
              )}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
