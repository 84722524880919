import "./styles.scss";

import React, { MouseEvent } from "react";

import { Button } from "reactstrap";

interface IconButtonProps {
  children: React.ReactNode;
  className?: string;
  handleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export const IconButton = ({
  children,
  className,
  handleClick,
  disabled,
}: IconButtonProps) => {
  return (
    <Button
      className={`iconBtn ${className}`}
      color="outline"
      onClick={handleClick}
      disabled={disabled}
    >
      {" "}
      {children}{" "}
    </Button>
  );
};
