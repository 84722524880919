import { gql } from "@apollo/client";

export const GET_SUPER_ADMIN_CLIENTS = gql`
  query (
    $limit: Int
    $page: Int
    $status: UserStatusTypes
    $keyword: String
    $orderRange: OrderNumberInput
    $dateRange: DateFilterInput
  ) {
    superadminGetClients(
      paginationInput: { limit: $limit, page: $page }
      filterInput: {
        status: $status
        keyword: $keyword
        orderRange: $orderRange
        dateRange: $dateRange
      }
    ) {
      data {
        id
        email
        firstName
        lastName
        middleName
        createdAt
        taskCount
        profileImage
        phone
        orderCount
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_SUPER_ADMIN_CLIENTS_BY_ID = gql`
  query ($userId: String!) {
    superadminGetClientById(userId: $userId) {
      id
      firstName
      lastName
      middleName
      email
      phone
      createdAt
      taskCount
      taskCompletedCount
      orderCount
      profileImage
      address {
        country
        city
        banchi
        municipality
        prefecture
        town
        buildingName
        roomNumber
        postalCode
      }
    }
  }
`;

export const ADD_SUPER_ADMIN_CLIENT = gql`
  mutation ($phone: String, $password: String!, $countryCode: String) {
    superadminAddClient(
      addClientInput: {
        phone: $phone
        password: $password
        countryCode: $countryCode
      }
    )
  }
`;

export const EDIT_SUPER_ADMIN_CLIENT = gql`
  mutation (
    $userId: String!
    $address: UserAddressInput
    $firstName: String
    $lastName: String
    $middleName: String
    $image: Upload
    $email: String
  ) {
    superadminEditClient(
      userId: $userId
      image: $image
      setProfileInput: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        address: $address
        email: $email
      }
    ) {
      id
    }
  }
`;

export const DELETE_SUPER_ADMIN_CLIENT = gql`
  mutation ($id: String!) {
    superadminDeleteClient(id: $id)
  }
`;
