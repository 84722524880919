import './styles.scss';

import React from 'react';

import { PasswordRequirement } from 'Components';
import {
  Link,
  useParams,
} from 'react-router-dom';
import {
  Col,
  Row,
} from 'reactstrap';

import { ChangePassword } from './components/ChangePassword';
import { Profile } from './components/Profile';

export default function MyAccount() {
  let { myAccount } = useParams();

  return (
    <div className="myAccount">
      <h1 className="titleFour title">My Account</h1>
      <Row>
        <Col md={2}>
          <div className="tab-navigation">
            <Link
              className={`my-account-nav ${!myAccount ? "active" : ""} `}
              to={"/my-account"}
            >
              <p className="labelTwo">My profile</p>
            </Link>
            <Link
              className={`my-account-nav ${
                myAccount === "change-password" ? "active" : ""
              } `}
              to={"/my-account/change-password"}
            >
              <p className="labelTwo">Change Password</p>
            </Link>
          </div>
        </Col>
        {!myAccount ? (
          <Col md={10}>
            <Profile />
          </Col>
        ) : myAccount === "change-password" ? (
          <>
            <Col md={5}>
              {" "}
              <ChangePassword />
            </Col>
            <Col md={5}>
              <PasswordRequirement />
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
}
