import "./styles.scss";

import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import axios from "axios";
import { Search } from "lucide-react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
} from "reactstrap";
import { DefaultImg } from "ReuseableComponents/DefaultImg/DefaultImg";
import {
  addEllipsis,
  getFullName,
  getTaskStatus,
  getTaskUrl,
  taskType,
  useUserContext,
  useUserRole,
} from "utils";

export const SearchInput = () => {
  const dropDownMenuRef = useRef(null);
  const inputRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownData, setDropDownData] = useState([]) as any;
  const [pageNumber, setPageNumber] = useState(1);

  const [searchOrderResult, setSearchOrderResult] = useState([]) as any;
  const [searchUserResult, setSearchUserResult] = useState([]) as any;

  const [searchLoading, setSearchLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const { userRole } = useUserRole();
  const { user } = useUserContext();

  useEffect(() => {
    if (searchUserResult.length > 0 || searchOrderResult.length > 0) {
      let ordersData = [] as any;
      let usersData = [] as any;

      ordersData = searchOrderResult.map((item: any) => {
        return {
          listType: "orderType",

          taskType: item?.task.type,
          customerName: getFullName(
            item.customer.firstName,
            item.customer.middleName,
            item.customer.lastName
          ),
          // profilePic: item.admin.profileImage,
          metaData: item.metaData,
          name: item.task.name,
          status: getTaskStatus(item.status),
          customerId: item.customer.id,
          taskId: item.task.id,
          orderNumer: item.orderNumer,
          serviceName: item.serviceName,
          id:
            userRole === "ADMIN"
              ? user?.id === item?.admin?.id
                ? item.id
                : item.id
              : item.id,
          isAdmin:
            userRole === "ADMIN"
              ? user?.id === item?.admin?.id
                ? true
                : false
              : false,
        };
      });

      if (userRole === "ADMIN") {
        usersData = searchUserResult
          .filter(
            (item: any) =>
              !item.userType.includes("ADMIN") &&
              !item.userType.includes("SUPERADMIN")
          )
          .map((item: any) => {
            return {
              id: item.id,
              listType: "userType",
              userDetails: getFullName(
                item.firstName,
                item.middleName,
                item.lastName
              ),
              profilePic: item.profileImage,
              userType: item.userType[0].toLowerCase(),
            };
          });
      } else {
        usersData = searchUserResult.map((item: any) => {
          return {
            id: item.id,
            listType: "userType",
            userDetails: getFullName(
              item.firstName,
              item.middleName,
              item.lastName
            ),
            profilePic: item.profileImage,
            userType: item.userType[0].toLowerCase(),
          };
        });
      }

      let data = [...ordersData, ...usersData];

      // if (userRole === "SUPERADMIN") {
      //   data = [...data, ...usersData];
      // }

      let map = new Map();

      for (const item of data) {
        map.set(item["id"], item);
      }

      const uniqueArray = Array.from(map.values());

      // let i = [new Map(data.map((item) => [item.id, item])).values()];

      setDropDownData(uniqueArray);
    } else {
      setDropDownData([]);
    }
  }, [searchOrderResult, searchUserResult, user?.id, userRole]);

  useEffect(() => {
    if (searchValue !== "") {
      setDropdownOpen(true);
      const fetch = setTimeout(async () => {
        try {
          const url =
            userRole === "SUPERADMIN"
              ? "/superadmin/advance-search"
              : "/admin/advance-search";
          const response = await axios.post(`${url}?keyword=${searchValue}`);

          if (response.data.data.orders && response.data.data.users) {
            setSearchOrderResult([
              ...searchOrderResult,
              ...response.data.data.orders,
            ]);

            setSearchUserResult([
              ...searchUserResult,
              ...response.data.data.users,
            ]);
          }
          setSearchLoading(false);
        } catch (error) {
          setSearchLoading(false);
        }
      }, 1000);

      // fetch();

      return () => {
        clearTimeout(fetch);
      };
    } else {
      setSearchOrderResult([]);
      setSearchUserResult([]);
      setSearchLoading(false);
    }
  }, [searchValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDropDownData([]);
    setSearchOrderResult([]);
    setSearchUserResult([]);
    setPageNumber(1);
    setSearchValue(e.target.value);
    setSearchLoading(true);
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="search-input">
      <div className="d-flex w-100">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          direction={"down"}
          className="w-100"
        >
          <DropdownToggle
            className="bg-transparent border-0 p-0 w-100"
            style={{
              color: "inherit",
            }}
          >
            <Search className="search-icon" />
            <Input
              ref={inputRef}
              className="input"
              type="text"
              placeholder="Search by name or User ID"
              onChange={handleChange}
            />
          </DropdownToggle>
          <DropdownMenu
            className="w-100 searchDropdown boxShadow"
            ref={dropDownMenuRef}
            // onScroll={handleScroll}
          >
            {dropdownData.length > 0
              ? dropdownData.map((item: any, i: number) => {
                  if (item.listType === "orderType") {
                    let taskTypeData =
                      //@ts-ignore
                      taskType[
                        item.taskType.split(" ").join("_").toUpperCase()
                      ];

                    return (
                      <Link to={getTaskUrl(item)} className="url">
                        <DropdownItem key={i} className="searchDropdown-item">
                          <div className="d-flex align-items-center">
                            <DefaultImg className=" searchDropdown-item-image p-0 me-3" />
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div>
                                <h4 className="labelTwo mx-2 mb-1">
                                  {" "}
                                  {addEllipsis(item.orderNumer, 10)}{" "}
                                </h4>
                                <div
                                  className="d-flex align-items-center px-2 py-1 mx-1"
                                  style={{
                                    backgroundColor: taskTypeData.bgColor,
                                    width: "fit-content",
                                    borderRadius: 4,
                                  }}
                                >
                                  {taskTypeData.icon}
                                  <p
                                    className={`labelThree task-name`}
                                    style={{
                                      color: taskTypeData.textColor,
                                    }}
                                  >
                                    {taskTypeData.name.replace(/_/g, " ")}
                                  </p>
                                </div>
                              </div>
                              <h3 className="labelTwo">{item.customerName}</h3>
                            </div>
                          </div>
                        </DropdownItem>
                      </Link>
                    );
                  } else {
                    const url =
                      item.userType === "admin"
                        ? `/teams/${item.id}`
                        : `/clients/${item.id}`;
                    return (
                      <Link to={url} className="url">
                        <DropdownItem key={i} className="searchDropdown-item">
                          <div className="d-flex align-items-center">
                            <div>
                              {item.profilePic ? (
                                <img
                                  alt=""
                                  src={item.profilePic}
                                  className="img-fluid searchDropdown-item-image  me-3"
                                />
                              ) : (
                                <DefaultImg className=" searchDropdown-item-image p-0 me-3" />
                              )}
                            </div>
                            <div>
                              <h4 className="labelTwo"> {item.userDetails} </h4>
                              <p className="labelTwo"> {item.userType} </p>
                            </div>
                          </div>
                        </DropdownItem>
                      </Link>
                    );
                  }
                })
              : !searchLoading && (
                  <h2 className="labelTwo text-center mt-4">
                    Insert Your Query{" "}
                  </h2>
                )}

            {searchLoading && <Spinner className="mx-auto d-flex mt-3" />}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};
