import "./styles.scss";

import React, { useEffect, useState } from "react";

import authBannerImg from "assets/images/auth/authBanner.svg";
import { AuthLayout } from "Components";
import { FORGET_PASSWORD_VERIFY_CODE } from "queries/authQuery";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";
import { CustomButton, Logo, OtpInputField } from "ReuseableComponents";

import { useLazyQuery } from "@apollo/client";

export default function Otp() {
  //var
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  //query
  const [forgetPasswordVerifyCode, { data, error, loading }] = useLazyQuery(
    FORGET_PASSWORD_VERIFY_CODE
  );

  const phone = searchParams.get("phone");
  //state
  const [otp, setOtp] = useState<number>(0);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    forgetPasswordVerifyCode({
      variables: {
        code: Number(otp),
        phone: phone,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    if (data) {
      navigate(`/set-password?phone=${phone}&code=${otp}`);
    }
  }, [error, data, navigate]);

  return (
    <AuthLayout>
      <Row>
        <Col md={6}>
          <div className="loginDetails">
            <Logo />
            <div className="formWrapper w-50 ">
              <h1 className="titleTwo title mb-3">Enter OTP</h1>
              <Form
                onSubmit={handleSubmit}
                className="d-flex flex-column justify-content-center align-items-end"
              >
                <div className="defaultMargin w-100 ">
                  <OtpInputField otp={otp} setOtp={setOtp} />
                </div>

                <CustomButton
                  btnName="Verify"
                  classNames="w-100 mt-4"
                  color="primary"
                  loading={loading}
                />
              </Form>
            </div>
          </div>
        </Col>
        <Col md={6} className="no-padding">
          <img
            src={authBannerImg}
            alt="auth banner"
            className="img-fluid w-100"
          />
        </Col>
      </Row>
    </AuthLayout>
  );
}
