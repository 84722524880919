import { useCallback, useEffect, useState } from "react";

import {
  EDIT_ADMIN_CLIENT,
  GET_ADMIN_CLIENTS_BY_ID,
} from "queries/adminClient";
import {
  EDIT_SUPER_ADMIN_CLIENT,
  GET_SUPER_ADMIN_CLIENTS_BY_ID,
} from "queries/superAdminClient";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "reactstrap";
import { BreadCrumbs, CustomButton, InputText } from "ReuseableComponents";
import { useUserRole } from "utils";

import { useMutation, useQuery } from "@apollo/client";

import { ImageUpload } from "./Components/ImageUpload/ImageUpload";

interface inputProps {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  prefecture: string;
  municipality: string;
  town: string;
  banchi: string;
  buildingName: string;
  roomNumber: string;
  postalCode: string;
}

const defaultValues = {
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  phone: "",
  prefecture: "",
  municipality: "",
  town: "",
  banchi: "",
  buildingName: "",
  roomNumber: "",
  postalCode: "",
};

export default function ClientsEdit() {
  //var
  const { clientId } = useParams();
  const { userRole } = useUserRole();

  //query
  const [
    superadminEditClient,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(
    userRole === "SUPERADMIN" ? EDIT_SUPER_ADMIN_CLIENT : EDIT_ADMIN_CLIENT,
    {
      fetchPolicy: "network-only",
      errorPolicy: "all",
      context: {
        headers: {
          "Content-Type": "application/json",
          "x-apollo-operation-name": "superadminEditClient",
        },
      },
    }
  );
  //query
  const { data: clientData, loading: clientLoading } = useQuery(
    userRole === "SUPERADMIN"
      ? GET_SUPER_ADMIN_CLIENTS_BY_ID
      : GET_ADMIN_CLIENTS_BY_ID,
    {
      fetchPolicy: "network-only",
      variables: {
        userId: clientId,
      },
    }
  );

  // state
  const [filePreview, setFilePreview] = useState("");
  const [profileImage, setProfileImage] = useState<File>(new File([], ""));
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
  });

  const onSubmit = (data: inputProps) => {
    let variables: any = {
      userId: clientId,
      firstName: data.firstName,
      middleName: data.middleName,
      email: data.email,
      address: {
        prefecture: data.prefecture,
        municipality: data.municipality,
        town: data.town,
        banchi: data.banchi,
        buildingName: data.buildingName,
        roomNumber: data.roomNumber,
        postalCode: data.postalCode,
      },
    };

    if (profileImage.name) {
      variables = {
        ...variables,
        image: profileImage,
      };
      if (data.lastName) {
        variables = {
          ...variables,
          lastName: data.lastName,
        };
      }
      if (data.firstName) {
        variables = {
          ...variables,
          firstName: data.firstName,
        };
      }
    }

    superadminEditClient({
      variables,
      refetchQueries: [
        userRole === "SUPERADMIN"
          ? GET_SUPER_ADMIN_CLIENTS_BY_ID
          : GET_ADMIN_CLIENTS_BY_ID,
      ],
    });
  };

  const getKeyData = useCallback(() => {
    if (userRole === "SUPERADMIN") {
      return "superadminGetClientById";
    } else {
      return "adminGetClientById";
    }
  }, [userRole]);

  useEffect(() => {
    if (clientData) {
      setValue("firstName", clientData?.[getKeyData()]?.firstName || "");
      setValue("middleName", clientData?.[getKeyData()]?.middleName || "");
      setValue("lastName", clientData?.[getKeyData()]?.lastName || "");
      setValue("email", clientData?.[getKeyData()]?.email || "");
      setValue("phone", clientData?.[getKeyData()]?.phone || "");
      setValue(
        "prefecture",
        clientData?.[getKeyData()]?.address?.prefecture || ""
      );
      setValue(
        "municipality",
        clientData?.[getKeyData()]?.address?.municipality || ""
      );
      setValue("town", clientData?.[getKeyData()]?.address?.town || "");
      setValue("banchi", clientData?.[getKeyData()]?.address?.banchi || "");
      setValue(
        "buildingName",
        clientData?.[getKeyData()]?.address?.buildingName || ""
      );
      setValue(
        "roomNumber",
        clientData?.[getKeyData()]?.address?.roomNumber || ""
      );
      setValue("postalCode", clientData?.[getKeyData()]?.address?.postalCode);
      setFilePreview(clientData?.[getKeyData()]?.profileImage || "");
    }
  }, [clientData, setValue, getKeyData]);

  useEffect(() => {
    if (editData) {
      toast.success("Update successful");
    }
  }, [editData, editError]);

  return (
    <div>
      <Row>
        <div className="mb-4">
          <BreadCrumbs
            routes={[
              {
                name: "client",
                active: false,
                link: "/clients",
              },
              {
                name: `${clientData?.[getKeyData()]?.firstName} ${
                  clientData?.[getKeyData()]?.middleName
                } ${clientData?.[getKeyData()]?.lastName}`,
                active: false,
                link: `/clients/${clientData?.[getKeyData()]?.id}`,
              },
              {
                name: "Edit Profile",
                active: true,
                link: `/clients/${clientData?.[getKeyData()]?.id}/edit`,
              },
            ]}
          />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={3} className="mb-4">
              <div className="d-flex justify-content-center">
                <ImageUpload
                  filePreview={filePreview}
                  setFilePreview={setFilePreview}
                  setProfileImage={setProfileImage}
                />
              </div>
            </Col>
            <Col md={6} className="mb-4">
              <Card className="formComponent p-4 border-0">
                <div>
                  <h4 className="capsOne mb-2">NAME</h4>
                  <div className="mb-4">
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="FirstName"
                          placeholder=""
                          field={field}
                          error={errors.firstName?.message}
                          invalid={!!errors.firstName}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="middleName"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Middle Name"
                          placeholder=""
                          field={field}
                          error={errors.middleName?.message}
                          invalid={!!errors.middleName}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Last Name"
                          placeholder=""
                          field={field}
                          error={errors.lastName?.message}
                          invalid={!!errors.lastName}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <h4 className="capsOne mb-2">CONTACT</h4>
                  <div className="mb-4">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Email Address"
                          placeholder=""
                          field={field}
                          error={errors.email?.message}
                          invalid={!!errors.email}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Phone Number"
                          placeholder=""
                          field={field}
                          error={errors.phone?.message}
                          invalid={!!errors.phone}
                          disabled={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <h4 className="capsOne mb-2">ADDRESS</h4>
                  <div className="mb-4">
                    <Controller
                      name="postalCode"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Postal Code "
                          placeholder=""
                          field={field}
                          error={errors.postalCode?.message}
                          invalid={!!errors.postalCode}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="prefecture"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Prefecture "
                          placeholder=""
                          field={field}
                          error={errors.prefecture?.message}
                          invalid={!!errors.prefecture}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="municipality"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Municipality "
                          placeholder=""
                          field={field}
                          error={errors.municipality?.message}
                          invalid={!!errors.municipality}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="town"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Town"
                          placeholder=""
                          field={field}
                          error={errors.town?.message}
                          invalid={!!errors.town}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="banchi"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Banchi"
                          placeholder=""
                          field={field}
                          error={errors.banchi?.message}
                          invalid={!!errors.banchi}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="buildingName"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Building Name"
                          placeholder=""
                          field={field}
                          error={errors.buildingName?.message}
                          invalid={!!errors.buildingName}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="roomNumber"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Room Number"
                          placeholder=""
                          field={field}
                          error={errors.roomNumber?.message}
                          invalid={!!errors.roomNumber}
                        />
                      )}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <div className="sticky-top">
                <CustomButton
                  btnName="Save Changes"
                  classNames="w-100"
                  // handleBtnClick={() => onSubmit()}
                  loading={editLoading}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
}
