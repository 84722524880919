import "./styles.scss";

import React, { ChangeEvent, useEffect, useState } from "react";

import isoNotification from "assets/images/notification/iosNotification.png";
import { Upload } from "lucide-react";
import { PUBLISH_GLOBAL_NOTIFICATION } from "queries/superAdminNotification";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Form, Label, Row } from "reactstrap";
import { CustomButton, InputText } from "ReuseableComponents";

import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { notificationSchema } from "./validation";

interface inputProps {
  headLine: string;
  body: string;
}

const defaultValues = {
  headLine: "",
  body: "",
};

export default function Notification() {
  const [filePreview, setFilePreview] = useState("");
  const [file, setFile] = useState<File>(new File([], ""));

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
    resolver: yupResolver(notificationSchema),
    mode: "onChange",
  });

  //query

  const [publishNotification, { data, error, loading }] = useMutation(
    PUBLISH_GLOBAL_NOTIFICATION,
    {
      errorPolicy: "all",
      context: {
        headers: {
          "Content-Type": "application/json",
          "x-apollo-operation-name": "publishGlobalNotification",
        },
      },
    }
  );

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onSubmit = (data: inputProps) => {
    let variables: {} = {
      input: {
        body: data.body,
        headline: data.headLine,
      },
    };

    if (file.name) {
      variables = {
        ...variables,
        image: file,
      };
    }

    publishNotification({
      variables,
    });
  };

  useEffect(() => {
    if (file.name !== "") {
      const blob = URL.createObjectURL(file);

      // Set the blob URI to the state
      setFilePreview(blob);
    }
  }, [file]);

  useEffect(() => {
    if (data) {
      toast.success("Notification published successfully");
      reset();
      setFile(new File([], ""));
      setFilePreview("");
    }
  }, [data, reset]);

  return (
    <div className="notification">
      <Row>
        <Col md={7}>
          <Card className="border-0">
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <div className="d-flex align-items-center mb-2 justify-content-between">
                    <Label className="bodyTwo mb-0">Thumbnail</Label>
                    <p className="labelTwo">Recommended size: 360 * 240 px</p>
                  </div>
                  <label className="image-comp">
                    {filePreview ? (
                      <img
                        src={filePreview}
                        alt="thumbnail"
                        className="file-preview"
                      />
                    ) : (
                      <>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <Upload />
                          <p className="label-two">Click to upload a file</p>
                        </div>
                        <input
                          type="file"
                          className="d-none"
                          onChange={handleImageChange}
                        />
                      </>
                    )}
                  </label>
                </div>
                <div className="mb-4">
                  <Controller
                    name="headLine"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        label="Headline"
                        placeholder="Headline"
                        field={field}
                        error={errors.headLine?.message}
                        invalid={!!errors.headLine}
                        showInfo={`${watch("headLine").length}/50`}
                      />
                    )}
                  />
                </div>
                <div className="mb-4">
                  <Controller
                    name="body"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="textarea"
                        label="Body"
                        placeholder="Type here "
                        field={{ ...field, rows: 10 }}
                        error={errors.body?.message}
                        invalid={!!errors.body}
                        showInfo={`${watch("body").length}/200`}
                      />
                    )}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <CustomButton
                    btnName="Publish"
                    classNames="px-5"
                    loading={loading}
                  />
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <div className="wrapperImage">
            <img
              alt="iso-iphone-15"
              src={isoNotification}
              className="img-fluid"
            />
            <div className="notification-details">
              <div className="notification-details-content">
                <h4> {watch("headLine")}</h4>
                <p className="body"> {watch("body")} </p>
              </div>
              {filePreview && (
                <img alt="" src={filePreview} className="img-fluid " />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
