import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Label } from "reactstrap";
import { CustomButton, InputText, ReactSelect } from "ReuseableComponents";
import {
  getServiceTaskName,
  getStatusVal,
  splitByCapitalLetter,
  useUserRole,
} from "utils";

interface FormComponentProps {
  pageFormData: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const FormComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: FormComponentProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const getTypeParams = searchParams.get("type");

  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParams = searchParams.get("serviceName");
  const serviceTypeParams = searchParams.get("serviceType");
  const serviceTypeListParams = searchParams.get("serviceTypeList");
  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const [formValues, setFormValues] = useState({} as any);

  useEffect(() => {
    if (serviceNameParams === "AbroadVisa") {
      if (translationPostData?.metadata) {
        setFormValues(
          translationPostData?.metadata?.[serviceNameParams]?.details
        );
      }
    }
    if (serviceNameParams === "JapanVisa" && serviceTypeParams) {
      if (translationPostData?.metadata) {
        setFormValues(
          translationPostData?.metadata?.[serviceNameParams][serviceTypeParams]
            .details
        );
      }
    }
  }, [translationPostData?.metadata, serviceNameParams, serviceTypeParams]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,

      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e: any, name: string) => {
    if (e?.value) {
      setFormValues({
        ...formValues,

        [name]: e?.value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: "",
      });
    }
  };

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (
      serviceNameParams &&
      serviceNameParams === "JapanVisa" &&
      serviceTypeParams
    ) {
      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          [serviceNameParams.split(" ").join("")]: {
            [serviceTypeParams]: {
              ...translationPostData.metadata[
                serviceNameParams.split(" ").join("")
              ][serviceTypeParams],
              details: formValues,
            },
          },
        },
      });
    } else if (serviceNameParams) {
      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          [serviceNameParams.split(" ").join("")]: {
            ...translationPostData.metadata[
              serviceNameParams.split(" ").join("")
            ],
            details: formValues,
          },
        },
      });
    }

    navigate(
      `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParams}&serviceType=${serviceTypeParams}&serviceTypeList=${serviceTypeListParams}&tab=02&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ""
      }&isAdmin=${isAdminParams}`
    );
  };

  return (
    <Form>
      <div className="mb-4 d-flex">
        <h4 className="titleFive">
          {getServiceTaskName(
            serviceNameParams || "",
            serviceTypeParams || "",
            serviceTypeListParams || ""
          )}
        </h4>
      </div>
      {pageFormData &&
        Object.keys(pageFormData).length > 0 &&
        Object.keys(pageFormData).map((item) => {
          //@ts-ignore
          let dataFormList = pageFormData[item];
          let inputType = Array.isArray(dataFormList)
            ? "select"
            : //@ts-ignore
            typeof dataFormList === "string"
            ? "text"
            : typeof dataFormList === "object" && item === "color"
            ? "color"
            : "";
          //@ts-ignore

          let optionValue =
            inputType === "select" &&
            dataFormList?.map((item: string) => {
              return {
                label: item,
                value: item,
              };
            });

          switch (inputType) {
            case "text":
              return (
                <div className="mb-4">
                  <InputText
                    label={splitByCapitalLetter(item)}
                    placeholder=""
                    name={item}
                    value={formValues?.[item] || ""}
                    onChange={handleChange}
                    disabled={
                      statusParams === "completed" ||
                      !getStatusVal(
                        userRole,
                        orderIdParams || "",
                        isAdminParams || ""
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              );

            case "select":
              return (
                <div className="mb-4">
                  <Label
                    className="form-label text-capitalize  bodyThree"
                    for="roles"
                  >
                    {splitByCapitalLetter(item)}
                  </Label>

                  <ReactSelect
                    isMulti={false}
                    options={optionValue}
                    isClearable={true}
                    name={item}
                    onChange={(e: any) => handleSelectChange(e, item)}
                    placeholder="Select from an option"
                    value={
                      formValues?.[item]
                        ? {
                            label: formValues?.[item] || "",
                            value: formValues?.[item] || "",
                          }
                        : ""
                    }
                    disabled={
                      statusParams === "completed" ||
                      !getStatusVal(
                        userRole,
                        orderIdParams || "",
                        isAdminParams || ""
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              );

            default:
              return null;
          }
        })}

      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mb-4 d-flex justify-content-end">
            <CustomButton
              btnName="Save"
              classNames="px-5"
              handleBtnClick={(e: MouseEvent<HTMLButtonElement>) => onSubmit(e)}
            />
          </div>
        )}
    </Form>
  );
};
