//styles
import "./styles.scss";

//react/next
import * as React from "react";
import { ComponentProps } from "react";

//third-party libary
import Select from "react-select";
import { FormFeedback, Input } from "reactstrap";

// ** React Select Theme Colors

const customStyles = {
  control: (base: any, state: any, isSelected: any) => ({
    ...base,
    background: "#FAFAFA",
    border: "1px solid #E2E2E2",
    borderRadius: 4,
    minHeight: 52,
    cursor: "pointer",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,

    "&:hover": {
      borderColor: state.isFocused ? "#3439ad" : "#845BF9",
    },
  }),

  multiValue: (base: any, state: any) => ({
    ...base,
    backgroundColor: "#3439ad",
    color: "#FBFFFE",
    borderRadius: "4px",
    padding: "4px 8px",
  }),

  multiValueLabel: (base: any, state: any) => ({
    ...base,
    color: "#FBFFFE",
  }),

  multiValueRemove: (base: any) => ({
    ...base,
    color: "#FBFFFE",
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: "#845BF9", color: "#FBFFFE" },
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected ? "#3439ad" : "#FFFFF",
    color: state.isSelected ? "#FBFFFE" : "#101010",
    textTransform: "capitalize",
    "&:hover": { backgroundColor: "#3439ad", color: "#FAFAFA" },
    cursor: "pointer",
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    // display: "none", // Hide the dropdown indicator
    color: "#000000",
    // height: "22px",
    // width: "22px",

    fontSize: "22px",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
};

type SelectProps = {
  genderVal?: object;
  isMulti: boolean;
  options: { label: string; value: string }[];
  setData?: (data: { value: string; label: string }) => void;
  onMenuScrollToBottom?: () => void;
  field?: ComponentProps<typeof Input>["field"];
  error?: ComponentProps<typeof Input>["error"];
  placeholder?: string;
  handleSelectInputChange?: (e: string) => void;
  isClearable?: boolean;
  name?: string;
  onChange?: any;
  value?: any;
  disabled?: boolean;
};

export const ReactSelect: React.FC<SelectProps> = ({
  isMulti,
  options,
  field,
  setData,
  onMenuScrollToBottom,
  genderVal,
  error,
  placeholder,
  isClearable,
  handleSelectInputChange,
  name,
  onChange,
  value,
  disabled,
}) => {
  return (
    <div className="custom-select">
      <Select
        open
        isMulti={isMulti}
        isClearable={isClearable}
        className={`react-select ${error ? "error-border" : ""}`}
        classNamePrefix="select"
        options={options}
        placeholder={placeholder ? placeholder : "All"}
        name={name}
        onChange={onChange}
        value={value}
        {...field}
        styles={customStyles}
        closeMenuOnSelect={isMulti ? false : true}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={handleSelectInputChange}
        isDisabled={disabled}
      />
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};
