import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { Card, CardBody } from "reactstrap";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import { useFileUpload } from "utils";

interface FilesProps {
  taskData: any;
  filesUrl: any;
  setFilesUrl: Dispatch<SetStateAction<any>>;
}

export const Files = ({ taskData, filesUrl, setFilesUrl }: FilesProps) => {
  const [numberOfFiles, setNumberOfFiles] = useState(1);

  const [filePreview, setFilePreview] = useState<
    { name: string; url: string }[]
  >([]);

  const {
    uploadFile,
    uploadData,
    uploadDataLoading,
    deleteFile,
    deleteFileData,
  } = useFileUpload();

  const handleAddMore = () => {
    setNumberOfFiles(numberOfFiles + 1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setFilesUrl((prevState) => ({}));
    let file = e.target.files?.[0];
    setFilesUrl([
      ...filesUrl,
      {
        file,
        componentsName: e.target.name,
      },
    ]);
    //@ts-ignore
    let imageUrl = URL.createObjectURL(e.target.files?.[0]);

    setFilePreview([
      ...filePreview,
      {
        name: e.target.name,
        url: imageUrl,
      },
    ]);
  };

  const handleDelete = (data: { url: string; name: string }) => {
    if (numberOfFiles !== 1) {
      setNumberOfFiles(numberOfFiles - 1);
    }
    setFilesUrl(
      filesUrl.filter((item: any) => item.componentsName !== data.name)
    );
    setFilePreview(filePreview.filter((item: any) => item.name !== data.name));
  };

  useEffect(() => {
    if (deleteFileData) {
    }
  }, [deleteFileData]);

  let components = [];

  for (let i = 0; i < numberOfFiles; i++) {
    components.push(
      <Card className="border-0 mb-2">
        <CardBody className="p-4">
          <PictureUpload
            name={`component-${i}`}
            onChange={handleChange}
            filePreview={filePreview[i]?.url}
            handleDeleteClick={() => handleDelete(filePreview[i])}
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      {components}
      <CustomButton
        btnName="Add more"
        classNames="px-3"
        handleBtnClick={handleAddMore}
      />
    </div>
  );
};
