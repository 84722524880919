import React, { useEffect, useState } from "react";

import axios from "axios";
import { OrderTaskBtn, UserCard } from "Components";
import { format } from "date-fns";
import { OrderModal } from "Pages/OrderCreate/Component/OrderModal/OrderModal";
import { GET_ADMIN_CLIENTS_BY_ID } from "queries/adminClient";
import { GET_SUPER_ADMIN_CLIENTS_BY_ID } from "queries/superAdminClient";
import { useSearchParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  getFullName,
  getReverseTaskStatus,
  splitByCapitalLetter,
  useUserRole,
} from "utils";

import { useQuery } from "@apollo/client";

import { DeliveryComponent } from "../Common/Delivery";
import { FilesComponent } from "../Common/Files";
// import { DeliveryComponent } from "./Delivery";
import { DetailsComponent } from "./Details";
import { PaymentComponent } from "./Payment";
import { UploadComponent } from "./Upload";
import { VisaTabBar } from "./VisaTabBar/VisaTabBar";

interface DesignPageProps {
  pageFormData: {
    availableSample: {
      name: string;
      imageUrl: string;
    }[];
    delivery: object;
    payment: object;
    type: any;
  };
  price: string;
}

export const VisaPage = ({ pageFormData, price }: DesignPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [translationPostData, setTranslationPostData] = useState({});

  const [visaPageFormData, setVisaPageFormData] = useState({}) as any;
  const [filesUrl, setFilesUrl] = useState<File[]>([]);

  const getTaskTab = searchParams.get("tab");
  const getTaskIdParams = searchParams.get("taskId");
  const getUserIdParams = searchParams.get("userId");
  const statusParams = searchParams.get("status");
  const orderId = searchParams.get("orderId");
  const serviceNameParams = searchParams.get("serviceName");
  const serviceTypeParams = searchParams.get("serviceType");
  const serviceTypeListParams = searchParams.get("serviceTypeList");
  const typeParams = searchParams.get("type");

  const isAdminParams = searchParams.get("isAdmin");

  const [showModal, setShowModal] = useState(true);

  const [completedDataVal, setCompletedDataVal] = useState({});

  const [inProgressOrderId, setInProgressOrderId] = useState("");

  const { userRole } = useUserRole();

  const [uploadFiles, setUploadFiles] = useState<Array<[]>>([]);

  //query
  const { data: clientData } = useQuery(
    userRole === "SUPERADMIN"
      ? GET_SUPER_ADMIN_CLIENTS_BY_ID
      : GET_ADMIN_CLIENTS_BY_ID,
    {
      variables: {
        userId: getUserIdParams,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    let postData = {
      taskId: getTaskIdParams,
      customerId: getUserIdParams,
      status: getReverseTaskStatus(statusParams || ""),
      amount: price,
    };
    setTranslationPostData(postData);
  }, [price, getTaskIdParams, getUserIdParams, statusParams]);

  useEffect(() => {
    if (serviceNameParams === "JapanVisa") {
      if (serviceNameParams && serviceTypeListParams && serviceTypeParams) {
        let filterServiceName = pageFormData?.type.find(
          (item: any) => item.name === splitByCapitalLetter(serviceNameParams)
        );
        let filterServiceType = filterServiceName?.services.find(
          (item: any) => item.name === serviceTypeParams
        );
        let filterServicTypeList = filterServiceType?.type.find(
          (item: any) => item.name === serviceTypeListParams
        );

        setVisaPageFormData(filterServiceType);

        setShowModal(false);
      } else {
      }
    } else {
      if (serviceNameParams && serviceTypeParams) {
        let filterServiceName = pageFormData?.type.find(
          (item: any) => item.name === splitByCapitalLetter(serviceNameParams)
        );
        setVisaPageFormData(filterServiceName);
        setShowModal(false);
      }
    }
  }, [
    serviceNameParams,
    serviceTypeListParams,
    serviceTypeParams,
    pageFormData?.type,
  ]);

  const orderUrl = () => {
    if (userRole === "SUPERADMIN") {
      return "/superadmin/clients/orders";
    }
    if (
      (userRole === "ADMIN" && statusParams === "new") ||
      isAdminParams === "false"
    ) {
      return "admin/clients/orders";
    }

    return "/admin/orders";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = orderUrl();
        const response = await axios.get(`${url}/${orderId}`);
        const taskData =
          statusParams === "new"
            ? response.data
            : userRole === "SUPERADMIN" || isAdminParams === "false"
            ? response.data
            : response.data.order;

        setSearchParams({
          type: typeParams || "",
          serviceName: serviceNameParams || "",
          serviceType: serviceTypeParams || "",
          serviceTypeList: serviceTypeListParams || "",
          tab: getTaskTab || "",
          taskId: taskData.task.id || "",
          userId: taskData.customer.id || "",
          status: statusParams || "",
          orderId: response.data.id || "",
          isAdmin: isAdminParams || "",
        });

        let postData = {
          taskId: getTaskIdParams,
          customerId: getUserIdParams,
          status: getReverseTaskStatus(statusParams || ""),
          amount: price,
          metadata: taskData.metaData,
        };
        setCompletedDataVal({
          admin: response.data.admin,
          updatedAt: response.data.updatedAt,
          createdAt: response.data.createdAt,
          order: taskData,
        });
        setTranslationPostData(postData);
        setInProgressOrderId(taskData.id);
        setUploadFiles(taskData.files);
      } catch (error) {}
    };
    if (
      orderId &&
      userRole &&
      getTaskIdParams &&
      getUserIdParams &&
      statusParams
    ) {
      fetchData();
    }
  }, [
    orderId,
    getTaskIdParams,
    getUserIdParams,
    userRole,
    price,
    serviceNameParams,
    serviceTypeParams,
    serviceTypeListParams,
    typeParams,
    statusParams,
    isAdminParams,
  ]);

  const getKeyData = () => {
    if (userRole === "SUPERADMIN") {
      return "superadminGetClientById";
    } else {
      return "adminGetClientById";
    }
  };

  const renderCompTab = () => {
    switch (getTaskTab) {
      case "01":
        return (
          <DetailsComponent
            pageFormData={visaPageFormData}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
          />
        );

      case "02":
        return (
          <UploadComponent
            pageFormData={visaPageFormData}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
          />
        );
      case "03":
        return (
          <DeliveryComponent
            pageFormData={pageFormData?.delivery}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
          />
        );
      case "04":
        return (
          <PaymentComponent
            pageFormData={pageFormData?.payment}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
            inProgressOrderId={inProgressOrderId}
          />
        );
      case "05":
        return (
          <FilesComponent
            filesUrl={filesUrl}
            setFilesUrl={setFilesUrl}
            uploadFiles={uploadFiles}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col md={4}>
            <UserCard
              fullName={getFullName(
                clientData?.[getKeyData()]?.firstName || "",
                clientData?.[getKeyData()]?.middleName || "",
                clientData?.[getKeyData()]?.lastName || ""
              )}
              email={clientData?.[getKeyData()]?.email}
              userId={clientData?.[getKeyData()]?.id}
              joinedDate={clientData?.[getKeyData()]?.createdAt || new Date()}
              address={`${clientData?.[getKeyData()]?.address?.banchi || ""} ${
                clientData?.[getKeyData()]?.address?.country || ""
              }`}
              contact={clientData?.[getKeyData()]?.phone}
              userImage={clientData?.[getKeyData()]?.profileImage}
              joinedCount={
                clientData?.[getKeyData()]?.createdAt
                  ? format(
                      new Date(clientData?.[getKeyData()]?.createdAt || ""),
                      "YYY"
                    )
                  : ""
              }
              totalCount={clientData?.[getKeyData()]?.orderCount}
              userName={clientData?.[getKeyData()]?.userName}
              showActions={false}
              showDelete={userRole === "SUPERADMIN"}
              // editClick={() => navigate(`/clients/${clientId}/edit`)}
              showEdit={true}
              showJoinedCount={true}
              showTodayCount={false}
              showTotalCount={true}
            />
          </Col>
          <Col md={6}>
            {!showModal && (
              <>
                <VisaTabBar />
                {renderCompTab()}
              </>
            )}
          </Col>
          <Col md={2}>
            <OrderTaskBtn
              showRejectBtn={orderId && isAdminParams === "true" ? true : false}
              showStartBtn={
                statusParams === "new" && orderId && isAdminParams === "false"
                  ? true
                  : false
              }
              filesUrl={filesUrl}
              completedDataVal={completedDataVal}
            />
          </Col>
        </Row>
      </div>
      <OrderModal
        showModal={showModal}
        setShowModal={setShowModal}
        pageFormData={pageFormData}
        visaPostData={translationPostData}
        setVisaPostData={setTranslationPostData}
        setVisaPageFormData={setVisaPageFormData}
        visaPageFormData={visaPageFormData}
      />
    </>
  );
};
