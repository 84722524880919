import React from "react";

import { Navigate } from "react-router-dom";
import { getLocalStorage } from "utils";

interface IProtectedRoute {
  children: React.ReactNode;
  role: string[];
}

export const ProtectedRoute = ({ children, role }: IProtectedRoute) => {
  //var
  let token = getLocalStorage("token");
  const tokenData = token ? JSON.parse(token) : null;

  const userRole = getLocalStorage("userType");

  const userType = userRole ? JSON.parse(userRole) : [];

  if (!tokenData?.isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  } else if (
    role &&
    userType &&
    role.some((item: any) => userType.includes(item))
  ) {
    return <div> {children} </div>;
  } else {
    return <Navigate to={"/unAuthorized"} />;
  }
};
