import { gql } from "@apollo/client";

export const GET_COUNT_ANALYTICS = gql`
  query ($limit: Int, $page: Int, $dateFilterInput: DateFilterInput) {
    superadminGetCountAnalytics(
      paginationInput: { limit: $limit, page: $page }
      dateFilterInput: $dateFilterInput
    ) {
      data {
        id
        totalAdmins
        completedOrders
        newOrders
        createdAt
      }
    }
  }
`;

export const GET_TOTAL_COUNT_ANALYTICS = gql`
  query ($limit: Int, $page: Int) {
    superadminGetTotalCountAnalytic(
      paginationInput: { limit: $limit, page: $page }
    ) {
      admins {
        change
        name
        status
        total
      }
      clients {
        change
        name
        status
        total
      }
      tasks {
        change
        name
        status
        total
      }
    }
  }
`;

export const GET_TASK_ANALYTICS = gql`
  query ($limit: Int, $page: Int, $dateFilterInput: DateFilterInput) {
    superadminGetTasksAnalytic(
      paginationInput: { limit: $limit, page: $page }
      dateFilterInput: $dateFilterInput
    ) {
      data {
        id
        serviceName
        customer {
          id
          firstName
          middleName
          lastName
          profileImage
        }
        admin {
          id
          firstName
          middleName
          lastName
          profileImage
        }
        task {
          id
          name
          type
        }
        status
        createdAt
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_TASK_BY_CATEGORY_ANALYTICS = gql`
  query ($limit: Int, $page: Int) {
    superadminTaskByCatorgiesAnalytic(
      paginationInput: { limit: $limit, page: $page }
    ) {
      data {
        name
        percent
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_STAFF_ANALYTICS = gql`
  query ($limit: Int, $page: Int) {
    superadminGetStaffsAnalytic(
      paginationInput: { limit: $limit, page: $page }
    ) {
      data {
        id
        firstName
        middleName
        lastName
        taskCompletedCount
        taskCount
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;
