import "./styles.scss";

import React from "react";

import { Label, Progress } from "reactstrap";

interface ProgressLoaderProps {
  progressValue: number;
}

export const ProgressLoader = ({ progressValue }: ProgressLoaderProps) => {
  return (
    <div className="progressLoader w-100 mx-3">
      <div className="progressLoader-label d-flex align-items-center justify-content-between">
        <Label className="labelTwo">Progress</Label>
        <span className="labelTwo"> {`${progressValue}%`} </span>
      </div>
      <Progress className="progressBar" value={progressValue} />
    </div>
  );
};
