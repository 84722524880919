import React from "react";

import logo from "assets/images/logo/appLogo.svg";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "ReuseableComponents";
import { removeLocalStorage } from "utils";

export const UnAuthorized = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    removeLocalStorage("token");
    removeLocalStorage("userType");
    navigate("/login");
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center "
      style={{
        height: "100vh",
      }}
    >
      <img alt="logo" src={logo} />
      <div className="mt-4">
        <h3 className="text-center mb-3 titleOne">Access Denied</h3>
        <p className="text-center bodyOne">
          {" "}
          You are restricted to enter this page{" "}
        </p>
      </div>
      <div className="mt-3">
        <CustomButton
          btnName="Go Back"
          classNames="px-5 me-3"
          handleBtnClick={() => navigate(-1)}
        />
        <CustomButton
          btnName="Login"
          classNames="px-5 secondary"
          handleBtnClick={handleClick}
        />
      </div>
    </div>
  );
};
