import "./styles.scss";

import React, { useEffect, useState } from "react";

import { AuthLayout } from "Components";
import { FORGET_PASSWORD_GENERATE_CODE } from "queries/authQuery";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";
import { CustomButton, InputText, Logo } from "ReuseableComponents";

import { useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { resetPasswordSchema } from "./validation";

interface LoginInput {
  phone: string;
}

export default function ResetPassword() {
  //var
  const navigate = useNavigate();
  //react-hook-form
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<LoginInput>({
    resolver: yupResolver(resetPasswordSchema),
  });

  //state
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  //query
  const [forgetPasswordGenerateCode, { data, error, loading }] = useLazyQuery(
    FORGET_PASSWORD_GENERATE_CODE
  );

  const onSubmit = (data: LoginInput) => {
    setPhoneNumber(data.phone);
    forgetPasswordGenerateCode({
      variables: {
        phone: data.phone,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message || "Something went wrong");
    }
    if (data) {
      navigate(`/otp/?phone=${phoneNumber}`);
    }
  }, [error, data, navigate]);

  return (
    <AuthLayout>
      <Row>
        <Col md={12}>
          <div className="resetPasswordDetails">
            <Logo />
            <div
              className={`containerWrapper ${
                isResetPassword ? "w-100" : "w-50"
              }`}
            >
              {!isResetPassword ? (
                <div>
                  <h1 className="titleTwo title">Reset Password</h1>
                  <div className="formWrapper">
                    <Form
                      onSubmit={handleSubmit(onSubmit)}
                      className="d-flex flex-column justify-content-center align-items-end"
                    >
                      <div className="defaultMargin w-100 ">
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <InputText
                              label="Mobile Number"
                              placeholder="Enter your mobile number"
                              icon
                              field={field}
                              error={errors.phone?.message}
                              invalid={!!errors.phone}
                            />
                          )}
                        />
                      </div>

                      <CustomButton
                        btnName="Send OTP"
                        classNames="w-100"
                        color="primary"
                        loading={loading}
                      />
                    </Form>
                  </div>
                </div>
              ) : (
                <p className="bodyOne">
                  {" "}
                  A reset link has been sent to your email address. Please click
                  to set new password.{" "}
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </AuthLayout>
  );
}
