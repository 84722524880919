import { AppLayout } from "Components";
import Advertisement from "Pages/Advertisement";
import Otp from "Pages/Auth/Otp";
import ResetPassword from "Pages/Auth/ResetPassword";
import SetPassword from "Pages/Auth/SetPassword";
import Chat from "Pages/Chat";
import ClientsEdit from "Pages/Clients/Edit";
import Clients from "Pages/Clients/List";
import ClientsView from "Pages/Clients/View";
import Feedbacks from "Pages/Feedbacks/List";
import MyAccount from "Pages/MyAccount";
import Notification from "Pages/Notification";
import Order from "Pages/Order";
import OrderCreate from "Pages/OrderCreate";
import Profile from "Pages/Profile";
import ProfileEdit from "Pages/Profile/Edit";
import Teams from "Pages/Teams";
import TeamsView from "Pages/Teams/View";
import { UnAuthorized } from "Pages/UnAuthorized/UnAuthorized";
import { createBrowserRouter } from "react-router-dom";

import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Dashboard";
import { ProtectedRoute } from "./protectedRoute";
import { UnProtectedRoute } from "./unprotectedRoutes";

export const router = createBrowserRouter(
  [
    {
      path: "/login",
      element: (
        <UnProtectedRoute>
          <Login />
        </UnProtectedRoute>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <UnProtectedRoute>
          <ResetPassword />
        </UnProtectedRoute>
      ),
    },
    {
      path: "/set-password",
      element: (
        <UnProtectedRoute>
          <SetPassword />
        </UnProtectedRoute>
      ),
    },
    {
      path: "/otp",
      element: (
        <UnProtectedRoute>
          <Otp />
        </UnProtectedRoute>
      ),
    },
    {
      path: "/",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <Dashboard />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/clients",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <Clients />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/clients/:clientId",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <ClientsView />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/clients/:clientId/edit",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <ClientsEdit />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/teams",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <Teams />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/teams/:teamsId",
      element: (
        <ProtectedRoute role={["SUPERADMIN"]}>
          <AppLayout>
            <TeamsView />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/feedbacks",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <Feedbacks />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <ProtectedRoute role={["ADMIN"]}>
          <AppLayout>
            <Profile />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/edit",
      element: (
        <ProtectedRoute role={["ADMIN"]}>
          <AppLayout>
            <ProfileEdit />
          </AppLayout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/my-account",
      element: (
        <ProtectedRoute role={["ADMIN", "SUPERADMIN"]}>
          <AppLayout>
            <MyAccount />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/my-account/:myAccount",
      element: (
        <ProtectedRoute role={["SUPERADMIN", "ADMIN"]}>
          <AppLayout>
            <MyAccount />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertisement",
      element: (
        <ProtectedRoute role={["SUPERADMIN"]}>
          <AppLayout>
            <Advertisement />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/notification",
      element: (
        <ProtectedRoute role={["SUPERADMIN"]}>
          <AppLayout>
            <Notification />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/order/:orderId",
      element: (
        <ProtectedRoute role={["SUPERADMIN", "ADMIN"]}>
          <AppLayout>
            <Order />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/create-order",
      element: (
        <ProtectedRoute role={["SUPERADMIN", "ADMIN"]}>
          <AppLayout>
            <OrderCreate />
          </AppLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/chat",
      element: (
        <ProtectedRoute role={["SUPERADMIN", "ADMIN"]}>
          <AppLayout>
            <Chat />
          </AppLayout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/chat",
      element: (
        <ProtectedRoute role={["ADMIN"]}>
          <AppLayout>
            <Chat />
          </AppLayout>
        </ProtectedRoute>
      ),
    },

    {
      path: "unAuthorized",
      element: <UnAuthorized />,
    },
  ],
  { basename: "/" }
);
