import { useEffect, useState } from "react";

import { ImageUpload } from "Pages/Clients/Edit/Components/ImageUpload/ImageUpload";
import { GET_ADMIN_CLIENTS_BY_ID } from "queries/adminClient";
import { ME, SET_PROFILE } from "queries/authQuery";
import { GET_SUPER_ADMIN_CLIENTS_BY_ID } from "queries/superAdminClient";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "reactstrap";
import { BreadCrumbs, CustomButton, InputText } from "ReuseableComponents";
import { useUserContext, useUserRole } from "utils";

import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { editClientSchema } from "./Validation/validation";

interface inputProps {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  email?: string;
  phone?: string;
  prefecture?: string;
  municipality?: string;
  town?: string;
  banchi?: string;
  buildingName?: string;
  roomNumber?: string;
  postalCode?: string;
}

const defaultValues = {
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  phone: "",
  prefecture: "",
  municipality: "",
  town: "",
  banchi: "",
  buildingName: "",
  roomNumber: "",
};

export default function ProfileEdit() {
  //var
  const { clientId } = useParams();
  const { userRole } = useUserRole();
  const { user } = useUserContext();

  //query
  const [
    superadminEditClient,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(SET_PROFILE, {
    errorPolicy: "all",
    context: {
      headers: {
        "Content-Type": "application/json",
        "x-apollo-operation-name": "setProfile",
      },
    },
  });
  //query
  // state
  const [filePreview, setFilePreview] = useState("");
  const [profileImage, setProfileImage] = useState<File>(new File([], ""));

  const {
    control,
    getValues,
    setValue,
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(editClientSchema),
  });

  const onSubmit = () => {
    const firstName = getValues("firstName");
    const middleName = getValues("middleName");
    const lastName = getValues("lastName");
    const email = getValues("email");
    const phone = getValues("phone");
    const prefecture = getValues("prefecture");
    const municipality = getValues("municipality");
    const town = getValues("town");
    const banchi = getValues("banchi");
    const buildingName = getValues("buildingName");
    const roomNumber = getValues("roomNumber");
    const postalCode = getValues("postalCode");

    let variables: any = {
      userId: clientId,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      email: email || "",
      address: {
        prefecture: prefecture,
        municipality: municipality,
        town: town,
        banchi: banchi,
        buildingName: buildingName,
        roomNumber: roomNumber,
        postalCode: postalCode,
      },
    };

    if (profileImage.name) {
      variables = {
        ...variables,
        image: profileImage,
      };
    }

    superadminEditClient({
      variables,
      refetchQueries: [
        userRole === "SUPERADMIN"
          ? GET_SUPER_ADMIN_CLIENTS_BY_ID
          : GET_ADMIN_CLIENTS_BY_ID,
        ME,
      ],
    });
  };

  useEffect(() => {
    if (user) {
      setValue("firstName", user?.firstName);
      setValue("middleName", user?.middleName);
      setValue("lastName", user?.lastName);
      setValue("email", user?.email);
      setValue("phone", user?.phone);
      setValue("prefecture", user?.userAddress?.prefecture);
      setValue("municipality", user?.userAddress?.municipality);
      setValue("town", user?.userAddress?.town);
      setValue("banchi", user?.userAddress?.banchi);
      setValue("buildingName", user?.userAddress?.buildingName);
      setValue("roomNumber", user?.userAddress?.roomNumber);
      setValue("postalCode", user?.userAddress?.postalCode);
      setFilePreview(user?.profileImage);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (editData) {
      toast.success("Update successful");
    }
  }, [editData]);

  const isDisable = () => {
    const firstName = watch("firstName");
    const middleName = watch("middleName");
    const lastName = watch("lastName");
    const email = watch("email");
    const phone = watch("phone");
    const prefecture = watch("prefecture");
    const municipality = watch("municipality");
    const town = watch("town");
    const banchi = watch("banchi");
    const buildingName = watch("buildingName");
    const roomNumber = watch("roomNumber");
    const postalCode = watch("postalCode");

    if (
      user?.firstName !== firstName ||
      user?.middleName !== middleName ||
      user?.lastName !== lastName ||
      user?.email !== email ||
      // user?.phone !== phone ||
      user?.userAddress?.prefecture !== prefecture ||
      user?.userAddress?.municipality !== municipality ||
      user?.userAddress?.town !== town ||
      user?.userAddress?.banchi !== banchi ||
      user?.userAddress?.buildingName !== buildingName ||
      user?.userAddress?.roomNumber !== roomNumber ||
      user?.profileImage !== filePreview ||
      user?.userAddress?.postalCode !== postalCode
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <BreadCrumbs
        routes={[
          {
            name: "profile",
            active: false,
            link: `/profile`,
          },
          {
            name: `edit`,
            active: true,
            link: `/profile/edit/`,
          },
        ]}
      />
      <Row>
        <Col md={3}>
          <div className="d-flex justify-content-center">
            <ImageUpload
              filePreview={filePreview}
              setFilePreview={setFilePreview}
              setProfileImage={setProfileImage}
            />
          </div>
        </Col>
        <Col md={9}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Card className="formComponent p-4 border-0">
                  <div>
                    <h4 className="capsOne mb-2">NAME</h4>

                    <div className="mb-4">
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="First Name"
                            placeholder=""
                            field={field}
                            error={errors.firstName?.message}
                            invalid={!!errors.firstName}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Middle Name"
                            placeholder=""
                            field={field}
                            error={errors.middleName?.message}
                            invalid={!!errors.middleName}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Last Name"
                            placeholder=""
                            field={field}
                            error={errors.lastName?.message}
                            invalid={!!errors.lastName}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="capsOne mb-2">CONTACT</h4>

                    <div className="mb-4">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Email Address"
                            placeholder=""
                            field={field}
                            error={errors.email?.message}
                            invalid={!!errors.email}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Phone Number"
                            placeholder=""
                            field={field}
                            error={errors.phone?.message}
                            invalid={!!errors.phone}
                            disabled={true}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="capsOne mb-2">ADDRESS</h4>
                    <div className="mb-4">
                      <Controller
                        name="postalCode"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="postalCode "
                            placeholder=""
                            field={field}
                            error={errors.postalCode?.message}
                            invalid={!!errors.postalCode}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="prefecture"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Prefecture "
                            placeholder=""
                            field={field}
                            error={errors.prefecture?.message}
                            invalid={!!errors.prefecture}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="municipality"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Municipality "
                            placeholder=""
                            field={field}
                            error={errors.municipality?.message}
                            invalid={!!errors.municipality}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="town"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Town"
                            placeholder=""
                            field={field}
                            error={errors.town?.message}
                            invalid={!!errors.town}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="banchi"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Banchi"
                            placeholder=""
                            field={field}
                            error={errors.banchi?.message}
                            invalid={!!errors.banchi}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="buildingName"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Building Name"
                            placeholder=""
                            field={field}
                            error={errors.buildingName?.message}
                            invalid={!!errors.buildingName}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="roomNumber"
                        control={control}
                        render={({ field }) => (
                          <InputText
                            label="Room Number"
                            placeholder=""
                            field={field}
                            error={errors.roomNumber?.message}
                            invalid={!!errors.roomNumber}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <div className="position-sticky sticky-top">
                  <CustomButton
                    btnName="Save Changes"
                    classNames="w-100"
                    loading={editLoading}
                    disabled={isDisable()}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
