import React, { Dispatch, SetStateAction, useEffect } from "react";

import { ADD_ADMIN_CLIENT } from "queries/adminClient";
import { ADD_SUPER_ADMIN_CLIENT } from "queries/superAdminClient";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "reactstrap";
import {
  CustomButton,
  InputText,
  PasswordInput,
  SidebarModal,
} from "ReuseableComponents";
import { useUserRole } from "utils";

import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { addClientSchema } from "./validation";

interface inputProps {
  phone: string;
  password: string;
}

interface SidebarComponentProps {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
}

const defaultValues = {
  phone: "",
  password: "",
};

export const SidebarComponent = ({
  showSidebar,
  setShowSidebar,
}: SidebarComponentProps) => {
  //var
  const navigate = useNavigate();
  const { userRole } = useUserRole();

  //query
  const [superadminAddClient, { data, loading, error }] = useMutation(
    userRole === "SUPERADMIN" ? ADD_SUPER_ADMIN_CLIENT : ADD_ADMIN_CLIENT,
    {
      errorPolicy: "all",
    }
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
    resolver: yupResolver(addClientSchema),
  });

  const onSubmit = (data: inputProps) => {
    superadminAddClient({
      variables: {
        phone: data.phone,
        password: data.password,
        countryCode: "81",
      },
    });
  };

  useEffect(() => {
    if (data) {
      setShowSidebar(false);
      navigate("/clients");
    }
  }, [data, navigate, setShowSidebar]);

  return (
    <SidebarModal
      showSidebarModal={showSidebar}
      setShowSidebarModal={setShowSidebar}
      title="New Client"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputText
                label="Phone No."
                placeholder="Enter 10 digit number"
                field={field}
                error={errors.phone?.message}
                invalid={!!errors.phone}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordInput
                label="Password"
                placeholder="Set password"
                icon={false}
                field={field}
                error={errors.password?.message}
              />
            )}
          />
        </div>
        <CustomButton
          btnName="Add Client"
          classNames="w-100"
          loading={loading}
        />
      </Form>
    </SidebarModal>
  );
};
