import "./styles.scss";

import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import uploadArrowIcon from "assets/icons/uploadArrow.svg";
import { ProgressLoader } from "Components";
import { Col, Label, Row } from "reactstrap";
import { ModalComponent } from "ReuseableComponents/Modal/Modal";

interface DashedImgComponentProps {
  filePreview?: string;

  handleDelete?: () => void;
  handleReplace?: (e: ChangeEvent<HTMLInputElement>) => void;

  showActionBtn?: boolean;
  disabled?: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  progressValue?: number;
  position: number;
  loaderPosition: number | null;
  showDeleteConfirmModal: boolean;

  setShowDeleteConfirmModal: Dispatch<SetStateAction<boolean>>;
  deleteImgLoading: boolean;
  handleFileDelete?: () => void;
}

export const DashedImgComponent = ({
  filePreview,
  handleDelete,
  handleReplace,
  showActionBtn,
  disabled,
  progressValue,
  handleChange,
  position,
  loaderPosition,
  showDeleteConfirmModal,
  setShowDeleteConfirmModal,
  deleteImgLoading,
  handleFileDelete,
}: // position
DashedImgComponentProps) => {
  const UploadButton = ({ buttonName }: { buttonName: string }) => {
    return (
      <Label className="w-100">
        <div
          className={`uploadButton text-center cursorPointer ${
            buttonName.toLowerCase() === "delete" && "secondary"
          } `}
          onClick={() => {
            if (buttonName.toLowerCase() === "delete" && handleDelete) {
              return handleDelete();
            } else {
              return;
            }
          }}
        >
          {buttonName}
        </div>

        {buttonName.toLowerCase() !== "delete" && (
          <input
            type="file"
            className="d-none"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (buttonName.toLowerCase() === "replace" && handleReplace) {
                return handleReplace(e);
              }
              handleChange(e);
            }}
            disabled={disabled}
          />
        )}
      </Label>
    );
  };

  return (
    <>
      <div className="dashedImgComponent d-flex w-100">
        <Row className="w-100">
          <Col
            md={showActionBtn ? 8 : 12}
            className={`${!showActionBtn ? "pe-0" : ""}`}
          >
            <div className="w-100 imageWrapper">
              <Label className="uploadSection w-100 d-flex align-items-center justify-content-center">
                {filePreview ? (
                  <div className="image">
                    <img alt="" src={filePreview} className="img-fluid" />
                  </div>
                ) : progressValue &&
                  progressValue !== 100 &&
                  loaderPosition === position ? (
                  <ProgressLoader progressValue={progressValue} />
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <img
                      alt="upload"
                      src={uploadArrowIcon}
                      className="img-fluid "
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <p className="labelTwo"> Tap to upload a file </p>
                  </div>
                )}
              </Label>
            </div>
          </Col>
          <Col md={4} className={`${!showActionBtn ? "d-none" : ""}`}>
            <div className="mx-3">
              {!filePreview ||
              (progressValue &&
                progressValue < 100 &&
                loaderPosition === position) ? (
                UploadButton({
                  buttonName: "Upload",
                })
              ) : (
                <>
                  <div>
                    {UploadButton({
                      buttonName: "Delete",
                    })}
                  </div>
                  <div>
                    {UploadButton({
                      buttonName: "Replace",
                    })}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <ModalComponent
        showModal={showDeleteConfirmModal}
        setShowModal={setShowDeleteConfirmModal}
        showFooter
        modalTitle="Delete File?"
        btnPrimaryText="Yes, remove"
        btnSecondaryText="Go back"
        btnPrimaryClick={handleFileDelete}
        primaryLoading={deleteImgLoading}
      >
        <span className="bodyTwo">
          Are you sure you want to remove this file?
        </span>
      </ModalComponent>
    </>
  );
};
