import { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  ADD_SUPER_ADMIN_ADS,
  DELETE_SUPER_ADMIN_ADS,
  GET_SUPER_ADMIN_ADS,
  UPDATE_SUPER_ADMIN_ADS,
} from "queries/superAdminAds";
import { DashedImgComponent } from "ReuseableComponents";

import { useMutation, useQuery } from "@apollo/client";

interface UserAds {
  adsType: "HOME_SCREEN_BOTTOM" | "HOME_SCREEN_SLIDER" | "PROFILE_SCREEN";
}

export const useAds = ({ adsType }: UserAds) => {
  const [filePreview, setFilePreview] = useState<
    { id: string; image: string; position: string; name: string }[] | null
  >(null);

  const [progressValue, setProgressValue] = useState(0);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [loaderPosition, setLoaderPosition] = useState<number | null>(null);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const { data } = useQuery(GET_SUPER_ADMIN_ADS, {
    fetchPolicy: "cache-and-network",

    variables: {
      type: adsType,
    },
  });

  const [addImg, { data: addImageData, error: addImageError }] = useMutation(
    ADD_SUPER_ADMIN_ADS,
    {
      errorPolicy: "all",
      context: {
        headers: {
          "Content-Type": "application/json",
          "x-apollo-operation-name": "superadminCreateAdvertisement",
        },
      },
    }
  );

  const [updateImg, { data: updateImageData, error: updateImgError }] =
    useMutation(UPDATE_SUPER_ADMIN_ADS, {
      errorPolicy: "all",
      context: {
        headers: {
          "Content-Type": "application/json",
          "x-apollo-operation-name": "superadminUpadateAdvertisement",
        },
      },
    });

  const handleAddImage = (
    e: ChangeEvent<HTMLInputElement>,
    position: number
  ) => {
    timerRef.current = setInterval(() => {
      setProgressValue((prevTimer) => prevTimer + 1);
    }, 30);
    if (e.target.files) {
      addImg({
        variables: {
          image: e.target.files[0],
          name: adsType,
          type: adsType,
          position: position ?? 1,
        },
        refetchQueries: [GET_SUPER_ADMIN_ADS],
      });
    }
  };

  useEffect(() => {
    if (progressValue === 100) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [progressValue]);

  useEffect(() => {
    if (addImageData || updateImageData) {
      timeoutRef.current = setTimeout(() => {
        setProgressValue(0);
      }, 3000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [addImageData, updateImageData]);

  useEffect(() => {
    if (addImageError || updateImgError) {
      setProgressValue(0);
    }
  }, [addImageError, updateImgError]);

  const [deleteImg, { data: deleteImgData, loading: deleteImgLoading }] =
    useMutation(DELETE_SUPER_ADMIN_ADS, {
      errorPolicy: "all",
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
    });

  const handleReplace = (
    e: ChangeEvent<HTMLInputElement>,
    imageId: string,
    position: number
  ) => {
    let prev = filePreview?.filter(
      (item) => item.position.toString() !== position.toString()
    );

    setFilePreview(prev ?? []);
    setProgressValue(0);
    timerRef.current = setInterval(() => {
      setProgressValue((prevTimer) => prevTimer + 1);
    }, 30);
    if (e.target.files) {
      updateImg({
        variables: {
          id: imageId,
          image: e.target.files[0],
          name: adsType,
          position: position,
        },
        refetchQueries: [GET_SUPER_ADMIN_ADS],
      });
    }
  };

  const handleDelete = (imageId: string) => {
    deleteImg({
      variables: {
        id: imageId,
      },
      refetchQueries: [GET_SUPER_ADMIN_ADS],
    });
  };

  useEffect(() => {
    if (data) {
      setFilePreview(data.superadminGetAdvertisements.data);
    }
  }, [data]);

  useEffect(() => {
    if (deleteImgData) {
      let file = filePreview?.filter(
        (item) => Number(item.position) !== loaderPosition
      );

      setFilePreview(file ?? []);
      setShowDeleteConfirmModal(false);
    }
  }, [deleteImgData]);

  const renderImageComponent = ({
    image,
    position,
    imageId,
  }: {
    image?: string;
    position: number;
    imageId: string;
  }) => {
    let file = filePreview?.find((item) => Number(item.position) === position);

    return (
      <>
        <DashedImgComponent
          filePreview={file?.image ?? ""}
          handleDelete={() => {
            setLoaderPosition(position);
            setShowDeleteConfirmModal(true);
          }}
          handleReplace={(e: ChangeEvent<HTMLInputElement>) => {
            setLoaderPosition(position);
            handleReplace(e, imageId, position);
          }}
          showActionBtn={true}
          handleChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLoaderPosition(position);
            handleAddImage(e, position);
          }}
          progressValue={progressValue}
          position={position}
          loaderPosition={loaderPosition}
          deleteImgLoading={deleteImgLoading}
          setShowDeleteConfirmModal={setShowDeleteConfirmModal}
          showDeleteConfirmModal={showDeleteConfirmModal}
          handleFileDelete={() => {
            let file = filePreview?.find(
              (item) => Number(item.position) === loaderPosition
            );

            handleDelete(file?.id ?? "");
          }}
        />
      </>
    );
  };

  return {
    renderImageComponent,
    filePreview,
  };
};
