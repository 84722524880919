//styles
import "flatpickr/dist/flatpickr.css";
import "./styles.scss";

import { Dispatch, SetStateAction } from "react";

import { X } from "lucide-react";
import Flatpickr from "react-flatpickr";

interface props {
  handleDateChange?: (data: Date[]) => void;
  setPicker: Dispatch<SetStateAction<string[]>>;
  mode: "range" | "time" | "multiple" | "single";
  value?: string[];
  placeholder?: string;
}

export const DatePicker: React.FC<props> = ({
  value,
  mode,
  handleDateChange,
  placeholder,
  setPicker,
}) => {
  return (
    <>
      <div className="datePicker">
        <Flatpickr
          name="releaseDay"
          id="range-picker"
          options={{
            mode: mode,
          }}
          className={` form-control custom-date ${value && "pe-4"}`}
          placeholder={placeholder}
          value={value && value.length > 0 ? value : ""}
          onChange={handleDateChange}
        />
        {value && value.length > 0 && (
          <X
            size={16}
            className="datePicker_icon"
            onClick={() => setPicker([])}
          />
        )}
      </div>
    </>
  );
};
