import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import pdfIcon from "assets/icons/pdf.svg";
import { useSearchParams } from "react-router-dom";
import { Card } from "reactstrap";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import { getStatusVal, useUserRole } from "utils";

interface FilesProps {
  filesUrl: any;
  setFilesUrl: Dispatch<SetStateAction<any>>;
  uploadFiles?: Array<[]>;
}

export const FilesComponent = ({
  filesUrl,
  setFilesUrl,
  uploadFiles,
}: FilesProps) => {
  const [searchParams] = useSearchParams();

  const statusParams = searchParams.get("status");
  const orderIdParams = searchParams.get("orderId");
  const isAdminParams = searchParams.get("isAdmin");

  const [numberOfFiles, setNumberOfFiles] = useState(1);

  const { userRole } = useUserRole();

  const [filePreview, setFilePreview] = useState<
    { name: string; url: string }[]
  >([]);

  const handleAddMore = () => {
    setNumberOfFiles(numberOfFiles + 1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0];
    setFilesUrl([
      ...filesUrl,
      {
        file,
        componentsName: e.target.name,
      },
    ]);
  };

  useEffect(() => {
    if (filesUrl.length > 0) {
      let data = filesUrl.map((item: any) => {
        if (item?.file?.type === "application/pdf") {
          return {
            name: item.componentsName,
            url: pdfIcon,
          };
        }
        return {
          name: item.componentsName,
          url: URL.createObjectURL(item.file),
        };
      });
      setFilePreview(data);
      setNumberOfFiles(filesUrl.length);
    }
  }, [filesUrl]);

  useEffect(() => {
    if (uploadFiles && uploadFiles.length > 0) {
      let data = uploadFiles.map((item: any) => {
        let imgUrl = item.split("/");
        let extensionAndName = imgUrl[imgUrl.length - 1];

        let splitExtensionAndName = extensionAndName.split(".");

        if (splitExtensionAndName[splitExtensionAndName.length - 1] === "pdf") {
          return {
            name: splitExtensionAndName[0],
            url: pdfIcon,
          };
        } else {
          return {
            name: splitExtensionAndName[0],
            url: item,
          };
        }
      });

      setFilePreview(data);
      setNumberOfFiles(uploadFiles.length);
    }
  }, [uploadFiles]);

  const handleDelete = (data: { url: string; name: string }) => {
    if (numberOfFiles !== 1) {
      setNumberOfFiles(numberOfFiles - 1);
    }
    setFilesUrl(
      filesUrl.filter((item: any) => item.componentsName !== data.name)
    );
    setFilePreview(filePreview.filter((item: any) => item.name !== data.name));
  };

  let components = [];

  for (let index = 0; index < numberOfFiles; index++) {
    components.push(
      <Card className="border-0 p-4 mb-4" key={index}>
        <PictureUpload
          name={`component-${index}`}
          onChange={handleChange}
          filePreview={filePreview[index]?.url}
          handleDeleteClick={() => handleDelete(filePreview[index])}
          disabled={statusParams === "completed" ? true : false}
        />
      </Card>
    );
  }

  return (
    <div>
      {components}
      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <CustomButton
            btnName="Add more"
            classNames="secondary px-5"
            handleBtnClick={handleAddMore}
          />
        )}
    </div>
  );
};
