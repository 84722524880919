import { gql } from "@apollo/client";

export const GET_ADMIN_CLIENTS = gql`
  query (
    $limit: Int
    $page: Int
    $status: UserStatusTypes
    $keyword: String
    $orderRange: OrderNumberInput
    $dateRange: DateFilterInput
  ) {
    adminGetClients(
      paginationInput: { limit: $limit, page: $page }
      filterInput: {
        status: $status
        keyword: $keyword
        orderRange: $orderRange
        dateRange: $dateRange
      }
    ) {
      data {
        id
        email
        firstName
        lastName
        middleName
        createdAt
        taskCount
        profileImage
        phone
        orderCount
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_ADMIN_CLIENTS_BY_ID = gql`
  query ($userId: String!) {
    adminGetClientById(userId: $userId) {
      id
      firstName
      lastName
      middleName
      email
      phone
      createdAt
      taskCount
      taskCompletedCount
      orderCount
      profileImage
      address {
        country
        city
        banchi
        municipality
        prefecture
        town
        buildingName
        roomNumber
        postalCode
      }
    }
  }
`;

export const EDIT_ADMIN_CLIENT = gql`
  mutation (
    $userId: String!
    $address: UserAddressInput
    $firstName: String
    $lastName: String
    $middleName: String
    $email: String
    $image: Upload
  ) {
    adminEditClient(
      userId: $userId
      image: $image
      setProfileInput: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        address: $address
        email: $email
      }
    ) {
      id
    }
  }
`;

export const ADD_ADMIN_CLIENT = gql`
  mutation ($phone: String, $password: String!, $countryCode: String) {
    adminAddClient(
      addClientInput: {
        phone: $phone
        password: $password
        countryCode: $countryCode
      }
    )
  }
`;

export const DELETE_ADMIN_CLIENT = gql`
  mutation ($id: String!) {
    adminDeleteClient(id: $id)
  }
`;
