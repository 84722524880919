import React from "react";

import { Card, CardBody, Form } from "reactstrap";
import { InputText } from "ReuseableComponents";
import { splitByCapitalLetter } from "utils";

interface PassportComponentProps {
  details: {
    serviceName: string;
    metaData: {
      details: any;
    };
  };
}

export const PassportComponent = ({ details }: PassportComponentProps) => {
  return (
    <Card className="border-0">
      <CardBody className="p-4">
        <h4 className="titleFour textCapitalize mb-3">{details.serviceName}</h4>

        {Object.keys(details.metaData.details).map((item) => {
          let data = details.metaData.details[item];
          return (
            <div>
              <h6 className="capsTwo mb-4">
                {" "}
                {splitByCapitalLetter(item).toUpperCase()}{" "}
              </h6>
              <Form>
                {Object.keys(data).map((item) => {
                  return (
                    <div className="mb-4">
                      <InputText
                        label={splitByCapitalLetter(item)}
                        field={{
                          value: data[item],
                        }}
                      />
                    </div>
                  );
                })}
              </Form>
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};
