import React from "react";

import { Card, CardBody } from "reactstrap";
import { OrderImgComponent } from "ReuseableComponents";

interface PaymentProps {
  taskData: any;
}

export const Payment = ({ taskData }: PaymentProps) => {
  return (
    <Card className="border-0">
      <CardBody className="p-4">
        <OrderImgComponent
          image={taskData.metaData?.payment?.uploadPaymentReceipt}
        />
      </CardBody>
    </Card>
  );
};
