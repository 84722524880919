import React from "react";

import { Card, CardBody } from "reactstrap";
import { OrderImgComponent } from "ReuseableComponents";
import { splitByCapitalLetter } from "utils";

interface VisaComponentProps {
  details: any;
}

export const VisaComponent = ({ details }: VisaComponentProps) => {
  return (
    <Card className="border-0 visaComponent">
      <CardBody>
        <h3 className="titleFour mb-4"> {details?.serviceName} </h3>
        {details &&
          Object.keys(details).map((item) => {
            if (item === "upload") {
              let data = details[item];
              return (
                <div>
                  {data &&
                    Object.keys(data).map((item) => {
                      let uploadData = data[item];
                      if (uploadData) {
                        return (
                          <div className="mb-4">
                            <h6 className="titleFour textCapitalize mb-3">
                              {splitByCapitalLetter(item)}{" "}
                            </h6>
                            <div className="d-flex justify-content-between">
                              {Object.keys(uploadData).map((item) => {
                                return (
                                  <div>
                                    <h6 className="bodyTwo textCapitalize mb-3">
                                      {splitByCapitalLetter(item)}
                                    </h6>
                                    <OrderImgComponent
                                      image={uploadData[item]}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="mb-4">
                            <h6 className="titleFour textCapitalize mb-3">
                              {splitByCapitalLetter(item)}
                            </h6>
                            <OrderImgComponent image={data[item]} />
                          </div>
                        );
                      }
                    })}
                </div>
              );
            } else {
              let data = details[item].upload;

              return (
                <div>
                  {data &&
                    Object.keys(data).map((item) => {
                      let uploadData = data[item];
                      if (typeof uploadData === "object") {
                        return (
                          <div className="mb-4">
                            <h6 className="titleFour textCapitalize mb-3">
                              {splitByCapitalLetter(item)}{" "}
                            </h6>
                            <div className="d-flex justify-content-between">
                              {Object.keys(uploadData).map((item) => {
                                return (
                                  <div>
                                    <h6 className="bodyTwo textCapitalize mb-3">
                                      {splitByCapitalLetter(item)}
                                    </h6>
                                    <OrderImgComponent
                                      image={uploadData[item]}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="mb-4">
                            <h6 className="titleFour textCapitalize mb-3">
                              {splitByCapitalLetter(item)}
                            </h6>
                            <OrderImgComponent image={data[item]} />
                          </div>
                        );
                      }
                    })}
                </div>
              );
            }
          })}
      </CardBody>
    </Card>
  );
};
