import "./styles.scss";

import React, { useEffect } from "react";

import authBannerImg from "assets/images/auth/authBanner.svg";
import { AuthLayout } from "Components";
import { LOGIN, ME } from "queries/authQuery";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import {
  CustomButton,
  InputText,
  Logo,
  PasswordInput,
} from "ReuseableComponents";
import { setLocalStorage } from "utils";

import { useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { loginSchema } from "./validation";

interface LoginInput {
  username: string;
  password: string;
}

export default function Login() {
  //var
  const navigate = useNavigate();

  //query
  const [login, { data: loginData, error: loginError, loading: loginLoading }] =
    useLazyQuery(LOGIN, {
      fetchPolicy: "network-only",
    });

  //query
  const [me, { data: meData, loading: meLoading }] = useLazyQuery(ME, {
    fetchPolicy: "network-only",
  });

  //react-hook-form
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<LoginInput>({
    resolver: yupResolver(loginSchema),
  });

  //context

  const onSubmit = (data: LoginInput) => {
    login({
      variables: {
        username: data.username,
        password: data.password,
        loginProvider: "PHONE",
      },
    });
  };

  useEffect(() => {
    if (loginData) {
      setLocalStorage("token", { ...loginData.login, isAuthenticated: true });
      me();
    }
  }, [loginData, me]);

  useEffect(() => {
    if (meData) {
      const filterUserType = meData.me.userType.filter(
        (item: string) => item !== "CUSTOMER"
      );

      if (filterUserType.length > 0) {
        setLocalStorage("userType", filterUserType);
        navigate("/");
      } else {
        navigate("/unAuthorized");
      }
    }
  }, [meData, navigate]);

  return (
    <AuthLayout>
      <Row>
        <Col md={12} lg={6}>
          <div className="loginDetails">
            <Logo />
            <h1 className="titleTwo title">Welcome back!</h1>
            <div className="formWrapper">
              <Form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column justify-content-center align-items-end"
              >
                <div className="defaultMargin w-100 ">
                  <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        label="Phone Number"
                        type="number"
                        placeholder="Enter phone number"
                        icon
                        field={field}
                        error={errors.username?.message}
                        invalid={!!errors.username}
                      />
                    )}
                  />
                </div>
                <div className="defaultMargin w-100">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <PasswordInput
                        label="Password"
                        placeholder="Password"
                        icon
                        field={field}
                        error={errors.password?.message}
                      />
                    )}
                  />
                </div>
                <div className="mb-4">
                  {" "}
                  <Link to="/reset-password" className="forgotPassword">
                    Forgot Password?
                  </Link>
                </div>

                <CustomButton
                  btnName="Login"
                  classNames="w-100"
                  color="primary"
                  loading={loginLoading}
                />
              </Form>
            </div>
          </div>
        </Col>
        <Col md={0} lg={6} className="no-padding d-none d-sm-none d-lg-block">
          <img
            src={authBannerImg}
            alt="auth banner"
            className="img-fluid w-100"
          />
        </Col>
      </Row>
    </AuthLayout>
  );
}
