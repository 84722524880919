import React, { Dispatch, MouseEvent, SetStateAction, useState } from "react";

import eyeIcon from "assets/icons/eye.svg";
import { Table } from "Components";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import {
  CustomButton,
  IconButton,
  ModalComponent,
  TaskStatusComponent,
  TaskTypeComponent,
} from "ReuseableComponents";
import {
  addEllipsis,
  getTaskStatus,
  getTaskUrl,
  useUserContext,
  useUserRole,
} from "utils";

// import { columns } from "./columns";

interface TableComponentProps {
  data: Array<[]>;
  loading: boolean;
  rowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  metaData?: {
    limit: number;
    page: number;
    total: number;
  };
  currentPage: number;
}

interface viewModalProps {
  completedAt: string;
  id: string;
  status: string;
  task: {
    id: string;
    createdAt: string;
    type: string;
    name: string;
  };
  customer: {
    firstName: string;
    lastName: string;
    middleName: string;
  };
  orderNumer: string;
  taskTypeData: any;
}

export const TableComponent = ({
  data,
  loading,
  metaData,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}: // rowsPerPage,
TableComponentProps) => {
  //state
  const [showViewModal, setShowViewModal] = useState(false);
  const [showViewModalData, setShowViewModalData] = useState({} as any);

  const { userRole } = useUserRole();

  const navigate = useNavigate();

  const { user } = useUserContext();

  const handleView = (data: any) => {
    setShowViewModal(true);

    setShowViewModalData({
      ...data,
    });
  };

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
  };

  const handleViewMore = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let url = getTaskUrl({
      metaData: showViewModalData?.metaData,
      name: showViewModalData.task.name,
      status: getTaskStatus(showViewModalData.status),
      customerId: showViewModalData.customer.id,
      taskId: showViewModalData.task.id,
      id: showViewModalData.id,
      serviceName: showViewModalData.serviceName,
      isAdmin: user?.id === showViewModalData?.admin?.id ? true : false,
    });

    // navigate(url);

    window.open(url, "_blank");
  };

  //columns
  const columns: Array<object> = [
    {
      name: "id",
      selector: (row: { orderNumer: string }) => row.orderNumer,
    },
    {
      name: "category",
      cell: (row: {
        task: {
          type: string;
        };
      }) => {
        return <TaskTypeComponent type={row?.task?.type} />;
      },
    },

    {
      name: "order date",
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => {
        return format(new Date(row.createdAt), "dd MMMM, yyyy");
      },
    },
    {
      name: "status",
      cell: (row: { status: string }) => {
        return <TaskStatusComponent type={row.status} />;
      },
    },
    {
      name: "actions",
      center: true,
      cell: (row: {
        status: string;
        metaData: any;
        task: { name: string; id: string };
        id: string;
        serviceName: string;
        customer: {
          id: string;
        };
      }) => {
        if (userRole === "SUPERADMIN") {
          return (
            <Link
              to={getTaskUrl({
                metaData: row.metaData,
                name: row.task.name,
                status: getTaskStatus(row.status),
                customerId: row.customer.id,
                taskId: row.task.id,
                id: row.id,
              })}
            >
              <IconButton className="icon">
                <img src={eyeIcon} alt="view" />
              </IconButton>
            </Link>
          );
        } else {
          return (
            <IconButton className="icon" handleClick={() => handleView(row)}>
              <img src={eyeIcon} alt="view" />
            </IconButton>
          );
        }
      },
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={metaData?.total || 0}
        pagination={true}
        handlePagination={handlePagination}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        variant="secondary"
        showHeader={true}
        name="Order history"
        showSubHeader={false}
        loading={loading}
        setCurrentPage={setCurrentPage}
      />
      <ModalComponent
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        modalTitle={addEllipsis(showViewModalData?.orderNumer || "", 40)}
      >
        <div>
          <p className="bodyTwo mb-3">
            Order Number: {showViewModalData?.orderNumer}
          </p>
          <div className="d-flex align-items-center mb-3">
            <p className="bodyTwo me-3">Category:</p>
            <TaskTypeComponent type={showViewModalData?.task?.type || ""} />
          </div>
          <div className=" mb-3 d-flex">
            <p className="bodyTwo me-3"> Status: </p>
            <TaskStatusComponent type={showViewModalData?.status || ""} />
          </div>
          <p className="bodyTwo mb-3">
            Client:{" "}
            {`${showViewModalData?.customer?.firstName} ${showViewModalData?.customer?.middleName} ${showViewModalData?.customer?.lastName}`}
          </p>
          {/* <p className="bodyTwo mb-3">
            Handled By: {showViewModalData?.orderNumer}
          </p> */}
          <p className="bodyTwo mb-3">
            Order Date:{" "}
            {showViewModalData?.task?.createdAt &&
              format(
                new Date(showViewModalData?.task?.createdAt || ""),
                "dd MMMM, yyyy"
              )}
          </p>
          <p className="bodyTwo mb-3">
            Completed Date:{" "}
            {showViewModalData?.completedAt &&
              format(
                new Date(showViewModalData?.completedAt || ""),
                "dd MMMM, yyyy"
              )}
          </p>
          <CustomButton
            btnName="View more"
            classNames="secondary px-4"
            handleBtnClick={handleViewMore}
          />
        </div>
      </ModalComponent>
    </>
  );
};
