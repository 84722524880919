import React, { createContext, ReactNode, useContext, useState } from "react";

// Define the type for the user object
type User = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  userType: string[];
  confirmed: string;
  profileImage: string;
  isAuthenticated: boolean;
  createdDTM: string;
  roles: string[];
  userAddress: {
    banchi: string;
    city: string;
    country: string;
    municipality: string;
    roomNumber: string;
    town: string;
    prefecture: string;
    buildingName: string;
    postalCode: string;
  };
};

// Define the type for the context value
type UserContextValue = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

// Create the user context
const UserContext = createContext<UserContextValue | undefined>(undefined);

// Define the props type for UserProvider
type UserProviderProps = {
  children: ReactNode;
};

// Create the UserProvider component
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const contextValue: UserContextValue = {
    user,
    setUser,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

// Create the custom hook for using the user context
export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
