import "./styles.scss";

import React from "react";

import { ChevronLeft, ChevronRight } from "lucide-react";
import DataTable from "react-data-table-component";
import { Card } from "reactstrap";
import { DefaultImg } from "ReuseableComponents";
import { getFullName } from "utils";

interface StaffTableComponentProps {
  data: Array<object>;
  handleChangeNextPage: () => void;
  handleChangePrevPage: () => void;
  loading: boolean;
}

export const StaffTableComponent = ({
  data,
  handleChangeNextPage,
  handleChangePrevPage,
  loading,
}: StaffTableComponentProps) => {
  const columns: Array<object> = [
    {
      name: "",
      cell: (row: {
        firstName: string;
        lastName: string;
        middleName: string;
        profileImage: string;
      }) => {
        return (
          <div className="d-flex align-items-center">
            {row.profileImage ? (
              <img
                src={row.profileImage}
                alt=""
                className="img-fluid"
                style={{
                  borderRadius: "50%",
                  height: 28,
                  width: 28,
                  marginRight: 12,
                }}
              />
            ) : (
              <DefaultImg
                className="p-2 me-2"
                style={{
                  height: 28,
                  width: 28,
                }}
              />
            )}

            <p>{getFullName(row.firstName, row.middleName, row.lastName)}</p>
          </div>
        );
      },
    },
    {
      name: "Task Accepted",
      selector: (row: { taskCount: string }) => row.taskCount,
    },
    {
      name: "Task Completed",
      selector: (row: { taskCompletedCount: string }) => row.taskCompletedCount,
    },
  ];

  return (
    <Card className="border-0 staffTable h-100">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="titleFour">Staffs</h2>
        <div className="d-flex align-items-center">
          <ChevronLeft
            className="cursorPointer"
            onClick={handleChangePrevPage}
          />
          <ChevronRight
            className="cursorPointer"
            onClick={handleChangeNextPage}
          />
        </div>
      </div>
      <div className="react-dataTable">
        <DataTable
          columns={columns}
          data={data}
          //   customStyles={customStyle}
          progressPending={loading}
        />
      </div>
    </Card>
  );
};
