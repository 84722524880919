import { useState } from "react";

import { GET_ADMIN_CLIENTS } from "queries/adminClient";
import { GET_SUPER_ADMIN_CLIENTS } from "queries/superAdminClient";
import { useSearchParams } from "react-router-dom";
import { useUserRole } from "utils";

import { useQuery } from "@apollo/client";

import { FilterComponent } from "./components/FilterComponent/FilterComponent";
import { SidebarComponent } from "./components/SidebarComponent/SidebarComponent";
import { TableComponent } from "./components/TableComponent/TableComponent";

export default function Clients() {
  //var
  const [searchParams] = useSearchParams();
  const { userRole } = useUserRole();

  //state
  const [status, setStatus] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [picker, setPicker] = useState<string[]>([]);
  const [orderRange, setOrderRange] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "") || 1
  );
  const [showSidebar, setShowSidebar] = useState(false);

  //query
  const { data: clientData, loading: clientLoading } = useQuery(
    userRole === "SUPERADMIN" ? GET_SUPER_ADMIN_CLIENTS : GET_ADMIN_CLIENTS,
    {
      variables: {
        limit: rowsPerPage,
        page: currentPage,
        [status && "status"]: status && status,
        [keyword && "keyword"]: keyword && keyword,
        [orderRange && "orderRange"]: {
          start: parseInt(orderRange.split("-")[0]),
          end: parseInt(orderRange.split("-")[1]),
        },
        dateRange: {
          from: picker[0],
          to: picker[1],
        },
      },
    }
  );

  const getKeyDat = () => {
    if (userRole === "SUPERADMIN") {
      return "superadminGetClients";
    } else {
      return "adminGetClients";
    }
  };

  return (
    <div className="clients">
      <FilterComponent
        setStatus={setStatus}
        setKeyword={setKeyword}
        picker={picker}
        setPicker={setPicker}
        setOrderRange={setOrderRange}
        setRowsPerPage={setRowsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setShowSidebarModal={setShowSidebar}
      />
      <div className="mt-4">
        <TableComponent
          data={clientData?.[getKeyDat()]?.data}
          metaData={clientData?.[getKeyDat()]?.meta}
          loading={clientLoading}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
      <SidebarComponent
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
    </div>
  );
}
