import React, { Dispatch, SetStateAction, useState } from "react";

import eyeIcon from "assets/icons/eye.svg";
import { Table } from "Components";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { IconButton } from "ReuseableComponents";
import {
  addEllipsis,
  adminTaskStatus,
  getTaskStatus,
  getTaskUrl,
  taskType,
  useUserContext,
  useUserRole,
} from "utils";

import { TaskDetails } from "../TaskDetails/TaskDetails";

// import { columns } from "./columns";

interface TableComponentProps {
  data: Array<[]>;
  loading: boolean;
  rowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  metaData?: {
    limit: number;
    page: number;
    total: number;
  };
  currentPage: number;
}

interface viewModalProps {
  createdAt: string;
  order: {
    orderNumer: string;
    status: string;
    completedAt: string;

    task: {
      id: string;
      createdAt: string;
      type: string;
      name: string;
    };
    customer: {
      firstName: string;
      lastName: string;
      middleName: string;
    };
  };
  admin: {
    firstName: string;
    lastName: string;
    middleName: string;
  };
}

export const TableComponent = ({
  data,
  loading,
  metaData,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}: // rowsPerPage,
TableComponentProps) => {
  //state
  const [showViewModal, setShowViewModal] = useState(false);
  const [showViewModalData, setShowViewModalData] = useState<viewModalProps>();

  const { userRole } = useUserRole();
  const { user } = useUserContext();

  const handleView = (data: viewModalProps) => {
    setShowViewModal(true);
    setShowViewModalData({
      ...data,
    });
  };

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
  };

  //columns
  const columns: Array<object> = [
    {
      name: "id",
      selector: (row: { order: { orderNumer: string } }) =>
        addEllipsis(row.order.orderNumer, 20),
      // maxWidth: "5px",
    },
    {
      name: "category",
      cell: (row: {
        order: {
          task: {
            name: string;
            image: string;
          };
        };
      }) => {
        //@ts-ignore
        const type = taskType[row.order.task.type];
        return (
          <div
            className="d-flex align-items-center px-2 py-1"
            style={{
              backgroundColor: type.bgColor,
              borderRadius: 4,
              color: type.textColor,
            }}
          >
            {type.icon}
            <p>{row.order.task.name}</p>
          </div>
        );
      },
    },

    {
      name: "order date",
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => {
        return format(new Date(row.createdAt), "dd MMMM, yyyy");
      },
    },

    {
      name: "status",
      maxWidth: "350px",

      cell: (row: {
        order: {
          status: string;
        };
      }) => {
        //@ts-ignore
        const type = adminTaskStatus[row.order.status];
        return (
          <div
            className="d-flex align-items-center px-2 py-1"
            style={{
              backgroundColor: type?.bgColor,
              borderRadius: 4,
              color: type?.textColor,
            }}
          >
            {type?.icon}
            <p>{type?.name}</p>
          </div>
        );
      },
    },
    {
      name: "actions",
      maxWidth: "10px",
      center: true,
      cell: (row: {
        id: string;
        admin: {
          id: string;
        };
        order: {
          metaData: {};
          task: { name: string; id: string };
          id: string;
          status: string;
          serviceName: string;
          customer: {
            id: string;
          };
        };
      }) => {
        return (
          <Link
            to={getTaskUrl({
              metaData: row.order.metaData,
              name: row.order.task.name,
              status: getTaskStatus(row.order.status),
              id: row.id,
              customerId: row.order.customer.id,
              taskId: row.order.task.id,
              serviceName: row.order.serviceName,
              isAdmin: user?.id === row.admin.id ? true : false,
            })}
          >
            <IconButton className="icon">
              <img src={eyeIcon} alt="view" />
            </IconButton>
          </Link>
          // <div></div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={metaData?.total || 0}
        pagination={true}
        handlePagination={handlePagination}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        variant="secondary"
        showHeader={true}
        name="Order history"
        showSubHeader={false}
        loading={loading}
        setCurrentPage={setCurrentPage}
      />
      <TaskDetails
        showViewModal={showViewModal}
        setShowViewModal={setShowViewModal}
        showViewModalData={showViewModalData}
      />
    </>
  );
};
