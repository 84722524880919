import "./styles.scss";

import React, { useCallback, useState } from "react";

import ReactPaginate from "react-paginate";
import { Button, Input, Label } from "reactstrap";

interface PaginationProps {
  totalCount: number;
  currentPage: number;
  rowsPerPage: number;
  handlePagination: (page: { selected: number }) => void;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination = ({
  totalCount,
  currentPage,
  rowsPerPage,
  handlePagination,
  setCurrentPage,
}: PaginationProps) => {
  const count = Number(Math.ceil(totalCount / rowsPerPage));

  const [goInput, setGoInput] = useState<string>("");

  const handleGo = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (setCurrentPage) {
        setCurrentPage(parseInt(goInput));
      }
    },
    [setCurrentPage, goInput]
  );

  return (
    <div className="d-flex align-items-center justify-content-between w-100 pagination">
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        pageCount={count || 1}
        activeClassName="table-active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page: { selected: number }) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={"pagination react-paginate justify-content-end p-1"}
      />
      <div className="d-flex align-items-center rightContent">
        <Label for="page" className="labelThree text-nowrap m-0 me-2">
          Go to page
        </Label>
        <Input
          name="page"
          type="text"
          className="tableGoInput"
          onChange={(e) => setGoInput(e.target.value)}
        />
        <Button className="tableGoBtn labelThree" onClick={handleGo}>
          Go
        </Button>
      </div>
    </div>
  );
};
