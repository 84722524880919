import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { UserCard } from "Components";
import { REJECT_ADMIN_ORDER, UPDATE_ADMIN_ORDER } from "queries/adminOrder";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";
import { CustomButton, InputText, ModalComponent } from "ReuseableComponents";
import { getFullName, useUserRole } from "utils";

import { useMutation } from "@apollo/client";

import { Delivery } from "./Components/Delivery/Delivery";
import { Details } from "./Components/Details/Details";
import { Files } from "./Components/Files/Files";
import { Payment } from "./Components/Payment/Payment";
import { Sample } from "./Components/Sample/Sample";
import { TabBar } from "./Components/TabBar/TabBar";

interface InputProps {
  rejectReason: string;
}

export default function Order() {
  const { orderId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { userRole } = useUserRole();

  const tabNumber = searchParams.get("tab");
  const status = searchParams.get("status");

  const [userCardData, setUserCardData] = useState({} as any);
  const [taskDetails, setTaskDetails] = useState({} as any);

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [filesUrl, setFilesUrl] = useState<File[]>([]);

  const [
    rejectCustomerOrder,
    { loading: orderRejectLoading, data: orderRejectData },
  ] = useMutation(REJECT_ADMIN_ORDER, {
    errorPolicy: "all",
  });

  const [
    updateAdminOrder,
    { loading: orderUpdateLoading, data: orderUpdatetData },
  ] = useMutation(UPDATE_ADMIN_ORDER, {
    errorPolicy: "all",
    context: {
      headers: {
        "Content-Type": "application/json",
        "x-apollo-operation-name": "updateAdminOrder",
      },
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InputProps>({
    defaultValues: {
      rejectReason: "",
    },
  });

  // const getKey = () => {
  //   return status === "new" ? "customer" : "admin";
  // };

  const getKey = useCallback(() => {
    return status === "new" ? "customer" : "admin";
  }, [status]);

  const getUrl = useCallback(() => {
    if (userRole === "SUPERADMIN") {
      return "/superadmin/clients/orders";
    } else if (status === "new") {
      return `/admin/clients/orders`;
    } else {
      return `/admin/orders`;
    }
  }, [status, userRole]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = getUrl();
        const response = await axios.get(`${url}/${orderId}`);
        const taskData = status === "new" ? response.data : response.data.order;
        const userData = {
          firstName: response.data[getKey()]?.firstName,
          middleName: response.data[getKey()]?.middleName,
          lastName: response.data[getKey()]?.lastName,
          email: response.data[getKey()]?.email,
          phone: response.data[getKey()]?.phone,
          profileImage: response.data[getKey()]?.profileImage,
          taskCount: response.data[getKey()]?.taskCount,
          id: response.data[getKey()]?.id,
          createdAt: response.data[getKey()]?.createdAt,
          address: response.data[getKey()]?.address?.banchi,
          taskCompletedCount: response.data[getKey()]?.taskCompletedCount,
          userName: response.data[getKey()]?.userName,
          orderCount: response.data[getKey()]?.orderCount,
        };
        setTaskDetails(taskData);
        setUserCardData(userData);
      } catch (error) {}
    };
    if (orderId && userRole) {
      fetchData();
    }
  }, [orderId, userRole, getKey, getUrl, status]);

  const showCurrentTab = () => {
    switch (tabNumber) {
      case "01":
        return (
          <Col md={12}>
            <Details taskData={taskDetails} />
          </Col>
        );

      case "02":
        return <Sample taskData={taskDetails} />;
      case "03":
        return (
          <Col md={12}>
            <Delivery taskData={taskDetails} />
          </Col>
        );
      case "04":
        return (
          <Col md={12}>
            <Payment taskData={taskDetails} />
          </Col>
        );
      case "05":
        return (
          <Files
            taskData={taskDetails}
            filesUrl={filesUrl}
            setFilesUrl={setFilesUrl}
          />
        );
      default:
        return null;
    }
  };

  const handleTakeOrder = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/admin/orders", {
        orderId: orderId,
      });
      navigate("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRejectTask = () => {
    setShowModal(true);
  };

  const onsubmit = (data: { rejectReason: string }) => {
    rejectCustomerOrder({
      variables: {
        orderId: orderId,
        status: "REJECTED",
        rejectReason: data.rejectReason,
      },
    });
  };

  const handleFinishOrder = () => {
    let filterFile = filesUrl.map((item: any) => item.file);
    // setShowFinishModal(true);
    updateAdminOrder({
      variables: {
        id: orderId,
        status: "DONE",
        files: filterFile,
      },
    });
  };

  useEffect(() => {
    if (orderUpdatetData) {
      toast.success("Task completed");
      navigate("/");
    }
  }, [orderUpdatetData, navigate]);

  useEffect(() => {
    if (orderRejectData) {
      navigate("/");
    }
  }, [orderRejectData]);

  return (
    <>
      <div>
        <Row>
          <Col md={3}>
            <UserCard
              fullName={getFullName(
                userCardData?.firstName,
                userCardData?.middleName,
                userCardData?.lastName
              )}
              email={userCardData?.email}
              userId={userCardData?.id}
              joinedDate={userCardData?.createdAt || new Date()}
              address={`${userCardData?.address}`}
              contact={userCardData?.phone}
              userImage={userCardData?.profileImage}
              joinedCount={userCardData?.taskCompletedCount}
              totalCount={userCardData?.taskCount}
              todayCount={userCardData?.orderCount}
              userName={userCardData?.userName}
              showDelete={false}
              showEdit={false}
              showJoinedCount={true}
              showTodayCount={true}
              showTotalCount={true}
            />
          </Col>
          <Col md={7}>
            <TabBar />
            <Row>{showCurrentTab()}</Row>
          </Col>
          <Col md={2}>
            {status !== "completed" && (
              <div>
                {status === "new" && (
                  <CustomButton
                    btnName="Start Task"
                    classNames="w-100"
                    handleBtnClick={handleTakeOrder}
                    loading={loading}
                  />
                )}
                {status !== "completed"}
                <CustomButton
                  type="button"
                  btnName={`Reject Task `}
                  classNames="w-100 mt-3 tertiary errorColor error-border py-2"
                  handleBtnClick={handleRejectTask}
                />
                {tabNumber === "05" && (
                  <CustomButton
                    type="button"
                    btnName={`Finish Task `}
                    classNames="w-100 mt-3  py-2"
                    loading={orderUpdateLoading}
                    handleBtnClick={handleFinishOrder}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>

      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        btnPrimaryText="Yes, remove"
        // btnPrimaryClick={handleDelete}
      >
        <Form onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-4">
            <Controller
              name="rejectReason"
              control={control}
              render={({ field }) => (
                <InputText
                  type="textarea"
                  label="Reason"
                  placeholder="Type here "
                  field={{ ...field, rows: 10 }}
                  invalid={!!errors.rejectReason}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-end">
            <CustomButton
              btnName="Submit"
              classNames="px-4"
              loading={orderRejectLoading}
            />
          </div>
        </Form>
      </ModalComponent>
    </>
  );
}
