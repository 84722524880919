import "./styles.scss";

import React from "react";

import userIcon from "assets/icons/inputUser.svg";

interface DefaultImgProps {
  className?: string;
  style?: any;
}

export const DefaultImg = ({ className, style }: DefaultImgProps) => {
  return (
    <div className={`defaultImg ${className}`} style={style}>
      <img alt="user-default" src={userIcon} className="img-fluid" />
    </div>
  );
};
