import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { io } from "socket.io-client";
import { getLocalStorage } from "utils/localStorage/localStorage";

import { useUserContext } from "./userContext";

interface ChatProviderProps {
  children: ReactNode;
}

interface ChatSettingsProps {
  chatId: string;
  orderId: string;
  customerId: string;
  isAdmin: boolean;
  chatType: string;
}

interface ConversationsProps {
  count: number;
  conversationsList: any;
}

interface createContextProps {
  isSeen?: boolean;
  conversations?: {
    count: number;
    conversationsList: [];
  };
  handleCreateConversation?: (item: any) => void;
  messagesList: any;
  chatSettings: ChatSettingsProps;
  setChatSettings: Dispatch<SetStateAction<any>>;
  handleSubmit?: any;
  getCustomerData: (item: any) => void;
  customerDetails?: any;
  socket?: any;
  joinConvoLoading?: boolean;
  newIncomingMessage?: any;
  setCustomerDetails: Dispatch<SetStateAction<any>>;
  setIsSeen: Dispatch<SetStateAction<boolean>>;
  setNewIncomingMessage: Dispatch<SetStateAction<any>>;
  setConversations: Dispatch<SetStateAction<any>>;
  filterKye: string;
  setFilterKey: Dispatch<SetStateAction<string>>;
}

export const ChatContext = createContext<createContextProps | undefined>(
  undefined
);

export const ChatProvider = ({ children }: ChatProviderProps) => {
  const tokenValue = getLocalStorage("token");
  const parsedValue = tokenValue ? JSON.parse(tokenValue) : null;

  const { user } = useUserContext();

  const [socket, setSocket] = useState(null as any);

  const [isSeen, setIsSeen] = useState<boolean>(true);
  const [messagesList, setMessagesList] = useState([] as any);

  const [chatSettings, setChatSettings] = useState<ChatSettingsProps>({
    chatType: "clients",
    chatId: "",
    customerId: "",
    isAdmin: false,
    orderId: "",
  });

  const [filterKye, setFilterKey] = useState("ADMIN_CUSTOMER");

  const [joinConvoLoading, setJoinConvoLoading] = useState(false);

  const [customerDetails, setCustomerDetails] = useState({} as any);

  const [conversations, setConversations] = useState<ConversationsProps>({
    count: 0,
    conversationsList: [],
  });

  const [newIncomingMessage, setNewIncomingMessage] = useState({} as any);

  const getCustomerData = useCallback(
    (item: any) => {
      return item?.users.find((item: any) => item.id !== user?.id);
    },
    [user?.id]
  );

  const joinConversation = useCallback(() => {
    let params: { friendId: string | undefined; orderId?: string } = {
      friendId: chatSettings?.customerId,
    };

    if (chatSettings.orderId && chatSettings.chatType === "clients") {
      params.orderId = chatSettings.orderId;
    }

    socket.emit("joinConversation", params, async (val: any) => {});

    socket.on("messages", async (val: any) => {
      await setMessagesList(val.slice().reverse());
      await setJoinConvoLoading(false);
    });
  }, [
    chatSettings?.customerId,
    chatSettings?.orderId,
    chatSettings?.chatType,
    socket,
  ]);

  useEffect(() => {
    let socketInstance = io(`${process.env.REACT_APP_API_URL_REST}`, {
      extraHeaders: {
        Authorization: parsedValue?.access_token,
      },
    });

    setSocket(socketInstance);
    setChatSettings((prevState: any) => ({
      ...prevState,
      isAdmin: false,
      chatType: "clients",
    }));

    return () => {
      socketInstance.disconnect();
    };
  }, [parsedValue?.access_token, window.location]);

  useEffect(() => {
    if (window.location.pathname === "/chat") {
      setIsSeen(true);
    }
  }, []);

  useEffect(() => {
    if (socket && user?.id) {
      socket.on("conversationsOfUser", async (val: any) => {
        setConversations({
          count: val.count,
          conversationsList: val.conversations,
        });
        const seenVal = await val?.conversations.findIndex(
          (item: any) => !item.seen
        );
        if (seenVal !== -1) {
          setIsSeen(false);
          if (window.location.pathname !== "/chat") {
            setChatSettings((prevState) => ({
              ...prevState,
              chatType:
                val?.conversations[seenVal].type === "ADMIN_ADMIN"
                  ? "teams"
                  : "clients",
            }));
            setFilterKey(val.conversations[seenVal]?.type);
          }
        } else {
          setIsSeen(true);
        }
      });
    }
  }, [socket, user?.id]);

  useEffect(() => {
    if (conversations.conversationsList?.length > 0 && isSeen) {
      const seenVal = conversations?.conversationsList?.findIndex(
        (item: any) => !item.seen
      );

      if (seenVal !== -1) {
        setIsSeen(false);
      } else {
        setIsSeen(true);
      }
    }
  }, [conversations?.conversationsList, isSeen]);

  useEffect(() => {
    if (
      socket &&
      chatSettings?.customerId &&
      chatSettings?.chatId &&
      chatSettings?.chatType
    ) {
      setJoinConvoLoading(true);
      joinConversation();
    }
  }, [
    chatSettings?.customerId,
    chatSettings?.chatId,
    chatSettings?.orderId,
    chatSettings?.chatType,
    socket,
    joinConversation,
  ]);

  const handleCreateConversation = (item: any) => {
    socket.emit("createConversation", item, (val: any) => {
      setChatSettings((prevState) => ({
        ...prevState,
        chatId: val.id,
        customerId: item.id,
        isAdmin: true,
        chatType: "teams",
      }));
      setFilterKey("ADMIN_ADMIN");
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on("newMessage", async (val: any) => {
        if (chatSettings.orderId !== val?.order?.id) {
          setNewIncomingMessage(val);
        } else {
          setNewIncomingMessage({});
        }

        if (chatSettings?.chatType === "clients") {
          if (val?.order?.id === chatSettings?.orderId) {
            setMessagesList((prevState: any) => [
              ...prevState,
              {
                createdAt: val.createdAt,
                id: val.id,
                message: val.message,
                user: val.user,
                updatedAt: val.updatedAt,
              },
            ]);
          }
          if (window.location.pathname !== "/chat") {
            setChatSettings((prevState) => ({
              ...prevState,
              chatId: val.id,
              orderId: val?.order?.id,
            }));
          }
        } else if (chatSettings?.chatType === "teams" && !val?.order?.id) {
          setMessagesList((prevState: any) => [
            ...prevState,
            {
              createdAt: val.createdAt,
              id: val.id,
              message: val.message,
              user: val.user,
              updatedAt: val.updatedAt,
            },
          ]);
        }
      });
    }
  }, [socket, chatSettings?.chatType, chatSettings.orderId]);

  const contextValue = {
    isSeen,
    conversations,
    setConversations,
    handleCreateConversation,
    messagesList,
    chatSettings,
    setChatSettings,
    getCustomerData,
    customerDetails,
    socket,
    joinConvoLoading,
    newIncomingMessage,
    setCustomerDetails,
    setIsSeen,
    setNewIncomingMessage,
    setFilterKey,
    filterKye,
  };

  return (
    <ChatContext.Provider value={{ ...contextValue }}>
      {" "}
      {children}{" "}
    </ChatContext.Provider>
  );
};

// Create the custom hook for using the user context
export const useChatContext = (): createContextProps => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
