import { gql } from "@apollo/client";

export const GET_SUPER_ADMIN_ADS_BY_TYPE = gql`
  query ($type: AdvertisementType!) {
    superadminGetAdvertisementByType(type: $type) {
      id
      name
      position
      image
    }
  }
`;
export const GET_SUPER_ADMIN_ADS = gql`
  query ($limit: Int, $page: Int, $type: AdvertisementType) {
    superadminGetAdvertisements(
      paginationInput: { limit: $limit, page: $page }
      filterInput: { type: $type }
    ) {
      data {
        id
        image
        name
        position
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_SUPER_ADMIN_ADS_BY_ID = gql`
  query ($id: String!) {
    superadminGetAdvertisementById(id: $id) {
      id
      name
      position
      image
    }
  }
`;

export const ADD_SUPER_ADMIN_ADS = gql`
  mutation (
    $name: String!
    $position: Int
    $type: AdvertisementType!
    $image: Upload!
  ) {
    superadminCreateAdvertisement(
      image: $image
      adsCreateInput: { name: $name, position: $position, type: $type }
    ) {
      id
    }
  }
`;

export const UPDATE_SUPER_ADMIN_ADS = gql`
  mutation (
    $name: String
    $position: Int
    $type: AdvertisementType
    $image: Upload!
    $id: String!
  ) {
    superadminUpdateAdvertisement(
      image: $image
      id: $id
      adsUpdateInput: { name: $name, position: $position, type: $type }
    ) {
      id
    }
  }
`;

export const DELETE_SUPER_ADMIN_ADS = gql`
  mutation ($id: String!) {
    superadminDeleteAdvertisement(id: $id)
  }
`;
