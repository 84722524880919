import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import axios from "axios";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Label } from "reactstrap";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import { getStatusVal, useFileUpload, useUserRole } from "utils";

interface PaymentComponentProps {
  pageFormData: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
  inProgressOrderId: string;
}

export const PaymentComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
  inProgressOrderId,
}: PaymentComponentProps) => {
  const [filePreview, setFilePreview] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const orderIdParams = searchParams.get("orderId");
  const customerId = searchParams.get("userId");
  const statusParams = searchParams.get("status");
  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const {
    uploadFile,
    uploadData,
    uploadDataLoading,
    deleteFile,
    deleteFileData,
  } = useFileUpload();

  useEffect(() => {
    if (uploadData) {
      setFilePreview(uploadData.uploadFile);
    }
  }, [uploadData]);

  useEffect(() => {
    if (deleteFileData) {
      setFilePreview("");
    }
  }, [deleteFileData]);

  useEffect(() => {
    if (translationPostData?.metadata?.payment?.uploadPaymentReceipt) {
      setFilePreview(
        translationPostData?.metadata?.payment?.uploadPaymentReceipt
      );
    }
  }, [translationPostData?.metadata?.payment?.uploadPaymentReceipt]);

  useEffect(() => {
    if (filePreview) {
      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          payment: {
            uploadPaymentReceipt: filePreview,
          },
        },
      });
    } else {
      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          payment: {
            uploadPaymentReceipt: "",
          },
        },
      });
    }
  }, [filePreview]);

  const removeFile = (url: string) => {
    deleteFile({
      variables: {
        fileUrl: url,
      },
    }).then((res) => {
      setFilePreview("");
    });
  };

  const handleUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    uploadFile({
      variables: {
        image: e.target.files?.[0],
        prefix: "ORDER",
      },
    });
  };

  const handleContinue = async () => {
    try {
      setLoading(true);
      if (orderIdParams && inProgressOrderId) {
        const response = await axios.patch(
          `/admin/orders/${
            statusParams === "in progress" ? inProgressOrderId : orderIdParams
          }/customer/${customerId}`,
          translationPostData
        );
        if (response) {
          toast.success("Order updated successfully");
          navigate("/");
        }
      } else {
        const response = await axios.post(
          "/admin/orders/customer",
          JSON.stringify(translationPostData)
        );
        if (response) {
          toast.success("Order added successfully");
          navigate("/");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-3">
        <Label>Pricing</Label>
        <Card className="border-0 p-4">
          {pageFormData &&
            Object.keys(pageFormData?.pricing).map((item, idx) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={idx}
                >
                  <h6 className="labelOne text-capitalize"> {item} </h6>
                  <p className="labelOne">¥ {translationPostData?.amount} </p>
                </div>
              );
            })}
        </Card>
      </div>
      <div>
        <Label className="titleFour"> {"Upload payment receipt"} </Label>
        <Card className="border-0 p-4">
          <div className="w-50 mb-4">
            <PictureUpload
              name="Upload payment receipt"
              onChange={handleUploadChange}
              filePreview={filePreview}
              handleDeleteClick={() => removeFile(filePreview)}
              disabled={
                statusParams === "completed" ||
                !getStatusVal(
                  userRole,
                  orderIdParams || "",
                  isAdminParams || ""
                )
                  ? true
                  : false
              }
            />
          </div>
        </Card>
      </div>
      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mt-4 d-flex justify-content-end">
            <CustomButton
              btnName={` ${orderIdParams ? "Edit order" : "Place order"} `}
              classNames="px-5"
              handleBtnClick={handleContinue}
              loading={loading}
            />
          </div>
        )}
    </div>
  );
};
