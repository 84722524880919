import React from "react";

import { Form } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { InputText } from "ReuseableComponents";

export const DesignAndTranslationComponent = ({ details }: any) => {
  return (
    <Card className="border-0">
      <CardBody>
        <Form>
          <div className="mb-4">
            <InputText
              label="Type"
              field={{
                value: details?.type.replace(/_/g, " "),
              }}
            />
          </div>
          <div className="mb-4">
            <InputText
              label="Size"
              field={{
                value: details?.size,
              }}
            />
          </div>
          <div className="mb-4">
            <InputText
              label="Side"
              field={{
                value: details?.sides,
              }}
            />
          </div>
          <div className="mb-4">
            <InputText
              label="Pages"
              field={{
                value: details?.pieces,
              }}
            />
          </div>
          <div className="mb-4">
            <InputText
              label="Color"
              field={{
                value: details?.color,
              }}
            />
          </div>
          <div className="mb-4">
            <InputText
              label="lamination"
              field={{
                value: details?.lamination,
              }}
            />
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};
