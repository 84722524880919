import "./styles.scss";

import React from "react";

import { Card } from "reactstrap";

interface CustomRadioBtnProps {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
}

export const CustomRadioBtn = ({
  title,
  subTitle,
  children,
  active,
  disabled,
}: CustomRadioBtnProps) => {
  return (
    <Card
      className={`border-0 customRadio p-4 mb-4 d-flex flex-row ${
        disabled ? "disabled" : ""
      }`}
    >
      <div className={`customRadio-circle me-3 mt-2 ${active && "active"}`}>
        <div className="customRadio-circle-dot" />
      </div>
      <div className="customRadio-content">
        {title && <h4 className="titleFour text-capitalize"> {title} </h4>}
        {subTitle && <p className="labelOne"> {subTitle} </p>}
        <div>{children}</div>
      </div>
    </Card>
  );
};
