import * as Yup from "yup";

export const emailValidation = () => {
  return Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .test("isEmail", "Invalid email address.", (value) =>
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
    );
};

export const passwordValidation = (name: string) => {
  return Yup.string()
    .required(`${name} is required!`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#^&])(?=.{8,})/,
      `${name} must be at least 8 characters, have at least one uppercase letter, one number and one special character.`
    );
};

export const nameValidation = (name: string) => {
  return Yup.string().required(`${name} is required!`);
};

export const phoneValidation = (name: string) => {
  return Yup.string()
    .required(`${name} is required!`)
    .matches(
      /^[0-9]{10}$/,
      `${name} must be exactly 10 digits long and contain only numbers`
    );
};
