import "./styles.scss";

import { Fragment, useRef } from "react";

import { format } from "date-fns";
import { ITEM_TYPE } from "DndComponents/data/types";
import { useDrag, useDrop } from "react-dnd";
import { DefaultImg } from "ReuseableComponents";
import { taskType } from "utils";

interface ItemProps {
  item: any;
  index: any;
  moveItem: any;
  status: any;
}

export const DragItem = ({ item, index, moveItem, status }: ItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    // hover(item: any, monitor: DropTargetMonitor) {
    //   if (!ref.current) {
    //     return;
    //   }
    //   const dragIndex = item.index;
    //   const hoverIndex = index;

    //   if (dragIndex === hoverIndex) {
    //     return;
    //   }

    //   const hoveredRect = ref.current.getBoundingClientRect();
    //   const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
    //   const mousePosition = monitor.getClientOffset();
    //   const hoverClientY = mousePosition && mousePosition.y - hoveredRect.top;

    //   if (
    //     dragIndex < hoverIndex &&
    //     hoverClientY &&
    //     hoverClientY < hoverMiddleY
    //   ) {
    //     return;
    //   }

    //   if (
    //     dragIndex > hoverIndex &&
    //     hoverClientY &&
    //     hoverClientY > hoverMiddleY
    //   ) {
    //     return;
    //   }
    //   moveItem(dragIndex, hoverIndex);
    //   item.index = hoverIndex;
    // },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { type: ITEM_TYPE, ...item, index },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  drag(drop(ref));

  //@ts-ignore
  let taskTypeData = taskType[item.taskName.split(" ").join("_").toUpperCase()];

  return (
    <Fragment>
      <div
        ref={ref}
        style={{
          opacity: isDragging ? 0.2 : 1,
        }}
        className="dragItem"
      >
        <div className={`dragItem-content`}>
          <div
            className="d-flex align-items-center px-2 py-1"
            style={{
              backgroundColor: taskTypeData.bgColor,
              width: "fit-content",
              borderRadius: 4,
            }}
          >
            {taskTypeData.icon}
            <p
              className={`labelThree task-name`}
              style={{
                color: taskTypeData.textColor,
              }}
            >
              {taskTypeData.name.replace(/_/g, " ")}
            </p>
          </div>
          <h3 className="bodyTwo mt-2 item-name"> {item.serviceName} </h3>
          <div className="dragItem-footer d-flex align-items-center justify-content-between mt-2">
            <div className="d-flex">
              {item.customerImg ? (
                <img
                  alt=""
                  src={item.customerImg}
                  className="img-fluid  user-img me-2"
                />
              ) : (
                <DefaultImg className="dragItem-userImg me-2 p-0" />
              )}

              <h5 className="labelThree customer-name">
                {" "}
                {item.customerName}{" "}
              </h5>
            </div>
            <div className="labelThree">
              {" "}
              {item.updatedAt &&
                format(new Date(item?.updatedAt), "yyy/MM/dd - HH:mm ")}{" "}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
