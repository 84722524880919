import designIcon from "assets/icons/taskIcon/design.svg";
import passportIcon from "assets/icons/taskIcon/passport.svg";
import translationIcon from "assets/icons/taskIcon/translation.svg";
import visaIcon from "assets/icons/taskIcon/visa.svg";
import { taskTypeProps } from "utils/types";

export const taskType: taskTypeProps = {
  DESIGN_AND_PRINTING: {
    name: "Design",
    bgColor: "#F8E1FF",
    textColor: "#862DA7",
    icon: <img alt="design" src={designIcon} className="img-fluid me-1 " />,
  },
  DESIGN: {
    name: "Design",
    bgColor: "#F8E1FF",
    textColor: "#862DA7",
    icon: <img alt="design" src={designIcon} className="img-fluid me-1 " />,
  },
  TRANSLATION: {
    name: "Translation",
    bgColor: "#DFFBF7",
    textColor: "#1EA896",
    icon: <img alt="design" src={translationIcon} className="img-fluid me-1" />,
  },
  VISA: {
    name: "Visa",
    bgColor: "#FFE5F3",
    textColor: "#DB398C",
    icon: <img alt="design" src={visaIcon} className="img-fluid me-1" />,
  },
  PASSPORT: {
    name: "Passport",
    bgColor: "#FFF0D3",
    textColor: "#FFA600",
    icon: <img alt="design" src={passportIcon} className="img-fluid me-1" />,
  },
};
