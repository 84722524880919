import "./styles.scss";

import React from "react";

export const PasswordRequirement = () => {
  return (
    <div className="requirementsWrapper">
      <div className="mb-3">
        <p className="titleFive">Requirements</p>
      </div>
      <div className="mb-3 d-flex">
        <li className="bodyTwo">Minimum 8 characters</li>
      </div>
      <div className="mb-3 d-flex">
        <li className="bodyTwo">At least 1 special character</li>
      </div>
      <div className="mb-3 d-flex">
        <li className="bodyTwo">Contains uppercase</li>
      </div>
    </div>
  );
};
