import { toast } from "react-toastify";

/**
 *
 * @param inputString
 * @param value
 * @returns
 */
export const addEllipsis = (inputString: string, value: number) => {
  if (inputString.length <= value) {
    return inputString;
  } else {
    return inputString.substring(0, value) + "...";
  }
};

/**
 *
 * @param inputString
 * @returns
 */

export const splitByCapitalLetter = (inputString: string) => {
  return inputString.split(/(?=[A-Z])/).join(" ");
};

type OptionProps = {
  value: string;
  label: string;
};

/**
 *
 * @param dropDownData
 * @param oldData
 * @returns
 */

export const selectDropDownMenu = (
  dropDownData: [],
  oldData: OptionProps[]
) => {
  let options: OptionProps[] = [];
  const val = dropDownData.map(
    (cd: {
      firstName: string;
      id: string;
      lastName: string;
      middleName: string;
    }) => {
      return {
        value: cd.id,
        // label: `${cd?.firstName} ${cd?.middleName} ${cd?.lastName}`,
        label: getFullName(cd.firstName, cd.middleName, cd.lastName),
      };
    }
  );
  options = [...oldData, ...val];

  //@ts-ignore
  options = [...new Map(options.map((item) => [item.value, item])).values()]; //removing duplicates

  return options;
};

export const removeDuplicateArrays = (data: { id: string }[]) => {
  return Array.from(new Map(data.map((obj) => [obj.id, obj])).values()); //removing duplicates
};

/**
 *
 * @param firstName
 * @param middleName
 * @param lastName
 * @returns
 */

export const getFullName = (
  firstName: string,
  middleName: string,
  lastName: string
) => {
  if (!firstName && !middleName && !lastName) {
    return `User`;
  }
  return `${firstName || ""} ${middleName || ""} ${lastName || ""} `;
};

/**
 *
 * @param status
 * @returns
 */

export const getTaskStatus = (status: string) => {
  return status === "IN_PROGRESS"
    ? "in progress"
    : status === "DONE"
    ? "completed"
    : "new";
};

/**
 *
 * @param status
 * @returns
 */

export const getReverseTaskStatus = (status: string) => {
  return status === "new"
    ? "TODO"
    : status === "in progress"
    ? "IN_PROGRESS"
    : status === "completed"
    ? "DONE"
    : "";
};

/**
 *
 * @param status
 * @returns
 */
export const getStatusClassName = (status: string) => {
  return status === "POSITIVE"
    ? "positive"
    : status === "NEGATIVE"
    ? "negative"
    : status === "NEUTRAL"
    ? "neutral"
    : "";
};

/**
 *
 * @param status
 * @returns
 */

export const getStatusIcon = (status: string) => {
  return status === "POSITIVE"
    ? "+"
    : status === "NEGATIVE"
    ? " "
    : status === "NEUTRAL"
    ? " "
    : "";
};

export const checkFileSize = (size: number) => {
  const maxFileSizeInBytes = 500 * 1024 * 1024; // 500MB in bytes
  if (size > maxFileSizeInBytes) {
    return toast.error("File size should not exceed more than 500mb");
  }
};

/**
 *
 * @param inputString
 * @returns
 */

export const getServiceName = (inputString: string) => {
  if (!inputString) {
    return "";
  }
  const regex = /- (.+)/;
  const match = inputString.match(regex);
  return match ? match[1] : "";
};

/**
 *
 * @param serviceNameParam
 * @param serviceTypeParams
 * @param serviceTypeListParams
 * @returns
 */

export const getServiceTaskName = (
  serviceNameParam?: string,
  serviceTypeParams?: string,
  serviceTypeListParams?: string
) => {
  let name = "";
  if (serviceNameParam) {
    name += `${splitByCapitalLetter(serviceNameParam)}`;
  }

  if (serviceTypeParams) {
    name += ` > ${serviceTypeParams}`;
  }

  if (serviceTypeListParams) {
    name += ` > ${serviceTypeListParams}`;
  }

  return name;
};

/**
 *
 * @param item
 * @returns
 */

export const getParamsServiceName = (item: any) => {
  let value = "";
  if (item.name.toLowerCase() === "visa") {
    Object.keys(item?.metaData).forEach((data, idx) => {
      if (data === "AbroadVisa" || data === "JapanVisa") {
        value = data;
      } else {
        return;
      }
    });
  }
  if (item.name.toLowerCase() === "passport") {
    value = item.serviceName;
  }
  if (item.name.toLowerCase() === "design") {
    value = item.metaData.availableSample.name;
  }
  return value;
};
/**
 *
 * @param item
 * @returns
 */

export const getParamsServiceTypeName = (item: any) => {
  let value = "";
  if (item.name.toLowerCase() === "visa") {
    Object.keys(item.metaData).forEach((data, idx) => {
      if (data === "AbroadVisa") {
        value = item.metaData[data].country.name;
      } else if (data === "JapanVisa") {
        Object.keys(item.metaData[data]).forEach((item) => {
          if (item === "coe" || item === "visaRenewal") {
            value = item;
          }
        });
      } else {
        return;
      }
    });
  }

  return value;
};

/**
 *
 * @param item
 * @returns
 */

export const getParamsServiceTypeListName = (item: any) => {
  let value = "";
  if (item.name.toLowerCase() === "visa") {
    Object.keys(item.metaData).forEach((data, idx) => {
      if (data === "JapanVisa") {
        Object.keys(item.metaData[data]).forEach((list) => {
          value = item.metaData[data][list].type.name;
        });
      } else {
        return;
      }
    });
  }
  return value;
};

export const getTaskUrl = (item: any, tab?: string) => {
  return `/create-order/${
    item.name.toLowerCase() === "design" ||
    item.name.toLowerCase() === "passport" ||
    item.name.toLowerCase() === "visa" ||
    item.name.toLowerCase() === "translation"
      ? `?type=${item?.name.toLowerCase()}&`
      : "?"
  }${
    item.name.toLowerCase() === "visa" ||
    item.name.toLowerCase() === "design" ||
    item.name.toLowerCase() === "passport"
      ? `serviceName=${getParamsServiceName(item)}&`
      : ""
  }${
    item.name.toLowerCase() === "visa"
      ? `serviceType=${getParamsServiceTypeName(item)}&`
      : ""
  }${
    item.name.toLowerCase() === "visa"
      ? `serviceTypeList=${getParamsServiceTypeListName(item)}&`
      : ""
  }tab=${
    item.name.toLowerCase() === "visa" &&
    getParamsServiceTypeName(item) === "coe"
      ? "02"
      : tab
      ? tab
      : "01"
  }&taskId=${item.taskId}&userId=${item.customerId}&status=${
    item.status
  }&orderId=${item.id}&isAdmin=${item?.isAdmin ? "true" : "false"}  `;
};

/**
 *
 * @param userRole
 * @param orderIdParams
 * @param isAdminParams
 * @returns
 */

export const getStatusVal = (
  userRole: string,
  orderIdParams?: string,
  isAdminParams?: string
) => {
  if (userRole === "SUPERADMIN") {
    return false;
  }
  if (!orderIdParams) {
    return true;
  }
  if (orderIdParams && isAdminParams === "true") {
    return true;
  } else {
    return false;
  }
};

export const isUrl = (str: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // Protocol (optional)
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string
      "(\\#[-a-z\\d_]*)?$",
    "i" // Case-insensitive flag
  );

  return urlPattern.test(str);
};

export const moveIndexToTop = async (arr: any, indexToMove: number) => {
  if (indexToMove < 0 || indexToMove >= arr.length) {
    return arr; // Return original array if index is out of range
  }

  const itemToMove = await arr[indexToMove];
  const newArr = await [
    ...arr.slice(0, indexToMove),
    ...arr.slice(indexToMove + 1),
  ];
  newArr.unshift(itemToMove); // Add the selected item to the beginning of the array

  return newArr;
};
