import React, { useEffect, useState } from "react";

import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useUserRole } from "utils";

import { DesignPage } from "./Pages/DesignPage";
import { PassportPage } from "./Pages/PassportPage";
import { TranslationPage } from "./Pages/TranslationPage";
import { VisaPage } from "./Pages/Visa";

export default function OrderCreate() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getTaskType = searchParams.get("type");

  const [taskDetails, setTaskDetails] = useState(Array<[]>);

  const { userRole } = useUserRole();

  useEffect(() => {
    const getTranslationData = async () => {
      let url =
        userRole === "SUPERADMIN" ? "/superadmin/tasks" : "/admin/tasks";
      try {
        const response = await axios.get(url);

        setTaskDetails(response.data.data);
      } catch (error) {
        //error
      }
    };

    if (userRole) {
      getTranslationData();
    }
  }, [userRole]);

  const renderCompTaskType = () => {
    let filterData: any = taskDetails.find(
      (item: any) => item.name.toLowerCase() === getTaskType
    );

    switch (getTaskType) {
      case "translation":
        return (
          <TranslationPage
            pageFormData={filterData?.metaData}
            price={filterData?.price}
          />
        );

      case "design":
        return (
          <DesignPage
            pageFormData={filterData?.metaData}
            price={filterData?.price}
          />
        );
      case "passport":
        return (
          <PassportPage
            pageFormData={filterData?.metaData}
            price={filterData?.price}
          />
        );

      case "visa":
        return (
          <VisaPage
            pageFormData={filterData?.metaData}
            price={filterData?.price}
          />
        );

      default:
        return null;
    }
  };
  return <div>{renderCompTaskType()} </div>;
}
