import "./styles.scss";

import { useEffect } from "react";

import { Header } from "Components/Header/Header";
import { Sidebar } from "Components/Sidebar/Sidebar";
import { ME } from "queries/authQuery";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { getLocalStorage, setLocalStorage, useUserContext } from "utils";

import { useQuery } from "@apollo/client";

// import useWindowSize from "utils/customHooks/useWindowSize"

interface LayoutProps {
  children?: React.ReactNode;
}

export const AppLayout = ({ children }: LayoutProps) => {
  //var
  let token = getLocalStorage("token");
  const tokenData = token ? JSON.parse(token) : null;
  //query
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useQuery(ME, {
    fetchPolicy: "network-only",
  });

  //context
  const { setUser } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (meData && tokenData) {
      setUser(meData.me);
      const filterUserType = meData.me.userType.filter(
        (item: string) => item !== "CUSTOMER"
      );
      setLocalStorage("userType", filterUserType);
    }
  }, [meData, setUser, tokenData]);

  useEffect(() => {
    if (meError) {
      // navigate("/login");
      // removeLocalStorage("userType");
      // removeLocalStorage("token");
    }
  }, [meError, navigate]);

  return (
    <div className={`layout  `}>
      <div className="row flex-nowrap mx-0">
        <div className={`col-xl-2 col-1 p-0 sidebar-wrapper  `}>
          <Sidebar />
        </div>
        <div className="col-xl-10 col-11 content-wrapper no-padding d-flex flex-column ">
          <Header />
          {meLoading ? (
            <div className="content d-flex justify-content-center align-items-center h-100">
              <Spinner />
            </div>
          ) : (
            <div className="content py-3 px-4 h-100">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};
