import { useEffect } from "react";

import { REMOVE_FILES, UPLOAD_FILES } from "queries/files";
import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";

export const useFileUpload = () => {
  const [
    uploadFile,
    { data: uploadData, loading: uploadDataLoading, error: uploadDataError },
  ] = useMutation(UPLOAD_FILES, {
    errorPolicy: "all",
    context: {
      headers: {
        "Content-Type": "application/json",
        "x-apollo-operation-name": "uploadFiles",
      },
    },
  });

  const [
    deleteFile,
    {
      data: deleteFileData,
      loading: deleteFileLoading,
      error: deleteFileError,
    },
  ] = useMutation(REMOVE_FILES, {
    errorPolicy: "all",
  });

  useEffect(() => {
    if (uploadDataError) {
      toast.error(uploadDataError.message);
    }
  }, [uploadDataError]);

  useEffect(() => {
    if (deleteFileError) {
      toast.error(deleteFileError.message);
    }
  }, [deleteFileError]);

  return {
    uploadData,
    uploadDataLoading,
    uploadDataError,
    uploadFile,
    deleteFileData,
    deleteFileLoading,
    deleteFileError,
    deleteFile,
  };
};
