import "./styles.scss";

import React, { ChangeEvent, ComponentProps, InputHTMLAttributes } from "react";

import userInputIcon from "assets/icons/inputUser.svg";
import { FormFeedback, Input } from "reactstrap";

interface IInputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  label?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  field?: ComponentProps<typeof Input>["field"];
  icon?: ComponentProps<typeof Input>["icon"];
  showInfo?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
}

export const InputText = ({
  type,
  invalid,
  placeholder,
  disabled,
  field,
  label,
  error,
  icon,
  showInfo,
  onChange,
  name,
  value,
}: // showInfo,
IInputTextProps) => {
  return (
    <div className="inputWrapper">
      <label className="label bodyTwo">{label}</label>
      <div className="inputField">
        {icon && (
          <img src={userInputIcon} className="img-fluid inputIcon" alt="" />
        )}
        <Input
          name={name}
          className={`input ${icon && "iconWithInput"} ${
            error && "errorInput"
          }`}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          value={value}
          {...field}
        />
      </div>
      {showInfo && (
        <p
          className={`d-block labelThree mt-1 mx-2 showInfo ${
            error && "error"
          } `}
        >
          {showInfo}
        </p>
      )}
      {error && <FormFeedback className="d-block">{error}</FormFeedback>}
    </div>
  );
};
