import gql from "graphql-tag";

export const UPLOAD_FILES = gql`
  mutation ($image: Upload!, $prefix: UploadPrefix!) {
    uploadFile(image: $image, prefix: $prefix)
  }
`;

export const REMOVE_FILES = gql`
  mutation ($fileUrl: String!) {
    deleteFile(fileUrl: $fileUrl)
  }
`;
