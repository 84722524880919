import "normalize.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/styles/index.scss";
import "react-toastify/dist/ReactToastify.css";

import React from "react";

import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { ChatProvider, UserProvider } from "utils";
import client from "utils/graphql";

import { ApolloProvider } from "@apollo/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <ChatProvider>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ChatProvider>
    </UserProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
