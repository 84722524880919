import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import eyeIcon from "assets/icons/eye.svg";
import notesIcon from "assets/icons/notesGray.svg";
import trashIcon from "assets/icons/trash.svg";
import { Table } from "Components";
import { format } from "date-fns";
import { DELETE_ADMIN_CLIENT, GET_ADMIN_CLIENTS } from "queries/adminClient";
import {
  DELETE_SUPER_ADMIN_CLIENT,
  GET_SUPER_ADMIN_CLIENTS,
} from "queries/superAdminClient";
import { Link, useNavigate } from "react-router-dom";
import { DefaultImg, IconButton, ModalComponent } from "ReuseableComponents";
import { getFullName, useUserRole } from "utils";

import { useMutation } from "@apollo/client";

// import { columns } from "./columns";

interface TableComponentProps {
  data: Array<[]>;
  loading: boolean;
  rowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  metaData?: {
    limit: number;
    page: number;
    total: number;
  };
  currentPage: number;
}

export const TableComponent = ({
  data,
  loading,
  metaData,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}: // rowsPerPage,
TableComponentProps) => {
  //var
  const { userRole } = useUserRole();
  const navigate = useNavigate();

  //state
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  //query
  const [superadminDeleteClient, { error: deleteError, data: deleteData }] =
    useMutation(
      userRole === "SUPERADMIN"
        ? DELETE_SUPER_ADMIN_CLIENT
        : DELETE_ADMIN_CLIENT,
      {
        errorPolicy: "all",
      }
    );

  const handleDelete = () => {
    superadminDeleteClient({
      variables: {
        id: deleteId,
      },
      refetchQueries: [
        userRole === "SUPERADMIN" ? GET_SUPER_ADMIN_CLIENTS : GET_ADMIN_CLIENTS,
      ],
    });
  };

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    if (deleteData) {
      setShowModal(false);
    }
  }, [deleteData]);

  //columns
  const columns: Array<object> = [
    {
      name: "id",
      selector: (row: { id: string }) => row.id,
    },
    {
      name: "Name",
      cell: (row: {
        id: string;
        firstName: string;
        lastName: string;
        middleName: string;
        profileImage: string;
      }) => {
        return (
          <div
            className="d-flex align-items-center cursorPointer"
            onClick={() => navigate(`/clients/${row.id}`)}
          >
            {row.profileImage ? (
              <img
                src={row.profileImage}
                alt=""
                className="img-fluid me-2"
                style={{
                  borderRadius: "50%",
                  height: 28,
                  width: 28,
                }}
              />
            ) : (
              <DefaultImg
                className="p-2 me-2"
                style={{
                  height: 28,
                  width: 28,
                }}
              />
            )}

            <p>{getFullName(row.firstName, row.middleName, row.lastName)}</p>
          </div>
        );
      },
    },
    {
      name: "phone",
      selector: (row: { phone: string }) => row.phone,
    },
    {
      name: "joining date",
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => {
        return format(new Date(row.createdAt), "dd MMMM, yyyy");
      },
    },
    {
      name: "orders",
      selector: (row: { orderCount: string }) => row.orderCount,
      center: "true",
    },
    {
      name: "actions",
      cell: (row: { id: string }) => {
        return (
          <div>
            {userRole !== "ADMIN" ? (
              <IconButton
                className="icon"
                handleClick={() => {
                  setShowModal(true);
                  setDeleteId(row.id);
                }}
                disabled={userRole === "ADMIN"}
              >
                <img src={trashIcon} alt="delete" />
              </IconButton>
            ) : (
              ""
            )}

            <Link to={`/clients/${row.id}/edit`}>
              <IconButton className="icon">
                <img src={notesIcon} alt="edit" />
              </IconButton>
            </Link>
            <Link to={`/clients/${row.id}`}>
              <IconButton className="icon">
                <img src={eyeIcon} alt="view" />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={metaData?.total || 0}
        pagination={true}
        handlePagination={handlePagination}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        variant="secondary"
        showHeader={false}
        showSubHeader={false}
        loading={loading}
        setCurrentPage={setCurrentPage}
      />
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        showFooter
        modalTitle="Delete User?"
        btnPrimaryText="Yes, remove"
        btnSecondaryText="Go back"
        btnPrimaryClick={handleDelete}
      >
        <span className="bodyTwo">
          Are you sure you want to remove this user?
        </span>
      </ModalComponent>
    </>
  );
};
