import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { REMOVE_FILES, UPLOAD_FILES } from "queries/files";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import { getStatusVal, useUserRole } from "utils";

import { useMutation } from "@apollo/client";

interface UploadComponentProps {
  pageFormData: object;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

interface FilesProps {
  name: string;
  url: string;
  loading?: boolean;
}

export const UploadComponent = ({
  translationPostData,
  setTranslationPostData,
}: UploadComponentProps) => {
  const [filePreview, setFilePreview] = useState("");
  const [file, setFile] = useState<File>(new File([], ""));

  const [filesUrl, setFilesUrl] = useState<FilesProps[]>([]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const taskTypeParams = searchParams.get("type");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const [
    uploadFile,
    { data: uploadData, loading: uploadDataLoading, error: uploadDataError },
  ] = useMutation(UPLOAD_FILES, {
    errorPolicy: "all",
    context: {
      headers: {
        "Content-Type": "application/json",
        "x-apollo-operation-name": "uploadFiles",
      },
    },
  });

  const [deleteFile] = useMutation(REMOVE_FILES, {
    errorPolicy: "all",
  });

  useEffect(() => {
    if (uploadDataError) {
      toast.error(uploadDataError.message || "Something went wrong");
    }
  }, [uploadDataError]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let data = {
      name: e.target.name,
      loading: true,
      url: "",
    };
    uploadFile({
      variables: {
        image: e.target.files?.[0],
        prefix: "ORDER",
      },
    }).then((res) => {
      data = {
        name: e.target.name,
        url: res.data.uploadFile,
        loading: false,
      };

      setFilesUrl([...filesUrl, data]);
    });
  };
  useEffect(() => {
    if (translationPostData.metadata?.upload?.uploadSample) {
      let data = translationPostData.metadata.upload.uploadSample.map(
        (item: string, index: number) => {
          return {
            name: `component-${index}`,
            url: item,
          };
        }
      );
      setFilesUrl(data);
    }
  }, []);

  useEffect(() => {
    if (filesUrl.length > 0) {
      let setData = translationPostData;
      // setData.metadata.upload?.uploadSample = filesUrl.map((item) => item.url);
      setData = {
        ...setData,
        metadata: {
          ...setData.metadata,
          upload: {
            uploadSample: filesUrl.map((item) => item.url),
          },
        },
      };

      setTranslationPostData(setData);
    }
  }, [filesUrl]);

  const removeFile = (fileUrl?: string, name?: string) => {
    let filterData = filesUrl.filter((item) => item.name !== name);
    setFilesUrl(filterData);
    deleteFile({
      variables: {
        fileUrl,
      },
    });
  };

  const components = [];
  for (let j = 0; j < translationPostData.metadata?.details?.pages; j++) {
    let filterData = filesUrl.find((item) => item.name === `component-${j}`);

    components.push(
      <div className="w-50 mb-4 p-1 ">
        <PictureUpload
          name={`component-${j}`}
          onChange={handleChange}
          filePreview={filterData?.url}
          loading={filterData?.loading}
          handleDeleteClick={() =>
            removeFile(filterData?.url, `component-${j}`)
          }
          disabled={
            statusParams === "completed" ||
            !getStatusVal(userRole, orderIdParams || "", isAdminParams || "")
              ? true
              : false
          }
        />
      </div>
    );
  }

  const handleContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(
      `${pathname}?type=${taskTypeParams}&tab=03&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ""
      }&isAdmin=${isAdminParams}`
    );
  };

  return (
    <div>
      <Card className="border-0">
        <CardBody className="p-4">
          <div className="d-flex align-items-center flex-wrap">
            {components}
          </div>
        </CardBody>
      </Card>

      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mt-4 d-flex justify-content-end">
            <CustomButton
              btnName="Continue"
              classNames="px-5"
              handleBtnClick={handleContinue}
            />
          </div>
        )}
    </div>
  );
};
