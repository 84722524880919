import React, { Dispatch, SetStateAction } from "react";

import { FormComponent } from "./FormComponent";

interface DetailsComponentProps {
  pageFormData: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const DetailsComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: DetailsComponentProps) => {
  return (
    <FormComponent
      pageFormData={pageFormData?.details}
      translationPostData={translationPostData}
      setTranslationPostData={setTranslationPostData}
    />
  );
};
