import notificationIcon from "assets/icons/bell.svg";
import notificationIconActive from "assets/icons/bellActive.svg";
import chatIcon from "assets/icons/chatIcon.svg";
import chatIconActive from "assets/icons/chatIconActive.svg";
import homeIcon from "assets/icons/home.svg";
import homeIconActive from "assets/icons/homeActive.svg";
import IDCardIcon from "assets/icons/IDcard.svg";
import IDCardIconActive from "assets/icons/IDcardActive.svg";
import microphoneIcon from "assets/icons/microphone.svg";
import microphoneActiveIcon from "assets/icons/microphoneActive.svg";
import notesActiveIcon from "assets/icons/noteActive.svg";
import notesIcon from "assets/icons/notes.svg";
import userIcon from "assets/icons/users.svg";
import userIconActive from "assets/icons/usersActive.svg";

export const navItems = [
  {
    id: "01",
    title: "dashboard",
    link: "/",
    icon: (
      <img src={homeIcon} alt="home" className="img-fluid me-2 navItemImage" />
    ),
    activeIcon: (
      <img
        src={homeIconActive}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    id: "02",
    title: "clients",
    link: "/clients",
    icon: (
      <img src={userIcon} alt="home" className="img-fluid me-2 navItemImage" />
    ),
    activeIcon: (
      <img
        src={userIconActive}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    id: "07",
    title: "chat",
    link: "/chat",
    icon: (
      <img src={chatIcon} alt="home" className="img-fluid me-2 navItemImage" />
    ),
    activeIcon: (
      <img
        src={chatIconActive}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["ADMIN"],
  },
  {
    id: "03",
    title: "teams",
    link: "/teams",
    icon: (
      <img
        src={IDCardIcon}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    activeIcon: (
      <img
        src={IDCardIconActive}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["SUPERADMIN", "ADMIN"],
  },

  {
    id: "04",
    title: "Feedbacks",
    link: "/feedbacks",
    icon: (
      <img src={notesIcon} alt="home" className="img-fluid me-2 navItemImage" />
    ),
    activeIcon: (
      <img
        src={notesActiveIcon}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    id: "05",
    title: "Ads",
    link: "/advertisement",
    icon: (
      <img
        src={microphoneIcon}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    activeIcon: (
      <img
        src={microphoneActiveIcon}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["SUPERADMIN"],
  },
  {
    id: "06",
    title: "Notification",
    link: "/notification",
    icon: (
      <img
        src={notificationIcon}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    activeIcon: (
      <img
        src={notificationIconActive}
        alt="home"
        className="img-fluid me-2 navItemImage"
      />
    ),
    role: ["SUPERADMIN"],
  },
];
