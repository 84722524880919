import React, { useEffect, useState } from "react";

import { ImageUpload } from "Pages/Clients/Edit/Components/ImageUpload/ImageUpload";
import { ME, SET_PROFILE } from "queries/authQuery";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";
import { CustomButton, InputText } from "ReuseableComponents";
import { useUserContext } from "utils";

import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { myAccountSchema } from "../../validation";

interface inputProps {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  newPassword?: string;
}

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export const Profile = () => {
  // state
  const [filePreview, setFilePreview] = useState("");
  const [profileImage, setProfileImage] = useState<File>(new File([], ""));

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
    resolver: yupResolver(myAccountSchema),
  });

  //context
  const { user, setUser } = useUserContext();

  //query

  const [
    setProfile,
    { data: profileData, loading: profileLoading, error: profileError },
  ] = useMutation(SET_PROFILE, {
    refetchQueries: [ME],
    context: {
      headers: {
        "Content-Type": "application/json",
        "x-apollo-operation-name": "superadminEditClient",
      },
    },
  });

  useEffect(() => {
    if (user) {
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setFilePreview(user.profileImage);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (profileData) {
      toast.success("Profile Updated!");
      setUser({
        ...user,
        ...profileData.setProfile,
      });
      setProfileImage(new File([], ""));
    }
  }, [profileData, setUser]);

  const onSubmit = (data: inputProps) => {
    let variables = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };

    if (profileImage.name) {
      variables = {
        ...variables,
        //@ts-ignore
        image: profileImage,
      };
    }

    setProfile({
      variables,
    });
  };

  const getStatsuChecked = () => {
    let firstName = watch("firstName");
    let lastName = watch("lastName");
    let email = watch("email");
    if (
      firstName !== user?.firstName ||
      lastName !== user?.lastName ||
      email !== user?.email ||
      filePreview !== user?.profileImage
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="profile-details container">
      <div className="d-flex align-items-center details ">
        <ImageUpload
          filePreview={filePreview}
          setFilePreview={setFilePreview}
          setProfileImage={setProfileImage}
        />
        <div className="mx-4">
          <h2 className="titleFive"> {user?.firstName} </h2>
          <p className="labelTwo"> {user?.userType.join(", ")} </p>
        </div>
      </div>
      <Form
        className="my-account-form d-flex flex-column "
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row className="mb-4">
          <Col md={5}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <InputText
                  label="First Name"
                  placeholder="First Name"
                  field={field}
                  error={errors.firstName?.message}
                  invalid={!!errors.firstName}
                />
              )}
            />
          </Col>
          <Col md={2} />
          <Col md={5}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <InputText
                  label="Last Name"
                  placeholder="Last Name"
                  field={field}
                  error={errors.lastName?.message}
                  invalid={!!errors.lastName}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  label="Email"
                  placeholder="Email"
                  field={field}
                  error={errors.email?.message}
                  invalid={!!errors.email}
                />
              )}
            />
          </Col>
          <Col md={2} />
          <Col md={5}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputText
                  inputMode="numeric"
                  label="Phone"
                  placeholder="Phone"
                  field={field}
                  error={errors.phone?.message}
                  invalid={!!errors.phone}
                  disabled
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col md={9} />
          <Col md={3}>
            <CustomButton
              btnName="Save Change"
              classNames={`px-5 mt-5 w-100 ${getStatsuChecked() && "disabled"}`}
              loading={profileLoading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
