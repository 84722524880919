import "./styles.scss";

import React from "react";

import { Button, Spinner } from "reactstrap";

interface CustomButtonProps {
  btnName: string;
  handleBtnClick?: any;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  color?: string;
  classNames?: string;
  loading?: boolean;
}

export const CustomButton = ({
  btnName,
  handleBtnClick,
  type,
  disabled,
  color,
  classNames,
  loading,
}: CustomButtonProps) => {
  return (
    <Button
      className={`customButton labelOne ${color} ${classNames} `}
      onClick={handleBtnClick}
      type={type ? type : "submit"}
      disabled={disabled}
    >
      {loading ? <Spinner size={"sm"} /> : btnName}
    </Button>
  );
};
