import {
  passwordValidation,
  phoneValidation,
} from 'utils';
import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  username: phoneValidation("Phone Number"),
  password: passwordValidation("Password"),
});
