import "./styles.scss";

import React from "react";

import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

interface BreadCrumbsProps {
  routes: {
    name: string;
    active: boolean;
    link: string;
  }[];
}

export const BreadCrumbs = ({ routes }: BreadCrumbsProps) => {
  return (
    <div className="breadCrumbs">
      <div className="items d-flex">
        {routes.map((route, index) => {
          return (
            <div key={index}>
              <Link
                to={route.link}
                className={`labelThree route ${route.active && " active"}`}
              >
                {" "}
                {route?.name}{" "}
              </Link>
              {index !== routes.length - 1 && (
                <ChevronRight className="labelThree" size={12} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
