import React, { Dispatch, SetStateAction } from "react";

import { Card, CardBody } from "reactstrap";

import { FormComponent } from "./FormComponent";

interface DetailsComponentProps {
  //   pageFormData: {
  //     page: string;
  //     translateFrom: Array<[]>;
  //     translateTo: Array<[]>;
  //   };
  pageFormData: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const DetailsComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: DetailsComponentProps) => {
  return (
    <Card className="border-0">
      <CardBody>
        <FormComponent
          pageFormData={pageFormData?.details}
          translationPostData={translationPostData}
          setTranslationPostData={setTranslationPostData}
        />
      </CardBody>
    </Card>
  );
};
