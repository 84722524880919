import React from "react";

import { Navigate } from "react-router-dom";
import { getLocalStorage } from "utils";

interface IUnProtectedRoute {
  children: React.ReactNode;
  user?: {
    username: string;
    email: string;
    isAuthenticated: boolean;
  };
}

export const UnProtectedRoute = ({ children }: IUnProtectedRoute) => {
  //var
  let token = getLocalStorage("token");
  const tokenData = token ? JSON.parse(token) : null;

  const userRole = getLocalStorage("userType");

  const userType = userRole ? JSON.parse(userRole) : [];

  if (tokenData?.isAuthenticated && userType.length > 0) {
    return <Navigate to={"/"} />;
  } else return <div> {children} </div>;
};
