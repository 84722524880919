import "./styles.scss";

import React from "react";

import uploadIconWhite from "assets/icons/arrowsUpWhite.svg";
import filesIcon from "assets/icons/files.svg";
import fileIconWhite from "assets/icons/fileWhite.svg";
import notes from "assets/icons/notes.svg";
import notesWhite from "assets/icons/notesWhite.svg";
import packageIcon from "assets/icons/package.svg";
import packageIconWhite from "assets/icons/packageWhite.svg";
import uploadIcon from "assets/icons/uploadArrow.svg";
import yenIcon from "assets/icons/yen.svg";
import yenIconWhite from "assets/icons/yenWhite.svg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

export const TabBar = () => {
  //var
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const tabNumber = searchParams.get("tab");
  const statusParams = searchParams.get("status");

  return (
    <Nav className="tabBar d-flex justify-content-between mb-3">
      <NavItem>
        <Link
          to={`${pathname}?status=${statusParams}&tab=01`}
          className={`navLink ${tabNumber === "01" && "active"}`}
        >
          <img
            alt=""
            src={tabNumber === "01" ? notesWhite : notes}
            className="img-fluid me-1"
            height={20}
            width={20}
          />
          Details
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to={`${pathname}?status=${statusParams}&tab=02`}
          className={`navLink ${tabNumber === "02" && "active"}`}
        >
          <img
            alt=""
            src={tabNumber === "02" ? uploadIconWhite : uploadIcon}
            className="img-fluid me-1"
            height={20}
            width={20}
          />
          Sample
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to={`${pathname}?status=${statusParams}&tab=03`}
          className={`navLink ${tabNumber === "03" && "active"}`}
        >
          <img
            alt=""
            src={tabNumber === "03" ? packageIconWhite : packageIcon}
            className="img-fluid me-1"
            height={20}
            width={20}
          />
          Delivery
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to={`${pathname}?status=${statusParams}&tab=04`}
          className={`navLink ${tabNumber === "04" && "active"}`}
        >
          <img
            alt=""
            src={tabNumber === "04" ? yenIconWhite : yenIcon}
            className="img-fluid me-1"
            height={20}
            width={20}
          />
          Payment
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to={`${pathname}?status=${statusParams}&tab=05`}
          className={`navLink ${tabNumber === "05" && "active"}`}
        >
          <img
            alt=""
            src={tabNumber === "05" ? fileIconWhite : filesIcon}
            className="img-fluid me-1"
            height={20}
            width={20}
          />
          Files
        </Link>
      </NavItem>
    </Nav>
  );
};
