import "./styles.scss";

import React, { useEffect, useState } from "react";

import adHome from "assets/images/ads/adHome.png";
import { Card, CardBody, Col, Row } from "reactstrap";

import { useAds } from "../HomeScreenBottom/useAds";

export const HomeScreenSlider = () => {
  //var

  //state
  const [uploadList, setUploadList] = useState<any[]>([]);

  const { filePreview, renderImageComponent } = useAds({
    adsType: "HOME_SCREEN_SLIDER",
  });

  //query

  useEffect(() => {
    let data: {
      position: number;
    }[] = [];
    for (let i = 0; i <= 3; i++) {
      data = [
        ...data,
        {
          position: i,
        },
      ];
    }
    setUploadList(data);
  }, []);

  return (
    <Card className="border-0 mb-4 home-screen-slider">
      <CardBody>
        <Row>
          <Col md={7}>
            <div className="title d-flex align-items-center mb-4">
              <h1 className="titleThree me-3"> Home screen - Slider</h1>
              <p className="labelTwo">Recommended size: 361 ✕ 136</p>
            </div>
            <div className="mt-3">
              {uploadList.length > 0 &&
                uploadList?.map((item: any, index) => {
                  let image = filePreview?.find(
                    (data: any) => Number(data.position) === Number(index + 1)
                  );

                  return (
                    <div className="mb-4" key={item.id}>
                      {renderImageComponent({
                        image: image?.image,
                        position: index + 1,
                        imageId: image?.id ?? "",
                      })}
                    </div>
                  );
                })}
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={4}>
            <h3 className="titleThree">Preview</h3>
            <div className="preview mt-3">
              <img
                alt="ad-bottom-screen"
                src={adHome}
                className="mobile-skeleton img-fluid"
              />
              {filePreview?.[0]?.image && (
                <img
                  alt="ad-bottom-screen"
                  src={filePreview?.[0]?.image}
                  className="mobile-preview img-fluid"
                />
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
