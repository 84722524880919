import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import axios from "axios";
import { CustomRadioBtn } from "Components";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import { CustomButton, InputText } from "ReuseableComponents";
import { getStatusVal, splitByCapitalLetter, useUserRole } from "utils";

interface DeliveryComponentProps {
  pageFormData: object;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
  inProgressOrderId?: string;
}

export const DeliveryComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
  inProgressOrderId,
}: DeliveryComponentProps) => {
  const [selectItem, setSelectItem] = useState({} as any);

  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const getTypeParams = searchParams.get("type");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParam = searchParams.get("serviceName");
  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (translationPostData?.metadata?.delivery) {
      let deliveryData = translationPostData.metadata.delivery;

      for (const key in deliveryData) {
        if (
          deliveryData[key] !== null &&
          typeof deliveryData[key] === "string"
        ) {
          setSelectItem({
            key: key,
            value: deliveryData[key],
          });
        } else if (
          typeof deliveryData[key] === "object" &&
          deliveryData[key] !== null
        ) {
          if (deliveryData[key].banchi && deliveryData[key].postalCode) {
            setSelectItem({
              key: "deliverToDifferentAddress",
              value: {
                banchi: "",
                postalcode: "",
              },
            });
            setFormValue({
              Banchi: deliveryData[key].Banchi,
              postalCode: deliveryData[key].postalCode,
            });
          }
        }
      }
    }
  }, [translationPostData.metadata?.delivery]);

  useEffect(() => {
    if (pageFormData && !translationPostData?.metadata?.delivery) {
      let data = Object.keys(pageFormData);
      setSelectItem({
        key: data[0],
        //@ts-ignore
        value: pageFormData[data[0]],
      });
    }
  }, [pageFormData, translationPostData]);

  const handleContinue = () => {
    if (typeof selectItem.value === "object") {
      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          delivery: {
            deliverToDifferentAddress: {
              ...formValue,
            },
          },
        },
      });
    } else {
      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          delivery: {
            [selectItem.key]: selectItem.value,
          },
        },
      });
    }
    navigate(
      `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParam}&tab=04&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ""
      }&isAdmin=${isAdminParams}`
    );
  };

  const handleContinueEdit = async () => {
    // if (typeof selectItem.value === "object") {
    //   await setTranslationPostData({
    //     ...translationPostData,
    //     metadata: {
    //       ...translationPostData.metadata,
    //       delivery: {
    //         deliverToDifferentAddress: {
    //           ...formValue,
    //         },
    //       },
    //     },
    //   });
    // } else {
    //   await setTranslationPostData({
    //     ...translationPostData,
    //     metadata: {
    //       ...translationPostData.metadata,
    //       delivery: {
    //         [selectItem.key]: selectItem.value,
    //       },
    //     },
    //   });
    // }

    let postData = translationPostData;
    if (typeof selectItem.value === "object") {
      postData = {
        ...postData,
        metadata: {
          ...postData.metadata,
          delivery: {
            deliverToDifferentAddress: {
              ...formValue,
            },
          },
        },
      };
    } else {
      postData = {
        ...postData,
        metadata: {
          ...postData.metadata,
          delivery: {
            [selectItem.key]: selectItem.value,
          },
        },
      };
    }

    try {
      setLoading(true);
      if (orderIdParams && inProgressOrderId) {
        const response = await axios.patch(
          `/admin/orders/${
            statusParams === "in progress" ? inProgressOrderId : orderIdParams
          }/customer/${userIdParams}`,
          postData
        );
        if (response) {
          toast.success("Order updated successfully");
          navigate("/");
        }
      } else {
        const response = await axios.post(
          "/admin/orders/customer",
          JSON.stringify(postData)
        );
        if (response) {
          toast.success("Order added successfully");
          navigate("/");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelect = (item: string, subTitle: string) => {
    if (
      statusParams === "completed" ||
      !getStatusVal(userRole, orderIdParams || "", isAdminParams || "")
    ) {
      return;
    }
    setSelectItem({
      key: item,
      value: subTitle,
    });
  };

  return (
    <Row>
      {pageFormData &&
        Object.keys(pageFormData).length > 0 &&
        Object.keys(pageFormData).map((item) => {
          //@ts-ignore
          let subTitle = pageFormData[item];
          let inputType = typeof subTitle === "object" ? "object" : "radioBtn";
          switch (inputType) {
            case "radioBtn":
              return (
                <div
                  key={item}
                  onClick={() => handleSelect(item, subTitle)}
                  className="cursorPointer"
                >
                  <CustomRadioBtn
                    title={splitByCapitalLetter(item)}
                    subTitle={subTitle}
                    active={selectItem.key === item}
                    disabled={
                      statusParams === "completed" ||
                      !getStatusVal(
                        userRole,
                        orderIdParams || "",
                        isAdminParams || ""
                      )
                        ? true
                        : false
                    }
                  />
                </div>
              );
            case "object":
              return (
                <div
                  key={item}
                  onClick={() => handleSelect(item, subTitle)}
                  className="cursorPointer"
                >
                  <CustomRadioBtn
                    title={splitByCapitalLetter(item)}
                    active={selectItem.key === item}
                    disabled={
                      statusParams === "completed" ||
                      !getStatusVal(
                        userRole,
                        orderIdParams || "",
                        isAdminParams || ""
                      )
                        ? true
                        : false
                    }
                  >
                    {Object.keys(subTitle).map((item) => {
                      //@ts-ignore

                      return (
                        <InputText
                          label={item}
                          onChange={handleChange}
                          name={item}
                          //@ts-ignore
                          value={formValue[item]}
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      );
                    })}
                  </CustomRadioBtn>
                </div>
              );
            default:
              return null;
          }
        })}

      {statusParams !== "completed" &&
      getStatusVal(userRole, orderIdParams || "", isAdminParams || "") &&
      // (
      //   <div className="mb-4 d-flex justify-content-end">
      //     <CustomButton
      //       btnName="Continue"
      //       classNames="px-5"
      //       handleBtnClick={handleContinue}
      //     />
      //   </div>
      // )

      getTypeParams === "design" ? (
        <div className="mt-4 d-flex justify-content-end">
          <CustomButton
            btnName={` ${orderIdParams ? "Edit order" : "Place order"} `}
            classNames="px-5"
            handleBtnClick={handleContinueEdit}
            loading={loading}
          />
        </div>
      ) : (
        <div className="mb-4 d-flex justify-content-end">
          <CustomButton
            btnName="Continue"
            classNames="px-5"
            handleBtnClick={handleContinue}
          />
        </div>
      )}
    </Row>
  );
};
