import "./styles.scss";

import React, { Dispatch, SetStateAction } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CustomButton } from "ReuseableComponents/Button/CustomButton";

interface ModalProps {
  children: React.ReactNode;
  modalTitle?: string;
  showFooter?: boolean;
  btnSecondaryText?: string;
  btnPrimaryText?: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  btnPrimaryClick?: () => void;
  secPrimaryClick?: () => void;
  classNames?: string;
  showModalTitle?: boolean;
  primaryLoading?: boolean;
}
export const ModalComponent = ({
  children,
  classNames,
  showFooter,
  modalTitle,
  btnSecondaryText,
  btnPrimaryText,
  showModal,
  setShowModal,
  btnPrimaryClick,
  secPrimaryClick,
  showModalTitle = true,
  primaryLoading,
}: ModalProps) => {
  const handleToggle = () => {
    setShowModal(!showModal);
  };
  return (
    <Modal
      className={`custom-modal ${classNames}`}
      isOpen={showModal}
      toggle={handleToggle}
      centered
      backdrop={`static`}
    >
      {showModalTitle && (
        <ModalHeader className="titleFour" toggle={handleToggle}>
          {modalTitle}
        </ModalHeader>
      )}

      <ModalBody>{children}</ModalBody>
      {showFooter && (
        <ModalFooter>
          <CustomButton
            color="secondary"
            btnName={btnSecondaryText || ""}
            classNames="px-4"
            handleBtnClick={() =>
              secPrimaryClick ? secPrimaryClick() : setShowModal(false)
            }
          />
          <CustomButton
            btnName={btnPrimaryText || ""}
            classNames="px-4"
            handleBtnClick={btnPrimaryClick}
            loading={primaryLoading}
          />
        </ModalFooter>
      )}
    </Modal>
  );
};
