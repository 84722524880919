import React, { Dispatch, SetStateAction } from "react";

import { addMinutes, formatISO } from "date-fns";
import { Card, CardBody, Col, Row } from "reactstrap";
import { DatePicker } from "ReuseableComponents";

interface FilterComponentProps {
  picker: string[];
  setPicker: Dispatch<SetStateAction<string[]>>;
}

export const FilterComponent = ({
  picker,
  setPicker,
}: FilterComponentProps) => {
  const handleDateChange = (date: Date[]) => {
    if (date.length === 2) {
      setPicker([
        formatISO(date[0]),
        formatISO(new Date(addMinutes(date[1], 23 * 60 + 59))),
      ]);
    }
  };

  return (
    <div>
      <Card className="filterComponent border-0 common-card">
        <CardBody>
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <h1 className="titleFour "> Filter</h1>
            </Col>
            <Col md={6}>
              <div className="d-flex align-items-center justify-content-end">
                <p className=" bodyThree me-3"> Date</p>
                <DatePicker
                  mode={"range"}
                  value={picker}
                  handleDateChange={handleDateChange}
                  placeholder="All"
                  setPicker={setPicker}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
