import { useEffect, useState } from "react";

import { getLocalStorage } from "utils/localStorage/localStorage";

export const useUserRole = () => {
  const [userActualRole, setUserActualRole] = useState("");

  const userRole = getLocalStorage("userType");

  useEffect(() => {
    const userType = userRole ? JSON.parse(userRole) : [];
    if (userRole && userType) {
      const role =
        userType.find((item: string) => item === "SUPERADMIN") || null;

      setUserActualRole(role ? "SUPERADMIN" : "ADMIN");
    }
  }, [userRole]);

  return {
    userRole: userActualRole,
  };
};
