import { gql } from "@apollo/client";

export const GET_SUPER_ADMIN_TEAMS = gql`
  query ($limit: Int, $page: Int) {
    superadminGetTeams(paginationInput: { limit: $limit, page: $page }) {
      data {
        id
        email
        firstName
        lastName
        middleName
        createdAt
        taskCount
        profileImage
        roles
        engagedIn
        status {
          active
          confirmed
        }
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_ADMIN_TEAMS = gql`
  query ($limit: Int, $page: Int) {
    adminGetTeams(paginationInput: { limit: $limit, page: $page }) {
      data {
        id
        email
        firstName
        lastName
        middleName
        createdAt
        taskCount
        orderCount
        taskCompletedCount
        phone
        profileImage
        roles
        engagedIn
        status {
          active
          confirmed
        }
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_SUPER_ADMIN_TEAMS_BY_ID = gql`
  query ($userId: String!) {
    superadminGetTeamById(userId: $userId) {
      id
      firstName
      lastName
      middleName
      email
      phone
      createdAt
      taskCount
      taskCompletedCount
      orderCount
      profileImage
      roles
      address {
        country
        city
        banchi
        municipality
        prefecture
        town
        buildingName
        roomNumber
      }
    }
  }
`;

export const GET_SUPER_ADMIN_ROLES = gql`
  query superadminGetUserRoles {
    superadminGetUserRoles
  }
`;

export const ADD_SUPER_ADMIN_USER = gql`
  mutation (
    $phone: String
    $password: String!
    $countryCode: String
    $roles: [UserRoleType!]!
  ) {
    superadminAddUser(
      addUserInput: {
        phone: $phone
        password: $password
        countryCode: $countryCode
        roles: $roles
      }
    )
  }
`;

export const EDIT_SUPER_ADMIN_USER = gql`
  mutation ($userId: String!, $roles: [UserRoleType!]!) {
    superadminEditUser(userId: $userId, roles: $roles) {
      id
    }
  }
`;

export const DELETE_SUPER_ADMIN_USER = gql`
  mutation ($userId: String!) {
    superadminDeleteUser(userId: $userId)
  }
`;
