import { useEffect, useState } from "react";

import axios from "axios";
import { UserCard } from "Components";
import { format } from "date-fns";
import {
  DELETE_ADMIN_CLIENT,
  GET_ADMIN_CLIENTS_BY_ID,
} from "queries/adminClient";
import {
  DELETE_SUPER_ADMIN_CLIENT,
  GET_SUPER_ADMIN_CLIENTS_BY_ID,
} from "queries/superAdminClient";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BreadCrumbs, ModalComponent } from "ReuseableComponents";
import { getFullName, useUserRole } from "utils";

import { useMutation, useQuery } from "@apollo/client";

import { FilterComponent } from "./components/FilterComponent/FilterComponent";
import { TableComponent } from "./components/TableComponent/TableCompoent";

interface OrderHistoryProps {
  data: any[];
  meta: {
    limit: number;
    page: number;
    total: number;
  };
}

export default function ClientsView() {
  //var
  let { clientId } = useParams();
  const navigate = useNavigate();

  const { userRole } = useUserRole();

  //state
  const [searchParams, setSearchParams] = useSearchParams();
  const [picker, setPicker] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [rowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "") || 1
  );
  const [orderHistory, setOrderHistory] = useState<OrderHistoryProps>();
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);

  //query
  const { data: clientData } = useQuery(
    userRole === "SUPERADMIN"
      ? GET_SUPER_ADMIN_CLIENTS_BY_ID
      : GET_ADMIN_CLIENTS_BY_ID,
    {
      variables: {
        userId: clientId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [superadminDeleteClient, { error: deleteError, data: deleteData }] =
    useMutation(
      userRole === "SUPERADMIN"
        ? DELETE_SUPER_ADMIN_CLIENT
        : DELETE_ADMIN_CLIENT,
      {
        errorPolicy: "all",
      }
    );

  const handleDelete = () => {
    superadminDeleteClient({
      variables: {
        id: clientId,
      },
    });
  };

  const getKeyData = () => {
    if (userRole === "SUPERADMIN") {
      return "superadminGetClientById";
    } else {
      return "adminGetClientById";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrderHistoryLoading(true);
        const response = await axios.post(
          `/${userRole.toLowerCase()}/clients/${clientId}/orders/history?paginationInput[limit]=${rowsPerPage}&paginationInput[page]=${currentPage}`,
          {
            dateRange: {
              from: picker[0],
              to: picker[1],
            },
          }
        );
        setOrderHistory(response.data);
        setOrderHistoryLoading(false);
      } catch (error) {
        // toast.error(error.message);
        setOrderHistoryLoading(false);
      }
    };

    if (clientId && userRole) {
      fetchData();
    }
  }, [clientId, rowsPerPage, currentPage, userRole, picker]);

  useEffect(() => {
    if (currentPage) {
      setSearchParams({
        page: currentPage.toString(),
      });
    }
  }, [currentPage, setSearchParams]);

  useEffect(() => {
    if (deleteData) {
      navigate("/clients");
    }
  }, [deleteData, navigate]);

  return (
    <div>
      <Row>
        <Col sm={12} lg={4}>
          <BreadCrumbs
            routes={[
              {
                name: "client",
                active: false,
                link: `/clients`,
              },
              {
                name: `${clientData?.[getKeyData()]?.firstName} ${
                  clientData?.[getKeyData()]?.middleName
                } ${clientData?.[getKeyData()]?.lastName}`,
                active: true,
                link: `/clients/${clientData?.[getKeyData()]?.id}`,
              },
            ]}
          />
          <div className="mt-4">
            <UserCard
              fullName={getFullName(
                clientData?.[getKeyData()]?.firstName || "",
                clientData?.[getKeyData()]?.middleName || "",
                clientData?.[getKeyData()]?.lastName || ""
              )}
              email={clientData?.[getKeyData()]?.email}
              userId={clientData?.[getKeyData()]?.id}
              joinedDate={clientData?.[getKeyData()]?.createdAt || new Date()}
              address={`${
                clientData?.[getKeyData()]?.address?.prefecture
                  ? clientData?.[getKeyData()]?.address?.prefecture
                  : ""
              } ${
                clientData?.[getKeyData()]?.address?.municipality
                  ? `, ${clientData?.[getKeyData()]?.address?.municipality}`
                  : ""
              } ${
                clientData?.[getKeyData()]?.address?.town
                  ? `, ${clientData?.[getKeyData()]?.address?.town}`
                  : ""
              } ${
                clientData?.[getKeyData()]?.address?.banchi
                  ? `, ${clientData?.[getKeyData()]?.address?.banchi}`
                  : ""
              } ${
                clientData?.[getKeyData()]?.address?.buildingName
                  ? `, ${clientData?.[getKeyData()]?.address?.buildingName}`
                  : ""
              } ${
                clientData?.[getKeyData()]?.address?.roomNumber
                  ? `, ${clientData?.[getKeyData()]?.address?.roomNumber}`
                  : ""
              }`}
              contact={clientData?.[getKeyData()]?.phone}
              userImage={clientData?.[getKeyData()]?.profileImage}
              joinedCount={
                clientData?.[getKeyData()]?.createdAt
                  ? format(
                      new Date(clientData?.[getKeyData()]?.createdAt),
                      "YYY"
                    )
                  : ""
              }
              totalCount={clientData?.[getKeyData()]?.orderCount}
              userName={clientData?.[getKeyData()]?.userName}
              showActions={true}
              showDelete={userRole === "SUPERADMIN"}
              deleteClick={() => setShowModal(true)}
              editClick={() => navigate(`/clients/${clientId}/edit`)}
              showEdit={true}
              showJoinedCount={true}
              showTodayCount={false}
              showTotalCount={true}
            />
          </div>
        </Col>
        <Col sm={12} lg={8} className="mt-4 mt-lg-0">
          <FilterComponent picker={picker} setPicker={setPicker} />
          <div className="mt-4">
            <TableComponent
              //@ts-ignore
              data={orderHistory?.data}
              metaData={orderHistory?.meta}
              loading={orderHistoryLoading}
              rowsPerPage={rowsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </Col>
      </Row>
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        showFooter
        modalTitle="Delete User?"
        btnPrimaryText="Yes, remove"
        btnSecondaryText="Go back"
        btnPrimaryClick={handleDelete}
      >
        <span className="bodyTwo">
          Are you sure you want to remove this user?
        </span>
      </ModalComponent>
    </div>
  );
}
