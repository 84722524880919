import { useState } from "react";

import {
  GET_ADMIN_FEEDBACK,
  GET_SUPER_ADMIN_FEEDBACK,
} from "queries/superAdminFeedBack";
import { useSearchParams } from "react-router-dom";
import { useUserRole } from "utils";

import { useQuery } from "@apollo/client";

import { FilterComponent } from "./components/FilterComponent/FilterComponent";
import { SidebarComponent } from "./components/SidebarComponent/SidebarComponent";
import { TableComponent } from "./components/TableComponent/TableComponent";

export default function Clients() {
  //state
  const [searchParams] = useSearchParams();

  const [keyword, setKeyword] = useState<string>("");
  const [picker, setPicker] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "") || 1
  );
  const [showSidebar, setShowSidebar] = useState(false);

  const { userRole } = useUserRole();

  let variables = {
    limit: rowsPerPage,
    page: currentPage,
    from: picker[0],
    to: picker[1],
  };

  if (keyword) {
    variables = {
      ...variables,
      //@ts-ignore
      keyword: keyword,
    };
  }

  //query
  const { data: feedback, loading: clientLoading } = useQuery(
    userRole === "SUPERADMIN" ? GET_SUPER_ADMIN_FEEDBACK : GET_ADMIN_FEEDBACK,
    {
      variables: variables,
    }
  );

  const getKey = () => {
    if (userRole === "SUPERADMIN") {
      return `superadminGetFeedbacks`;
    } else {
      return `adminGetFeedbacks`;
    }
  };

  return (
    <div className="clients">
      <FilterComponent
        setKeyword={setKeyword}
        picker={picker}
        setPicker={setPicker}
        setRowsPerPage={setRowsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <div className="mt-4">
        <TableComponent
          data={feedback?.[getKey()]?.data}
          metaData={feedback?.[getKey()]?.meta}
          loading={clientLoading}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
      <SidebarComponent
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
    </div>
  );
}
