import { gql } from "@apollo/client";

export const GET_SUPER_ADMIN_FEEDBACK = gql`
  query (
    $limit: Int
    $page: Int
    $keyword: String
    $from: String
    $to: String
  ) {
    superadminGetFeedbacks(
      paginationInput: { limit: $limit, page: $page }
      feedbackFilterInput: {
        keyword: $keyword
        dateRange: { from: $from, to: $to }
      }
    ) {
      data {
        id
        message
        platform
        createdAt
        user {
          firstName
          middleName
          lastName
          profileImage
          email
          id
        }
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;

export const GET_ADMIN_FEEDBACK = gql`
  query (
    $limit: Int
    $page: Int
    $keyword: String
    $from: String
    $to: String
  ) {
    adminGetFeedbacks(
      paginationInput: { limit: $limit, page: $page }
      feedbackFilterInput: {
        keyword: $keyword
        dateRange: { from: $from, to: $to }
      }
    ) {
      data {
        id
        message
        platform
        createdAt
        user {
          firstName
          middleName
          lastName
          profileImage
          email
          id
        }
      }
      meta {
        limit
        page
        total
      }
    }
  }
`;
