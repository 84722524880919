// import "./styles.scss";

import React from "react";

import adBot from "assets/images/ads/ad-profile.png";
import { Card, CardBody, Col, Row } from "reactstrap";

import { useAds } from "../HomeScreenBottom/useAds";

export const ProfileScreen = () => {
  const { filePreview, renderImageComponent } = useAds({
    adsType: "PROFILE_SCREEN",
  });

  return (
    <Card className="border-0">
      <CardBody>
        <Row>
          <Col md={7}>
            <div className="title d-flex align-items-center mb-4">
              <h1 className="titleThree me-3 "> Profile Screen</h1>
              <p className="labelTwo">Recommended size: 361 ✕ 136</p>
            </div>
            <div className="mt-3">
              {renderImageComponent({
                image: filePreview?.[0]?.image,
                imageId: filePreview?.[0]?.id ?? "",
                position: 1,
              })}
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={4}>
            <h3 className="titleThree">Preview</h3>
            <div className="preview mt-3">
              <img
                alt="ad-bottom-screen"
                src={adBot}
                className="mobile-skeleton img-fluid"
              />
              {filePreview?.[0]?.image && (
                <img
                  alt="ad-bottom-screen"
                  src={filePreview?.[0]?.image}
                  className="mobile-preview img-fluid"
                />
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
