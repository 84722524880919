import React from "react";

import { HomeScreenBottom } from "./Components/HomeScreenBottom/HomeScreenBottom";
import { HomeScreenSlider } from "./Components/HomeScreenSlider/HomeScreenSlider";
import { ProfileScreen } from "./Components/ProfileScreen/ProfileScreen";

export default function Advertisement() {
  return (
    <div className="advertisement">
      <HomeScreenSlider />
      <HomeScreenBottom />
      <ProfileScreen />
    </div>
  );
}
