import draftIcon from "assets/icons/draftIcon.svg";
import badgesIcon from "assets/icons/statusIcon/badges.svg";
import inProgressIcon from "assets/icons/statusIcon/time.svg";
import xCircleIcon from "assets/icons/statusIcon/XCircle.svg";
import tickSuccess from "assets/icons/tickSuccess.svg";

export const adminTaskStatus = {
  DONE: {
    name: "Completed",
    bgColor: "#E5FEF1",
    textColor: "#0CCE6B",
    icon: <img alt="design" src={tickSuccess} className="img-fluid me-1" />,
  },
  IN_PROGRESS: {
    name: "In Progress",
    bgColor: "#E5F6FF",
    textColor: "#5CC8FF",
    icon: <img alt="design" src={inProgressIcon} className="img-fluid me-1" />,
  },
  REJECTED: {
    name: "Rejected",
    bgColor: "#FFCAD5",
    textColor: "#EF2D56",
    icon: <img alt="design" src={xCircleIcon} className="img-fluid me-1" />,
  },
  TODO: {
    name: "New",
    bgColor: "#FFE6C5",
    textColor: "#FF9F1C",
    icon: <img alt="design" src={badgesIcon} className="img-fluid me-1" />,
  },
  DRAFT: {
    name: "Draft",
    bgColor: "#E2E2E2",
    textColor: "#606060",
    icon: <img alt="design" src={draftIcon} className="img-fluid me-1" />,
  },
};
