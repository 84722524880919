import "./styles.scss";

import React from "react";

import { useDrop } from "react-dnd";

import { ITEM_TYPE } from "../data/types";

interface DropWrapperProps {
  onDrop: any;
  children: any;
  status: any;
}

export const DropWrapper = ({ onDrop, children, status }: DropWrapperProps) => {
  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    // canDrop: (item: any) => {
    //   const itemIndex = statuses.findIndex(
    //     (si: any) => si.status === item.status
    //   );
    //   const statusIndex = statuses.findIndex((si) => si.status === status);

    //   return [itemIndex + 1, itemIndex - 1, itemIndex].includes(statusIndex);
    // },
    drop: (item: any, monitor: any) => {
      onDrop(item, monitor, status);
    },
  });

  return (
    <div ref={drop} className="drop-wrapper">
      {React.cloneElement(children)}
    </div>
  );
};
