import React from "react";

import appLogo from "assets/images/logo/logoSecond.svg";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Link to={"/"}>
      <img src={appLogo} alt="app-logo" className="img-fluid" />
    </Link>
  );
};
