import { useEffect, useState } from "react";

import axios from "axios";
import { UserCard } from "Components";
import { format } from "date-fns";
import {
  DELETE_SUPER_ADMIN_USER,
  GET_SUPER_ADMIN_TEAMS,
  GET_SUPER_ADMIN_TEAMS_BY_ID,
} from "queries/superAdminTeam";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BreadCrumbs, ModalComponent } from "ReuseableComponents";
import { getFullName, useUserRole } from "utils";

import { useMutation, useQuery } from "@apollo/client";

import { FilterComponent } from "./components/FilterComponent/FilterComponent";
import { TableComponent } from "./components/TableComponent/TableComponent";

interface OrderHistoryProps {
  data: any[];
  meta: {
    limit: number;
    page: number;
    total: number;
  };
}

export default function TeamsView() {
  //var
  let { teamsId } = useParams();
  const navigate = useNavigate();

  const { userRole } = useUserRole();

  //state
  const [searchParams, setSearchParams] = useSearchParams();
  const [picker, setPicker] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [rowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "") || 1
  );
  const [orderHistory, setOrderHistory] = useState<OrderHistoryProps>();
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);

  //query
  const { data: clientData } = useQuery(GET_SUPER_ADMIN_TEAMS_BY_ID, {
    variables: {
      userId: teamsId,
    },
  });

  const [
    superadminDeleteUser,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_SUPER_ADMIN_USER, {
    errorPolicy: "all",
    refetchQueries: [GET_SUPER_ADMIN_TEAMS],
  });

  const handleDelete = () => {
    superadminDeleteUser({
      variables: {
        userId: teamsId,
      },
    });
  };

  const getKeyData = () => {
    if (userRole === "SUPERADMIN") {
      return "superadminGetClientById";
    } else {
      return "adminGetClientById";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrderHistoryLoading(true);
        const response = await axios.post(
          `/superadmin/teams/${teamsId}/tasks/history?paginationInput[limit]=${rowsPerPage}&paginationInput[page]=${currentPage}`,
          {
            dateRange: {
              from: picker[0],
              to: picker[1],
            },
          }
        );
        setOrderHistory(response.data);
        setOrderHistoryLoading(false);
      } catch (error) {
        // toast.error(error.message);
        setOrderHistoryLoading(false);
      }
    };

    if (teamsId) {
      fetchData();
    }
  }, [teamsId, rowsPerPage, currentPage, userRole, picker]);

  useEffect(() => {
    if (currentPage) {
      setSearchParams({
        page: currentPage.toString(),
      });
    }
  }, [currentPage, setSearchParams]);

  useEffect(() => {
    if (deleteData) {
      navigate("/teams");
    }
  }, [deleteData, navigate]);

  return (
    <div>
      <Row>
        <Col md={4}>
          <BreadCrumbs
            routes={[
              {
                name: "teams",
                active: false,
                link: `/teams`,
              },
              {
                name: getFullName(
                  clientData?.superadminGetTeamById?.firstName,
                  clientData?.superadminGetTeamById?.middleName,
                  clientData?.superadminGetTeamById?.lastName
                ),
                active: true,
                link: `/clients/${clientData?.[getKeyData()]?.id}`,
              },
            ]}
          />
          <div className="mt-4">
            <UserCard
              fullName={getFullName(
                clientData?.superadminGetTeamById?.firstName,
                clientData?.superadminGetTeamById?.middleName,
                clientData?.superadminGetTeamById?.lastName
              )}
              email={clientData?.superadminGetTeamById?.email}
              userId={clientData?.superadminGetTeamById?.id}
              joinedDate={
                clientData?.superadminGetTeamById?.createdAt || new Date()
              }
              address={`${
                clientData?.superadminGetTeamById?.address?.prefecture
                  ? clientData?.superadminGetTeamById?.address?.prefecture
                  : ""
              } ${
                clientData?.superadminGetTeamById?.address?.municipality
                  ? `, ${clientData?.superadminGetTeamById?.address?.municipality}`
                  : ""
              } ${
                clientData?.superadminGetTeamById?.address?.town
                  ? `, ${clientData?.superadminGetTeamById?.address?.town}`
                  : ""
              } ${
                clientData?.superadminGetTeamById?.address?.banchi
                  ? `, ${clientData?.superadminGetTeamById?.address?.banchi}`
                  : ""
              } ${
                clientData?.superadminGetTeamById?.address?.buildingName
                  ? `, ${clientData?.superadminGetTeamById?.address?.buildingName}`
                  : ""
              } ${
                clientData?.superadminGetTeamById?.address?.roomNumber
                  ? `, ${clientData?.superadminGetTeamById?.address?.roomNumber}`
                  : ""
              }`}
              contact={clientData?.superadminGetTeamById?.phone}
              userImage={clientData?.superadminGetTeamById?.profileImage}
              joinedCount={
                clientData?.superadminGetTeamById?.createdAt
                  ? format(
                      new Date(clientData?.superadminGetTeamById?.createdAt),
                      "YYY"
                    )
                  : ""
              }
              totalCount={clientData?.superadminGetTeamById?.taskCount}
              todayCount={clientData?.superadminGetTeamById?.taskCompletedCount}
              userName={clientData?.superadminGetTeamById?.userName}
              showActions={true}
              showDelete={true}
              showEdit={false}
              deleteClick={() => setShowModal(true)}
              editClick={() => navigate(`/teams`)}
              roles={clientData?.superadminGetTeamById?.roles}
              showJoinedCount={true}
              showTodayCount={true}
              showTotalCount={true}
            />
          </div>
        </Col>
        <Col md={8}>
          <FilterComponent picker={picker} setPicker={setPicker} />
          <div className="mt-4">
            <TableComponent
              //@ts-ignore
              data={orderHistory?.data}
              metaData={orderHistory?.meta}
              loading={orderHistoryLoading}
              rowsPerPage={rowsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </Col>
      </Row>
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        showFooter
        modalTitle="Delete User?"
        btnPrimaryText="Yes, remove"
        btnSecondaryText="Go back"
        btnPrimaryClick={handleDelete}
      >
        <span className="bodyTwo">
          Are you sure you want to remove this user?
        </span>
      </ModalComponent>
    </div>
  );
}
