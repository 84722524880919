import {
  emailValidation,
  nameValidation,
  passwordValidation,
  phoneValidation,
} from 'utils';
import * as Yup from 'yup';

export const myAccountSchema = Yup.object().shape({
  email: emailValidation(),
  firstName: nameValidation("First Name"),
  lastName: nameValidation("Last Name"),
  phone: phoneValidation("Phone"),
});

export const updatePasswordSchema = Yup.object().shape({
  newPassword: passwordValidation("New Password").notOneOf(
    [Yup.ref("oldPassword")],
    "New password must be different from old password"
  ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm Password is required"),
});
