import './styles.scss';

import React from 'react';

import { GET_ADMIN_ORDER_ANALYTICS } from 'queries/adminOrder';
import {
  getStatusClassName,
  getStatusIcon,
} from 'utils';

import { useQuery } from '@apollo/client';

interface DashboardCardProps {}

export const DashboardCard = ({}: DashboardCardProps) => {
  // let taskTypeData =
  //   //@ts-ignore
  //   taskType[taskName?.split(" ").join("_").toUpperCase() || ""];

  const {
    data: orderAnalytics,
    error: orderAnalyticsError,
    loading: orderAnalyticsLoading,
  } = useQuery(GET_ADMIN_ORDER_ANALYTICS, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className={`dashboard-card`}>
      <h4 className="labelTwo"> Task Completed Today</h4>
      <div className="my-2 d-flex align-items-center justify-content-between">
        <h6 className="headerThree">
          {" "}
          {orderAnalytics?.getAdminOrderAnalytics.taskCompleted || 0}{" "}
        </h6>
        <span
          className={`labelTwo growthNumber ${getStatusClassName(
            orderAnalytics?.getAdminOrderAnalytics.status
          )}`}
        >
          {" "}
          {getStatusIcon(orderAnalytics?.getAdminOrderAnalytics.status)}
          {Number(orderAnalytics?.getAdminOrderAnalytics?.percent).toFixed(0)}%
        </span>
      </div>
      <p className="labelThree">
        {orderAnalytics?.getAdminOrderAnalytics.change}{" "}
        {orderAnalytics?.getAdminOrderAnalytics.message}
      </p>
    </div>
  );
};
