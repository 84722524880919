import "./styles.scss";

import React, { useEffect, useState } from "react";

import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Card } from "reactstrap";

ChartJS.register(ArcElement, Tooltip);

interface TaskCategoryProps {
  data: {
    name: string;
    percent: string;
  }[];
}

interface labelsProps {
  label: string;
  color: string;
  percent: string;
}

export const TaskCategory = ({ data }: TaskCategoryProps) => {
  const [labels, setLabels] = useState<labelsProps[]>([]);
  const [labelIndex, setLabelIndex] = useState(0);

  useEffect(() => {
    if (data) {
      const d = data.map((item) => {
        return {
          label: item.name,
          percent: item.percent,
          color:
            item.name.toLowerCase() === "design"
              ? "#FF5A49"
              : item.name.toLowerCase() === "translation"
              ? "#862DA7"
              : item.name.toLowerCase() === "visa"
              ? "#DB398C"
              : item.name.toLowerCase() === "passport"
              ? "#FFA600"
              : "",
        };
      });
      //@ts-ignore
      setLabels(d);
    }
  }, [data]);

  const chartData = {
    labels: labels?.map((item: any) => item.label),
    datasets: [
      {
        label: "value",
        data: labels?.map((item: any) => item.percent),
        // labels: data?.map((item) => item.percent),
        borderWidth: 0,
        backgroundColor: labels?.map((item: any) => item.color),
      },
    ],
  };

  return (
    <Card className="border-0 taskCategory h-100">
      <h2 className="titleFour mb-3">Task by Category</h2>
      {labels.length > 0 ? (
        <div
          className="d-flex w-100 justify-content-between"
          style={{
            height: 300,
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <Doughnut
              //   plugins={[textCenter]}
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "41%",
                left: "38%",
                textAlign: "center",
              }}
            >
              <h4 className="bodyTwo"> {labels[labelIndex]?.label} </h4>
              <p className="titleFour"> {labels[labelIndex]?.percent}% </p>
            </div>
          </div>
          <div>
            {labels.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className="d-flex align-items-center mb-3"
                  onClick={() => setLabelIndex(index)}
                >
                  <div
                    style={{
                      backgroundColor: item?.color,
                      height: 10,
                      width: 10,
                      borderRadius: "50%",
                      marginRight: 5,
                    }}
                  />{" "}
                  <p> {item?.label} </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <p className="bodyTwo text-center">No data available</p>
      )}
    </Card>
  );
};
