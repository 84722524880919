// css import
import "./styles.scss";
import "../../assets/styles/color.scss";

import * as React from "react";

import { Pagination } from "Components/Pagination/Pagination";
// data table import
import DataTable from "react-data-table-component";

// interface
interface TableData {
  columns: Array<object>;
  data: Array<object>;
  pagination?: boolean;
  name?: string;
  currentPage: number;
  rowsPerPage: number;
  totalCount: number;
  handlePagination: (page: { selected: number }) => void;
  variant: "primary" | "secondary";
  showHeader?: boolean;
  showSubHeader?: boolean;
  loading?: boolean;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  onRowClicked?: (row: any) => void;
}

export const Table: React.FC<TableData> = ({
  columns,
  data,
  pagination,
  name,
  currentPage,
  rowsPerPage,
  totalCount,
  handlePagination,
  variant,
  showHeader,
  showSubHeader,
  loading,
  setCurrentPage,
  onRowClicked,
}) => {
  const customStyle = {
    headRow: {
      style: {
        backgroundColor: variant === "primary" ? "#F3F3F3" : "#FFFFFF",
        minHeight: "40x", // override the row height
        borderBottomStyle: "none !important",
        fontSize: "12px",
        fontWeight: "500",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "8px",
        paddingBottom: "8px",
        textTransform: "uppercase",
        color: "#606060",
      },
    },
    rows: {
      style: {
        minHeight: "68px", // override the row height
        borderBottomStyle: "none !important",
        borderColor: "none",
        paddingLeft: "16px",
        paddingRight: "16px",
        color: "#606060",
        fontSize: "16px",
        fontWeight: "400",
        textTransform: "capitalize",
        "&:nth-child(odd)": {
          backgroundColor: variant === "primary" ? "#FFFFFF" : "#FAFAFA",
        },
        "&:nth-child(even)": {
          backgroundColor: variant === "primary" ? "#FAFAFA" : "#FFFFFF",
        },
      },
      odd: {
        style: {
          backgroundColor: "red",
        },
      },
    },
  };

  return (
    <>
      <div className="table-container">
        <div className={variant === "primary" ? "card" : ""}>
          <div className={variant !== "primary" ? "card" : ""}>
            {showHeader && (
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="titleFour">{name}</h3>
                {showSubHeader && (
                  <div className="d-flex">
                    <div className="d-flex align-items-center me-3">
                      <h5 className="titleTwo me-2">32</h5>
                      <p className="labelThree">Completed</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <h5 className="titleTwo me-2">85</h5>
                      <p className="labelThree">In Progress</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="react-dataTable">
              <DataTable
                columns={columns}
                data={data}
                customStyles={customStyle}
                progressPending={loading}
                onRowClicked={onRowClicked}
              />
            </div>
          </div>
          {pagination ? (
            <div>
              <Pagination
                totalCount={totalCount}
                handlePagination={handlePagination}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
