import axios from "axios";
import { toast } from "react-toastify";
import { getLocalStorage } from "utils/localStorage/localStorage";

// Alter defaults after instance has been created
axios.defaults.baseURL = process.env.REACT_APP_API_URL_REST;
axios.defaults.headers.post["Content-Type"] = "application/json";
// instance.defaults.headers.common["Authorization"] = AUTH_TOKEN;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const tokenValue = getLocalStorage("token");

    const parsedValue = tokenValue ? JSON.parse(tokenValue) : null;

    config.headers["Authorization"] = `Bearer ${parsedValue.access_token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (axios.isAxiosError(error)) {
      if (error.response?.data.message !== "Unauthorized") {
        toast.error(error.response?.data.message || "Something went wrong");
      }
    }
    return Promise.reject(error);
  }
);
