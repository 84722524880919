import React, { useEffect, useState } from "react";

import axios from "axios";
import { UserCard } from "Components";
import { addMinutes, formatISO } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { DatePicker } from "ReuseableComponents";
import { getFullName, taskType, useUserContext } from "utils";

import { TableComponent } from "./Component/TableComponent/TableCompoent";

interface OrderHistoryProps {
  data: any[];
  meta: {
    limit: number;
    page: number;
    total: number;
  };
}

export default function Profile() {
  //var
  const navigate = useNavigate();
  const { user } = useUserContext();

  //state
  const [searchParams] = useSearchParams();
  const [rowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "") || 1
  );
  const [orderHistory, setOrderHistory] = useState<OrderHistoryProps>();
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);

  const [picker, setPicker] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrderHistoryLoading(true);
        const response = await axios.post(
          `/admin/orders/my?paginationInput[limit]=${rowsPerPage}&paginationInput[page]=${currentPage}`,
          {
            dateRange: {
              from: picker[0],
              to: picker[1],
            },
          }
        );
        setOrderHistory(response.data);
        setOrderHistoryLoading(false);
      } catch (error) {
        // toast.error(error.message);
        setOrderHistoryLoading(false);
      }
    };
    fetchData();
  }, [rowsPerPage, currentPage, picker]);

  const handleDateChange = (date: Date[]) => {
    if (date.length === 2) {
      setPicker([
        formatISO(date[0]),
        formatISO(new Date(addMinutes(date[1], 23 * 60 + 59))),
      ]);
    }
  };

  const getRoles = () => {
    return user?.roles?.map((data: string, i: number) => {
      //@ts-ignore
      let taskTypeData = taskType[data.split(" ").join("_").toUpperCase()];
      return (
        <div
          key={i}
          className="d-flex align-items-center px-2 py-1 mx-1 mb-2"
          style={{
            backgroundColor: taskTypeData.bgColor,
            width: "fit-content",
            borderRadius: 4,
          }}
        >
          <p
            className={`labelFour task-name`}
            style={{
              color: taskTypeData.textColor,
            }}
          >
            {taskTypeData.name.replace(/_/g, " ")}
          </p>
        </div>
      );
    });
  };

  return (
    <div>
      {" "}
      <div className="container-fluid p-0">
        <Row>
          <Col sm={0} lg={4}>
            <div className="d-none d-lg-block">
              <UserCard
                fullName={getFullName(
                  user?.firstName || "",
                  user?.middleName || "",
                  user?.lastName || ""
                )}
                email={user?.email}
                // userId={user?.id}
                joinedDate={user?.createdDTM}
                address={`${
                  user?.userAddress?.prefecture
                    ? user?.userAddress?.prefecture
                    : ""
                } ${
                  user?.userAddress?.municipality
                    ? `, ${user?.userAddress?.municipality}`
                    : ""
                } ${
                  user?.userAddress?.town ? `, ${user?.userAddress?.town}` : ""
                } ${
                  user?.userAddress?.banchi
                    ? `, ${user?.userAddress?.banchi}`
                    : ""
                } ${
                  user?.userAddress?.buildingName
                    ? `, ${user?.userAddress?.buildingName}`
                    : ""
                } ${
                  user?.userAddress?.roomNumber
                    ? `, ${user?.userAddress?.roomNumber}`
                    : ""
                }`}
                contact={user?.phone}
                userImage={user?.profileImage}
                showEdit={true}
                showActions={true}
                editClick={() => navigate("/profile/edit")}
                showDelete={false}
                roles={user?.roles}
                showJoinedCount={false}
                showTodayCount={false}
                showTotalCount={false}
              />
            </div>
          </Col>
          <Col sm={12} lg={8}>
            <div className="d-flex align-items-center justify-content-end">
              <p className=" bodyThree me-3"> Date</p>
              <DatePicker
                mode={"range"}
                value={picker}
                handleDateChange={handleDateChange}
                placeholder="All"
                setPicker={setPicker}
              />
            </div>

            <div className="mt-4">
              <TableComponent
                //@ts-ignore
                data={orderHistory?.data}
                metaData={orderHistory?.meta}
                loading={orderHistoryLoading}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
