import React, { Dispatch, SetStateAction, useEffect } from "react";

import { addMinutes, formatISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
//reactstrap
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { DatePicker, FilterSearch, ReactSelect } from "ReuseableComponents";

interface filterComponentProps {
  setKeyword: Dispatch<SetStateAction<string>>;
  picker: string[];
  setPicker: Dispatch<SetStateAction<string[]>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
}

interface inputFiledProps {
  status: {
    label: string;
    value: string;
  } | null;
  orders: {
    label: string;
    value: string;
  } | null;
  rowsPerPage: {
    label: string;
    value: string;
  } | null;
  search: string;
}

export const FilterComponent = ({
  setKeyword,
  picker,
  setPicker,
  setRowsPerPage,
  currentPage,
  setCurrentPage,
}: filterComponentProps) => {
  //var

  //state

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = {
    search: "",
  };

  const { control, watch, setValue } = useForm<inputFiledProps>({
    mode: "onChange",
    defaultValues,
  });

  const handleDateChange = (date: Date[]) => {
    if (date.length === 2) {
      setPicker([
        formatISO(date[0]),
        formatISO(new Date(addMinutes(date[1], 23 * 60 + 59))),
      ]);
    }
  };

  //input field value
  const search = watch("search");
  const rowsPerPage = watch("rowsPerPage")?.value;

  useEffect(() => {
    setKeyword(search);

    setRowsPerPage(parseInt(rowsPerPage || "10"));

    setCurrentPage(currentPage);
    setSearchParams({
      keyword: search,
      startDate: picker?.[0] || "",
      endDate: picker?.[1] || "",
      rowsPerPage: rowsPerPage || "10",
      page: currentPage.toString(),
    });
  }, [
    watch,
    setKeyword,
    search,
    setSearchParams,
    picker,
    rowsPerPage,
    setRowsPerPage,
    currentPage,
    setCurrentPage,
  ]);

  const keywordParams = searchParams.get("keyword");
  const endDateParams = searchParams.get("endDate");
  const startDateParams = searchParams.get("startDate");

  useEffect(() => {
    if (keywordParams) {
      setValue("search", keywordParams || "");
    }
    if (endDateParams && startDateParams) {
      setPicker([startDateParams, endDateParams]);
    }
  }, [keywordParams, setValue, endDateParams, startDateParams, setPicker]);

  return (
    <div>
      <Card className="filterComponent border-0 common-card">
        <CardBody>
          <Row className="mb-3 ">
            <Col md={9}>
              <h1 className="titleFour ">Search & Filter</h1>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="dateRangeWrapper">
                <label className="mb-2 bodyThree "> Joining Date</label>
                <DatePicker
                  mode={"range"}
                  value={picker}
                  handleDateChange={handleDateChange}
                  placeholder="All"
                  setPicker={setPicker}
                />
              </div>
            </Col>
          </Row>
          <hr className="my-4" />
          <Row>
            <Col md={5} className="d-flex align-items-center ">
              <Label className="form-label m-0 me-3  bodyThree" for="search">
                Search
              </Label>
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <FilterSearch
                    placeholder="Search by name or Id"
                    field={field}
                    classNames="w-100"
                  />
                )}
              />
            </Col>
            <Col
              md={7}
              className="d-flex align-items-center justify-content-end"
            >
              <Label
                className="form-label m-0 me-3  bodyThree"
                for="rowsPerPage"
              >
                Show
              </Label>
              <Controller
                name="rowsPerPage"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    isMulti={false}
                    options={[
                      {
                        label: "10",
                        value: "10",
                      },
                      {
                        label: "20",
                        value: "20",
                      },
                      {
                        label: "50",
                        value: "50",
                      },
                      {
                        label: "100",
                        value: "100",
                      },
                    ]}
                    field={field}
                  />
                )}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
