import "./styles.scss";

import React, { Dispatch, SetStateAction, useState } from "react";

import uploadIcon from "assets/icons/uploadIcon.svg";
import uploadEditIcon from "assets/images/common/imageUploadEidt.svg";
import { Dropdown, DropdownMenu, DropdownToggle, Label } from "reactstrap";
import { checkFileSize } from "utils";

interface ImageUploadProps {
  filePreview: string;
  setFilePreview: Dispatch<SetStateAction<string>>;
  setProfileImage: Dispatch<SetStateAction<File>>;
}

export const ImageUpload = ({
  filePreview,
  setFilePreview,
  setProfileImage,
}: ImageUploadProps) => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleImageChange = ({
    target: {
      //@ts-ignore
      files: [file],
    },
  }) => {
    if (file) {
      if (checkFileSize(file.size)) {
        return;
      }
      setFilePreview(URL.createObjectURL(file));
      setProfileImage(file);
    }
  };

  const handleRemove = () => {
    setToggle(false);
    setFilePreview("");
    setProfileImage(new File([], ""));
  };

  return (
    <div className="image-upload">
      {filePreview ? (
        <div>
          <img
            src={filePreview}
            alt="user-img"
            className="img-fluid user-img"
          />

          <Dropdown isOpen={toggle} toggle={handleToggle} className="editIcon">
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={toggle}
              onClick={handleToggle}
            >
              <img src={uploadEditIcon} alt="no-data" className="img-fluid " />
            </DropdownToggle>
            <DropdownMenu className="p-3 border-0 dropMenu mb-2">
              {/* <div className="bodyTwo " onClick={handleRemove}>
                Remove Picture
              </div> */}
              <div>
                <Label className="bodyTwo cursorPointe mb-0">
                  <div className="bodyTwo cursorPointer text-white-space ">
                    Upload new picture
                  </div>
                  <input
                    type="file"
                    className="d-none"
                    accept="image/png, image/jpeg"
                    //@ts-ignore
                    onChange={handleImageChange}
                    multiple={false}
                  />
                </Label>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      ) : (
        <Label>
          <img src={uploadIcon} alt="no-data" className="img-fluid" />
          <input
            type="file"
            className="d-none"
            accept="image/png, image/jpeg"
            //@ts-ignore
            onChange={handleImageChange}
            multiple={false}
          />
        </Label>
      )}
    </div>
  );
};
