import { passwordValidation, phoneValidation } from "utils";
import * as Yup from "yup";

export const addTeamSchema = Yup.object().shape({
  phone: phoneValidation("Phone"),
  password: passwordValidation("Password"),
  // status: phoneValidation("status"),
});

export const editTeamSchema = Yup.object().shape({
  roles: Yup.array().required("Role is required"),
});
