import "./styles.scss";

import React, { MouseEvent } from "react";

import chatIcon from "assets/icons/chatIcon.svg";
import eyeIcon from "assets/icons/eye.svg";
import notesIcon from "assets/icons/notesGray.svg";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { DefaultImg, IconButton } from "ReuseableComponents";
import { taskType, useUserRole } from "utils";

interface TeamCardProps {
  image?: string;
  name?: string;
  roles?: string[];
  status?: boolean;
  handleEdit?: (e: MouseEvent<HTMLButtonElement>) => void;
  taskName: [];
  id: string;
  handleCreateConversation: () => void;
}

export const TeamCard = ({
  image,
  name,
  roles,
  status,
  handleEdit,
  taskName,
  id,
  handleCreateConversation,
}: TeamCardProps) => {
  const { userRole } = useUserRole();

  function userEngagedIn() {
    if (taskName?.length > 0) {
      return taskName.map((data: string, i: number) => {
        //@ts-ignore
        let taskTypeData = taskType[data.split(" ").join("_").toUpperCase()];

        return (
          <div
            key={i}
            className="d-flex align-items-center px-2 py-1 mx-1 "
            style={{
              backgroundColor: taskTypeData.bgColor,
              width: "fit-content",
              borderRadius: 4,
            }}
          >
            {taskTypeData.icon}
            <p
              className={`labelThree task-name`}
              style={{
                color: taskTypeData.textColor,
              }}
            >
              {taskTypeData.name.replace(/_/g, " ")}
            </p>
          </div>
        );
      });
    } else {
      return <span className="titleSix mx-2">IDLE </span>;
    }
  }

  const getRoles = () => {
    return roles?.map((data: string, i: number) => {
      //@ts-ignore
      let taskTypeData = taskType[data.split(" ").join("_").toUpperCase()];
      return (
        <div
          key={i}
          className="d-flex align-items-center px-2 py-1 mx-1 mb-2"
          style={{
            backgroundColor: taskTypeData.bgColor,
            width: "fit-content",
            borderRadius: 4,
          }}
        >
          <p
            className={`labelFour task-name`}
            style={{
              color: taskTypeData.textColor,
            }}
          >
            {taskTypeData.name.replace(/_/g, " ")}
          </p>
        </div>
      );
    });
  };

  return (
    <Link
      to={userRole === "ADMIN" ? "#" : `/teams/${id}`}
      className="text-decoration-none"
    >
      <Card className="teamCard border-0">
        <CardBody>
          {/* <button onClick={handleCreateConversation}>chat</button> */}
          <div className="d-flex mb-4">
            <div className="userImgCard me-2">
              {image ? (
                <img alt="team-img" className="userImg me-3 " src={image} />
              ) : (
                <DefaultImg
                  style={{
                    padding: 1,
                    height: 72,
                    width: 72,
                  }}
                />
              )}

              <div className={`userStatus ${status && "active"}`} />
            </div>
            <div className="flex-grow-1">
              <h4 className="bodyTwo">{name}</h4>
              <div className="d-flex flex-wrap">{getRoles()}</div>
            </div>

            <div>
              {userRole === "ADMIN" ? (
                <div>
                  <IconButton className="icon" handleClick={handleEdit}>
                    <img src={eyeIcon} alt="edit" />
                  </IconButton>
                  <IconButton
                    className="icon"
                    handleClick={handleCreateConversation}
                  >
                    <img src={chatIcon} alt="edit" />
                  </IconButton>
                </div>
              ) : (
                <IconButton className="icon" handleClick={handleEdit}>
                  <img src={notesIcon} alt="edit" />
                </IconButton>
              )}
            </div>
          </div>
          <div>
            <span className="labelThree d-flex align-items-center">
              Status: Engaged in {userEngagedIn()}{" "}
            </span>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};
