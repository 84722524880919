import React, { Dispatch, MouseEvent, SetStateAction } from "react";

import { useSearchParams } from "react-router-dom";

import { FormComponent } from "./FormComponent";

interface DetailsComponentProps {
  //   pageFormData: {
  //     page: string;
  //     translateFrom: Array<[]>;
  //     translateTo: Array<[]>;
  //   };
  pageFormData: {
    name: string;
    imageUrl: string;
  }[];
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const DetailsComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: DetailsComponentProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getTabPrams = searchParams.get("tab");
  const getTypeParams = searchParams.get("type");
  const getTaskTypeParams = searchParams.get("taskType");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");

  const handleBtnClick = (
    e: MouseEvent<HTMLButtonElement>,
    designName: string
  ) => {
    e.preventDefault();
    setSearchParams({
      type: designName,
      taskType: getTaskTypeParams || "",
      tab: getTabPrams || "",
      taskId: taskIdParams || "",
      userId: userIdParams || "",
      status: statusParams || "",
    });
  };

  return (
    <div>
      <FormComponent
        pageFormData={pageFormData}
        translationPostData={translationPostData}
        setTranslationPostData={setTranslationPostData}
      />
    </div>
  );
};
