import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import tickSuccess from "assets/icons/tickSuccess.svg";
import { CustomRadioBtn } from "Components";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import {
  getStatusVal,
  splitByCapitalLetter,
  useFileUpload,
  useUserRole,
} from "utils";

interface UploadComponentProps {
  pageFormData?: {
    name: string;
  }[];
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const UploadComponent = ({
  translationPostData,
  pageFormData,
  setTranslationPostData,
}: UploadComponentProps) => {
  const [filePreview, setFilePreview] = useState("");
  const [file, setFile] = useState<File>(new File([], ""));

  const [formList, setFormList] = useState(Array<[]>);

  const [sampleFileUrl, setSampleFileUrl] = useState("");

  const [selectItem, setSelectItem] = useState("");

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const getTypeParams = searchParams.get("type");
  const getTaskTypeParams = searchParams.get("taskType");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParam = searchParams.get("serviceName");

  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const {
    uploadFile,
    uploadData,
    uploadDataLoading,
    deleteFile,
    deleteFileData,
  } = useFileUpload();

  useEffect(() => {
    if (serviceNameParam) {
      let key = splitByCapitalLetter(serviceNameParam);
      let filterData: any = pageFormData?.find(
        (item) => item.name === serviceNameParam
      );

      setFormList(filterData?.upload?.availableSample);
    }
  }, [serviceNameParam, pageFormData]);

  useEffect(() => {
    if (uploadData) {
      setFilePreview(uploadData.uploadFile);
    }
  }, [uploadData]);

  useEffect(() => {
    if (deleteFileData) {
      setFilePreview("");
    }
  }, [deleteFileData]);

  useEffect(() => {
    if (translationPostData.metadata?.upload?.sample) {
      let file = translationPostData.metadata?.upload?.sample[0];

      let findUrl = formList.find((item) => item === file);

      if (findUrl) {
        setSampleFileUrl(file);
        setSelectItem("chooseSample");
      } else {
        setFilePreview(translationPostData.metadata?.upload?.sample[0]);
        setSelectItem("uploadSample");
      }
    }
  }, [formList, translationPostData.metadata?.upload?.sample]);

  const removeFile = (url: string) => {
    deleteFile({
      variables: {
        fileUrl: url,
      },
    }).then((res) => {
      setFilePreview("");
    });
  };

  const handleUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    uploadFile({
      variables: {
        image: e.target.files?.[0],
        prefix: "ORDER",
      },
    });
  };

  const handleContinue = () => {
    // navigate(`${pathname}?taskType=${taskTypeParams}&tab=03`);
    setTranslationPostData({
      ...translationPostData,
      metadata: {
        ...translationPostData.metadata,
        upload: {
          sample: [filePreview ? filePreview : sampleFileUrl],
        },
      },
    });
    navigate(
      `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParam}&tab=03&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ""
      }&isAdmin=${isAdminParams}`
    );
  };

  const handleSelect = (type: string) => {
    if (
      statusParams === "completed" ||
      !getStatusVal(userRole, orderIdParams || "", isAdminParams || "")
    ) {
      return;
    }
    setSelectItem(type);
  };

  return (
    <div>
      <div
        onClick={() => handleSelect("chooseSample")}
        className="cursorPointer"
      >
        <CustomRadioBtn
          title={"Choose a sample"}
          subTitle={
            "Choose a sample that you like from the list of designs we have."
          }
          active={selectItem === "chooseSample"}
          disabled={
            statusParams === "completed" ||
            !getStatusVal(userRole, orderIdParams || "", isAdminParams || "")
              ? true
              : false
          }
        >
          {" "}
          <div className="d-flex flex-row flex-wrap">
            {selectItem === "chooseSample" &&
              formList?.length > 0 &&
              formList.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="mb-3 me-4"
                    onClick={() => setSampleFileUrl(item)}
                    style={{
                      flexBasis: "25%",
                      position: "relative",
                      border: "1px solid #bdbdbd",
                    }}
                  >
                    {sampleFileUrl === item && (
                      <img
                        className="img-fluid"
                        src={tickSuccess}
                        alt="tick"
                        style={{
                          position: "absolute",
                          right: 0,
                        }}
                      />
                    )}

                    <img alt="" src={item} className="img-fluid" />
                  </div>
                );
              })}
          </div>
        </CustomRadioBtn>
      </div>

      <div
        className="cursorPointer mt-4"
        onClick={() => handleSelect("uploadSample")}
      >
        <CustomRadioBtn
          title={"Upload a sample"}
          subTitle={
            "You can upload a sample photo of the design that you want."
          }
          active={selectItem === "uploadSample"}
        >
          {selectItem === "uploadSample" && (
            <PictureUpload
              name="sample upload"
              onChange={handleUploadChange}
              filePreview={filePreview}
              handleDeleteClick={() => removeFile(filePreview)}
              disabled={
                statusParams === "completed" ||
                !getStatusVal(
                  userRole,
                  orderIdParams || "",
                  isAdminParams || ""
                )
                  ? true
                  : false
              }
            />
          )}
        </CustomRadioBtn>
      </div>
      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mb-4 d-flex justify-content-end">
            <CustomButton
              btnName="Continue"
              classNames="px-5"
              handleBtnClick={handleContinue}
            />
          </div>
        )}
    </div>
  );
};
