import { gql } from "@apollo/client";

export const UPDATE_ADMIN_ORDER = gql`
  mutation ($id: String!, $status: OrderStatus, $files: [Upload!]) {
    adminUpdateOrder(id: $id, status: $status, files: $files)
  }
`;

export const REJECT_ADMIN_ORDER = gql`
  mutation ($orderId: String!, $status: OrderStatus!, $rejectReason: String!) {
    rejectCustomerOrder(
      orderId: $orderId
      status: $status
      rejectReason: $rejectReason
    )
  }
`;

export const GET_ADMIN_ORDER_ANALYTICS = gql`
  query {
    getAdminOrderAnalytics {
      change
      message
      percent
      status
      taskCompleted
    }
  }
`;
