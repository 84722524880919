import { gql } from "@apollo/client";

export const LOGIN = gql`
  query (
    $username: String!
    $password: String!
    $loginProvider: LoginProviderType!
  ) {
    login(
      loginInputDto: {
        username: $username
        password: $password
        loginProvider: $loginProvider
      }
    ) {
      access_token
      refresh_token
    }
  }
`;

export const FORGET_PASSWORD_GENERATE_CODE = gql`
  query ($phone: String) {
    forgetPasswordGenerateCode(forgetPasswordInput: { phone: $phone })
  }
`;

export const FORGET_PASSWORD_VERIFY_CODE = gql`
  query ($phone: String, $code: Int!) {
    forgetPasswordVerifyCode(
      verifyForgotPasswordInput: { phone: $phone, code: $code }
    )
  }
`;

export const ME = gql`
  query {
    me {
      id
      firstName
      middleName
      lastName
      userName
      createdDTM
      email
      userType
      confirmed
      phone
      profileImage
      roles
      userAddress {
        banchi
        city
        country
        municipality
        prefecture
        buildingName
        town
        roomNumber
        postalCode
      }
    }
  }
`;

export const SET_PROFILE = gql`
  mutation (
    $firstName: String
    $middleName: String
    $lastName: String
    $image: Upload
    $email: String
    $address: UserAddressInput
  ) {
    setProfile(
      image: $image
      setProfileInput: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        email: $email
        address: $address
      }
    ) {
      firstName
      lastName
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation ($newPassword: String!, $oldPassword: String!) {
    updatePassword(
      updatePasswordInput: {
        newPassword: $newPassword
        oldPassword: $oldPassword
      }
    )
  }
`;

export const SET_PASSWORD_FORGET = gql`
  query ($password: String!, $phone: String, $code: Int) {
    setPasswordAfterForgetPassword(
      resetPasswordInput: { password: $password, code: $code, phone: $phone }
    )
  }
`;
