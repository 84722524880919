import React from "react";

export const OrderImgComponent = ({ image }: { image: string }) => {
  return (
    <div className="orderImgComponent">
      <img className="img-fluid" alt="" src={image} />
      {/* <p className="bodyTwo">payment.jpg</p> */}
    </div>
  );
};
