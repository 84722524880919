import "./styles.scss";

import React from "react";

import timeIcon from "assets/icons/statusIcon/time.svg";

interface InProgressProps {
  classNames?: string;
}

export const InProgress = ({ classNames }: InProgressProps) => {
  return (
    <div
      className={`inProgress labelThree d-flex align-items-center ${classNames}`}
    >
      {" "}
      <img alt="" src={timeIcon} className="me-1" /> In Progress
    </div>
  );
};
