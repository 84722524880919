import React from "react";

import { Card, CardBody } from "reactstrap";
import { OrderImgComponent } from "ReuseableComponents";

interface SampleProps {
  taskData: any;
}

export const Sample = ({ taskData }: SampleProps) => {
  return (
    <Card className="border-0">
      <CardBody className="p-4">
        <h6 className="titleFour">
          {taskData?.metaData?.availableSample?.name}
        </h6>
        <OrderImgComponent
          image={taskData?.metaData?.availableSample?.imageUrl}
        />
      </CardBody>
    </Card>
  );
};
