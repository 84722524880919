import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  ADD_SUPER_ADMIN_USER,
  DELETE_SUPER_ADMIN_USER,
  EDIT_SUPER_ADMIN_USER,
  GET_SUPER_ADMIN_ROLES,
  GET_SUPER_ADMIN_TEAMS,
} from "queries/superAdminTeam";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Label } from "reactstrap";
import {
  CustomButton,
  InputText,
  PasswordInput,
  ReactSelect,
  SidebarModal,
} from "ReuseableComponents";

import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { addTeamSchema, editTeamSchema } from "./validation";

interface inputProps {
  phone: string;
  password: string;
  roles: {
    label: string;
    value: string;
  }[];
}

interface SidebarComponentProps {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  showSidebarEditData?: {
    roles: [];
    id: string;
  };
}

interface optionProps {
  label: string;
  value: string;
}

const defaultValues = {
  phone: "",
  password: "",
};

export const SidebarComponent = ({
  showSidebar,
  setShowSidebar,
  showSidebarEditData,
}: SidebarComponentProps) => {
  //var
  const navigate = useNavigate();

  //state
  const [rolesOption, setRoleOptions] = useState<optionProps[]>([]);

  //query
  const [superadminAddUser, { data: addData, loading: addLoading }] =
    useMutation(ADD_SUPER_ADMIN_USER, {
      errorPolicy: "all",
      refetchQueries: [GET_SUPER_ADMIN_TEAMS],
    });

  const [superadminEditser, { data: editData, loading: editLoading }] =
    useMutation(EDIT_SUPER_ADMIN_USER, {
      errorPolicy: "all",
      refetchQueries: [GET_SUPER_ADMIN_TEAMS],
    });

  const [superadminDeleteUser, { data: deleteData, loading: deleteLoading }] =
    useMutation(DELETE_SUPER_ADMIN_USER, {
      errorPolicy: "all",
      refetchQueries: [GET_SUPER_ADMIN_TEAMS],
    });

  const { data: rolesData } = useQuery(GET_SUPER_ADMIN_ROLES);

  const validationSchema = showSidebarEditData ? editTeamSchema : addTeamSchema;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: inputProps) => {
    if (!showSidebarEditData) {
      superadminAddUser({
        variables: {
          phone: data.phone,
          password: data.password,
          countryCode: "81",
          roles: data.roles.map((data) => data.value),
        },
      });
    } else {
      superadminEditser({
        variables: {
          roles: data.roles.map((data) => data.value),
          userId: showSidebarEditData.id,
        },
      });
    }
  };

  const handleDeleteUser = () => {
    superadminDeleteUser({
      variables: {
        userId: showSidebarEditData?.id,
      },
    });
  };

  useEffect(() => {
    if (rolesData) {
      const option = rolesData?.superadminGetUserRoles?.map((data: string) => {
        return {
          label: data.toLowerCase(),
          value: data,
        };
      });
      setRoleOptions(option);
    }
  }, [rolesData]);

  useEffect(() => {
    if (showSidebarEditData?.roles?.length) {
      const val = showSidebarEditData?.roles?.map((data) => {
        return {
          label: data,
          value: data,
        };
      });
      setValue("roles", val);
    } else {
      //@ts-ignore
      setValue("roles", "");
    }
  }, [showSidebarEditData, setValue]);

  useEffect(() => {
    if (addData) {
      setShowSidebar(false);
      navigate("/teams");
    }

    if (editData) {
      setShowSidebar(false);
      navigate("/teams");
    }
  }, [addData, navigate, setShowSidebar, editData]);

  useEffect(() => {
    if (deleteData) {
      toast.success("Admin Delete Successful");
      setShowSidebar(false);
    }
  }, [deleteData]);

  return (
    <SidebarModal
      showSidebarModal={showSidebar}
      setShowSidebarModal={setShowSidebar}
      title={`${showSidebarEditData ? "Edit Admin" : "New Admin"}`}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!showSidebarEditData ? (
          <>
            <div className="mb-4">
              <Label className="form-label  bodyThree" for="roles">
                Roles
              </Label>
              <Controller
                name="roles"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    isMulti={true}
                    options={rolesOption}
                    field={field}
                    isClearable={true}
                    error={errors.roles?.message}
                    placeholder="Please select the roles"
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputText
                    label="Phone No."
                    placeholder="Enter 10 digit number"
                    field={field}
                    error={errors.phone?.message}
                    invalid={!!errors.phone}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    label="Password"
                    placeholder="Set password"
                    icon={false}
                    field={field}
                    error={errors.password?.message}
                    autoComplete="off"
                  />
                )}
              />
            </div>
          </>
        ) : (
          <div className="mb-4">
            <Label className="form-label  bodyThree" for="roles">
              Roles
            </Label>
            <Controller
              name="roles"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  isMulti={true}
                  options={rolesOption}
                  field={field}
                  isClearable={true}
                  error={errors.roles?.message}
                />
              )}
            />
          </div>
        )}
        <CustomButton
          btnName={`${showSidebarEditData ? "Save Changes" : "Add Client"} `}
          classNames="w-100"
          loading={addLoading || editLoading}
        />
        {showSidebarEditData && (
          <CustomButton
            type="button"
            btnName={`Delete `}
            classNames="w-100 mt-3 tertiary errorColor error-border py-2"
            loading={deleteLoading}
            handleBtnClick={handleDeleteUser}
          />
        )}
      </Form>
    </SidebarModal>
  );
};
