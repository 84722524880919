import { gql } from "@apollo/client";

export const PUBLISH_GLOBAL_NOTIFICATION = gql`
  mutation publishGlobalNotification(
    $image: Upload
    $input: SuperadminCreateGlobalNotificationInput!
  ) {
    publishGlobalNotification(image: $image, globalNotificationInput: $input)
  }
`;
