import React, { ReactNode } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface AdminDashboardProps {
  children: ReactNode;
}

export const AdminDashboardWrapper = ({ children }: AdminDashboardProps) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
};
