import React from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import { splitByCapitalLetter } from "utils";

interface DeliveryProps {
  taskData: any;
}
export const Delivery = ({ taskData }: DeliveryProps) => {
  return (
    <Card className="border-0">
      <CardBody>
        {taskData.metaData?.delivery &&
          Object.keys(taskData.metaData?.delivery).map((item) => {
            if (taskData.metaData?.delivery[item]) {
              if (typeof taskData.metaData?.delivery[item] === "object") {
                return (
                  <div>
                    <h3 className="titleFour mb-2 textCapitalize">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h3>
                    {taskData.metaData?.delivery[item] &&
                    Object.keys(taskData.metaData?.delivery[item]).length >
                      0 ? (
                      <div>
                        {Object.keys(taskData.metaData?.delivery[item]).map(
                          (item) => {
                            return (
                              <Row className="mb-4">
                                <Col md={6}>
                                  <h5 className="textCapitalize me-2">
                                    {splitByCapitalLetter(item)}:{" "}
                                  </h5>
                                </Col>
                                <Col md={6}>
                                  <p>
                                    {" "}
                                    {
                                      taskData.metaData?.delivery[item]?.[item]
                                    }{" "}
                                  </p>
                                </Col>
                              </Row>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              } else {
                return (
                  <Row className="mb-4">
                    <Col md={6}>
                      {" "}
                      <h3 className="titleFour me-4 textCapitalize">
                        {splitByCapitalLetter(item)}
                      </h3>
                    </Col>
                    <Col md={6}>
                      <p className="bodyOne">
                        {taskData.metaData?.delivery[item]}
                      </p>
                    </Col>
                  </Row>
                );
              }
            }
          })}
      </CardBody>
    </Card>
  );
};

const data = {
  object1: {
    address1: "",
    address2: "",
  },
  object2: "",
  object3: "",
};
