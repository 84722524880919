import React, { Dispatch, SetStateAction, useState } from "react";

import eyeIcon from "assets/icons/eye.svg";
import { Table } from "Components";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { IconButton, InProgress, ModalComponent } from "ReuseableComponents";
import {
  addEllipsis,
  adminTaskStatus,
  getTaskStatus,
  getTaskUrl,
  taskType,
} from "utils";

interface TableComponentProps {
  data: Array<[]>;
  loading: boolean;
  rowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  metaData?: {
    limit: number;
    page: number;
    total: number;
  };
  currentPage: number;
}

interface viewModalProps {
  completedAt: string;
  orderNumer: string;
  status: string;
  task: {
    id: string;
    createdAt: string;
    type: string;
    name: string;
  };
  customer: {
    firstName: string;
    lastName: string;
    middleName: string;
  };
}

export const TableComponent = ({
  data,
  loading,
  metaData,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}: // rowsPerPage,
TableComponentProps) => {
  //state
  const [showViewModal, setShowViewModal] = useState(false);
  const [showViewModalData, setShowViewModalData] = useState<viewModalProps>();

  const handleView = (data: viewModalProps) => {
    setShowViewModal(true);
    setShowViewModalData({
      ...data,
    });
  };

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
  };

  //columns
  const columns: Array<object> = [
    {
      name: "id",
      maxWidth: "100px",

      selector: (row: { order: { id: string } }) =>
        addEllipsis(row.order.id, 5),
    },
    {
      name: "category",
      cell: (row: {
        order: {
          task: {
            type: string;
            image: string;
          };
        };
      }) => {
        let taskTypeData =
          //@ts-ignore
          taskType[row.order.task.type.split(" ").join("_").toUpperCase()];

        return (
          <div
            className="d-flex align-items-center px-2 py-1 mx-1"
            style={{
              backgroundColor: taskTypeData.bgColor,
              width: "fit-content",
              borderRadius: 4,
            }}
          >
            {taskTypeData.icon}
            <p
              className={`labelThree task-name`}
              style={{
                color: taskTypeData.textColor,
              }}
            >
              {taskTypeData.name.replace(/_/g, " ")}
            </p>
          </div>
        );
      },
    },

    {
      name: "order date",
      maxWidth: "700px",

      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => {
        return format(new Date(row.createdAt), "dd MMMM, yyyy");
      },
    },
    {
      name: "status",
      center: "true",
      cell: (row: { order: { status: string } }) => {
        //@ts-ignore
        const type = adminTaskStatus[row.order.status];
        return (
          <div
            className="d-flex align-items-center px-2 py-1"
            style={{
              backgroundColor: type?.bgColor,
              borderRadius: 4,
              color: type?.textColor,
            }}
          >
            {type?.icon}
            <p>{type?.name}</p>
          </div>
        );
      },
    },
    {
      name: "actions",
      center: "true",
      cell: (row: {
        order: {
          status: string;
          metaData: any;
          task: { name: string; id: string };
          id: string;
          serviceName: string;
        };
        admin: {
          id: string;
        };
      }) => {
        return (
          <Link
            to={getTaskUrl({
              ...row.order,
              name: row.order.task.name,
              status: getTaskStatus(row.order.status),
              customerId: row.admin?.id,
              taskId: row.order.task?.id,
            })}
          >
            <IconButton className="icon">
              <img src={eyeIcon} alt="view" />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={metaData?.total || 0}
        pagination={true}
        handlePagination={handlePagination}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        variant="secondary"
        showHeader={true}
        name="Order history"
        showSubHeader={false}
        loading={loading}
        setCurrentPage={setCurrentPage}
      />
      <ModalComponent
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        modalTitle="Delete User?"
      >
        <div>
          <p className="bodyTwo mb-3">
            Order Number: {showViewModalData?.orderNumer}
          </p>
          <p className="bodyTwo mb-3">
            Category:{" "}
            <span
              style={{
                padding: "4px 12px",
                backgroundColor:
                  //@ts-ignore
                  taskType[showViewModalData?.task?.name.toLowerCase()]?.color,
              }}
            >
              {
                //@ts-ignore
                taskType[showViewModalData?.task?.name.toLowerCase()]?.icon
              }
              {showViewModalData?.task?.type}
            </span>
          </p>
          <div className="bodyTwo mb-3 d-flex">
            Status: <InProgress classNames="mx-3" />{" "}
          </div>
          <p className="bodyTwo mb-3">
            Client:{" "}
            {`${showViewModalData?.customer?.firstName} ${showViewModalData?.customer?.middleName} ${showViewModalData?.customer?.lastName}`}
          </p>
          {/* <p className="bodyTwo mb-3">
            Handled By: {showViewModalData?.orderNumer}
          </p> */}
          <p className="bodyTwo mb-3">
            Order Date:{" "}
            {showViewModalData?.task?.createdAt &&
              format(
                new Date(showViewModalData?.task?.createdAt || ""),
                "dd MMMM, yyyy"
              )}
          </p>
          <p className="bodyTwo mb-3">
            Completed Date:{" "}
            {showViewModalData?.completedAt &&
              format(
                new Date(showViewModalData?.completedAt || ""),
                "dd MMMM, yyyy"
              )}
          </p>
        </div>
      </ModalComponent>
    </>
  );
};
