import React, { Dispatch, SetStateAction, useEffect } from "react";

import { addMinutes, formatISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
//reactstrap
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import {
  CustomButton,
  DatePicker,
  FilterSearch,
  ReactSelect,
} from "ReuseableComponents";

//react/next

const statusOption = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "DELETED", label: "DELETED" },
  { value: "INACTIVE", label: "INACTIVE" },
];

const orderOption = [
  {
    label: "0 - 10",
    value: "0-10",
  },
  {
    label: "11 - 50",
    value: "11-50",
  },
  {
    label: "51 - 100",
    value: "51-100",
  },
];

interface filterComponentProps {
  setStatus: Dispatch<SetStateAction<string>>;
  setKeyword: Dispatch<SetStateAction<string>>;
  picker: string[];
  setPicker: Dispatch<SetStateAction<string[]>>;
  setOrderRange: Dispatch<SetStateAction<string>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setShowSidebarModal: Dispatch<SetStateAction<boolean>>;
  currentPage: number;
}

interface inputFiledProps {
  status: {
    label: string;
    value: string;
  } | null;
  orders: {
    label: string;
    value: string;
  } | null;
  rowsPerPage: {
    label: string;
    value: string;
  } | null;
  search: string;
}

export const FilterComponent = ({
  setStatus,
  setKeyword,
  picker,
  setPicker,
  setOrderRange,
  setRowsPerPage,
  currentPage,
  setShowSidebarModal,
  setCurrentPage,
}: filterComponentProps) => {
  //var

  //state

  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = () => {
    setShowSidebarModal(true);
  };

  const defaultValues = {
    search: "",
  };

  const { control, watch, setValue } = useForm<inputFiledProps>({
    mode: "onChange",
    defaultValues,
  });

  const handleDateChange = (date: Date[]) => {
    if (date.length === 2) {
      setPicker([
        formatISO(date[0]),

        formatISO(new Date(addMinutes(date[1], 23 * 60 + 59))),
      ]);
    }
  };

  //input field value
  let status = watch("status")?.value;
  const search = watch("search");
  const orderRange = watch("orders")?.value;
  const rowsPerPage = watch("rowsPerPage")?.value;

  useEffect(() => {
    setStatus(status || "");
    setKeyword(search);
    setOrderRange(orderRange || "");

    setRowsPerPage(parseInt(rowsPerPage || "10"));

    setCurrentPage(currentPage);
    setSearchParams({
      status: status || "",
      keyword: search,
      startDate: picker?.[0] || "",
      endDate: picker?.[1] || "",
      orderRange: orderRange || "",
      rowsPerPage: rowsPerPage || "10",
      page: currentPage.toString(),
    });
  }, [
    watch,
    status,
    setStatus,
    setKeyword,
    search,
    setSearchParams,
    picker,
    setOrderRange,
    orderRange,
    rowsPerPage,
    setRowsPerPage,
    currentPage,
    setCurrentPage,
  ]);

  const statusParams = searchParams.get("status");
  const keywordParams = searchParams.get("keyword");
  const endDateParams = searchParams.get("endDate");
  const startDateParams = searchParams.get("startDate");
  const orderRangeParams = searchParams.get("orderRange");

  useEffect(() => {
    if (statusParams) {
      setValue("status", {
        value: statusParams,
        label: statusParams,
      });
    }
    if (keywordParams) {
      setValue("search", keywordParams || "");
    }
    if (endDateParams && startDateParams) {
      setPicker([startDateParams, endDateParams]);
    }
    if (orderRangeParams) {
      setValue("orders", {
        value: orderRangeParams,
        label: orderRangeParams,
      });
    }
  }, [
    statusParams,
    keywordParams,
    setValue,
    endDateParams,
    startDateParams,
    setPicker,
    orderRangeParams,
    setOrderRange,
  ]);

  return (
    <div>
      <Card className="filterComponent border-0 common-card">
        <CardBody>
          <Row className="mb-3 ">
            <Col md={9}>
              <h1 className="titleFour ">Search & Filter</h1>
            </Col>
            <Col md={3}>
              <CustomButton
                btnName="Add"
                classNames={`w-100 `}
                color="primary"
                handleBtnClick={handleClick}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="dateRangeWrapper">
                <label className="mb-2 bodyThree "> Date</label>
                <DatePicker
                  mode={"range"}
                  value={picker}
                  handleDateChange={handleDateChange}
                  placeholder="All"
                  setPicker={setPicker}
                />
              </div>
            </Col>
            <Col className="my-md-0 my-1" md="4">
              <Label className="form-label  bodyThree" for="status">
                Status
              </Label>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    isMulti={false}
                    options={statusOption}
                    field={field}
                    isClearable={true}
                  />
                )}
              />
            </Col>

            <Col className="my-md-0 my-1" md="4">
              <Label className="form-label  bodyThree" for="orders">
                Orders
              </Label>
              <Controller
                name="orders"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    isMulti={false}
                    options={orderOption}
                    field={field}
                    isClearable={true}
                  />
                )}
              />
            </Col>
          </Row>
          <hr className="my-4" />
          <Row>
            <Col md={5} className="d-flex align-items-center ">
              <Label className="form-label m-0 me-3  bodyThree" for="search">
                Search
              </Label>
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <FilterSearch
                    placeholder="Search by name or Id"
                    field={field}
                    classNames="w-100"
                  />
                )}
              />
            </Col>
            <Col
              md={7}
              className="d-flex align-items-center justify-content-end"
            >
              <Label
                className="form-label m-0 me-3  bodyThree"
                for="rowsPerPage"
              >
                Show
              </Label>
              <Controller
                name="rowsPerPage"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    isMulti={false}
                    options={[
                      {
                        label: "10",
                        value: "10",
                      },
                      {
                        label: "20",
                        value: "20",
                      },
                      {
                        label: "50",
                        value: "50",
                      },
                      {
                        label: "100",
                        value: "100",
                      },
                    ]}
                    field={field}
                  />
                )}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
