import React, { Dispatch, SetStateAction } from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { format, formatISO } from "date-fns";
import { Line } from "react-chartjs-2";
import { Card } from "reactstrap";
import { DatePicker } from "ReuseableComponents";

interface DailyOrderChartProps {
  orderCounts: [];
  dailyOrderPicker: string[];
  setDailyOrderPicker: Dispatch<SetStateAction<string[]>>;
}

export const DailyOrderChart = ({
  orderCounts,
  dailyOrderPicker,
  setDailyOrderPicker,
}: DailyOrderChartProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
    // Legend
  );

  const options = {
    responsive: true,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  const data = {
    labels: orderCounts
      ?.map((item) => {
        // @ts-ignore
        return format(new Date(item.createdAt), "dd/MM");
      })
      .reverse(),
    datasets: [
      {
        label: "New Orders",
        data: orderCounts
          ?.map((item) => {
            // @ts-ignore

            return item?.newOrders;
          })
          .reverse(),
        borderColor: "#862DA7",
        backgroundColor: " #862DA7",
      },
      {
        label: "Completed Orders",
        data: orderCounts
          ?.map((item) => {
            // @ts-ignore
            return item?.completedOrders;
          })
          .reverse(),
        borderColor: "#FFA600",
        backgroundColor: "#FFA600",
      },
    ],
  };

  const handleDateOrderChange = (date: Date[]) => {
    if (date.length === 2) {
      setDailyOrderPicker([formatISO(date[0]), formatISO(date[1])]);
    }
  };

  return (
    <Card className="border-0 p-4">
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h2 className="titleFour"> Daily Orders</h2>
        <div className="dateRangeWrapper d-flex align-items-center">
          <label className="me-2 bodyThree "> Date</label>
          <DatePicker
            mode={"range"}
            value={dailyOrderPicker}
            handleDateChange={handleDateOrderChange}
            placeholder="All"
            setPicker={setDailyOrderPicker}
          />
        </div>
        <div className="d-flex">
          <div className="d-flex me-4">
            <div
              style={{
                backgroundColor: "#862DA7",
                height: 24,
                width: 24,
                borderRadius: 4,
                marginRight: 8,
              }}
            />{" "}
            <p>Incoming Orders</p>
          </div>
          <div className="d-flex">
            <div
              style={{
                backgroundColor: "#FFA600",
                height: 24,
                width: 24,
                borderRadius: 4,
                marginRight: 8,
              }}
            />{" "}
            <p>Orders Completed</p>
          </div>
        </div>
      </div>

      <Line options={options} data={data} />
    </Card>
  );
};
