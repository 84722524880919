import React, { useState } from "react";

import { DashboardCard } from "Components/DashboardCard/DashboardCard";
import { addMinutes, formatISO } from "date-fns";
import {
  GET_COUNT_ANALYTICS,
  GET_STAFF_ANALYTICS,
  GET_TASK_ANALYTICS,
  GET_TASK_BY_CATEGORY_ANALYTICS,
  GET_TOTAL_COUNT_ANALYTICS,
} from "queries/analytics";
import { useSearchParams } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { DatePicker } from "ReuseableComponents";

import { useQuery } from "@apollo/client";

import { DailyOrderChart } from "./component/DailyOrderChart/DailyOrderChart";
import { StaffTableComponent } from "./component/StaffTableComponent/StaffTableComponent";
import { TableComponent } from "./component/TableComponent/TableComponent";
import { TaskCategory } from "./component/TaskCategory/TaskCategory";

export const SuperAdminDashboard = () => {
  //var
  const [searchParams] = useSearchParams();
  //state
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "") || 1
  );
  const [picker, setPicker] = useState<string[]>([]);
  const [dailyOrderPicker, setDailyOrderPicker] = useState<string[]>([]);

  const [staffPageNumber, setStaffPageNumber] = useState(1);

  //query
  const { data: countAnalyticsData, loading: countAnalyticsLoading } = useQuery(
    GET_COUNT_ANALYTICS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        dateFilterInput: {
          from: dailyOrderPicker[0],
          to: dailyOrderPicker[1],
        },
      },
    }
  );

  const { data: totalCountAnalyticsData, loading: totalCountAnalyticsLoading } =
    useQuery(GET_TOTAL_COUNT_ANALYTICS, {
      fetchPolicy: "cache-and-network",
    });

  const { data: taskAnalytics, loading: taskAnalyticsLoading } = useQuery(
    GET_TASK_ANALYTICS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        limit: rowsPerPage,
        page: currentPage,
        dateFilterInput: {
          from: picker[0],
          to: picker[1],
        },
      },
    }
  );

  const { data: taskByCategoryData, loading: taskByCategoryLoading } = useQuery(
    GET_TASK_BY_CATEGORY_ANALYTICS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: staffData, loading: staffDataLoading } = useQuery(
    GET_STAFF_ANALYTICS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        page: staffPageNumber,
        limit: 5,
      },
    }
  );

  const handleDateChange = (date: Date[]) => {
    if (date.length === 2) {
      setPicker([
        formatISO(date[0]),
        formatISO(new Date(addMinutes(date[1], 23 * 60 + 59))),
      ]);
    }
  };

  return (
    <div>
      <Row>
        <Col md={10}>
          <div className="chart">
            <DailyOrderChart
              orderCounts={
                countAnalyticsData?.superadminGetCountAnalytics?.data
              }
              dailyOrderPicker={dailyOrderPicker}
              setDailyOrderPicker={setDailyOrderPicker}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="right-content">
            <DashboardCard
              label="Total client"
              growthNumber={` ${totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.clients.change}`}
              totalNumber={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic
                  ?.clients.total
              }
              status={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic
                  ?.clients.status
              }
              className="mb-4"
            />
            <DashboardCard
              label="Admin"
              growthNumber={` ${totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.admins.change}`}
              totalNumber={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.admins
                  .total
              }
              status={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.admins
                  .status
              }
              className="mb-4"
            />
            <DashboardCard
              label="Top Category"
              growthNumber={` ${totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.tasks.change}`}
              totalNumber={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.tasks
                  .total
              }
              status={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.tasks
                  .status
              }
              type="category"
              className="mb-4"
              taskName={
                totalCountAnalyticsData?.superadminGetTotalCountAnalytic?.tasks
                  .name
              }
            />
            {/* <DashboardCard
              label="Total client"
              growthNumber="+12"
              totalNumber="21,909"
              className="mb-4"
            /> */}
          </div>
        </Col>
      </Row>
      <Card className="border-0 p-4 mt-4">
        <Row>
          <Col md={4}>
            <div className="dateRangeWrapper d-flex align-items-center">
              <label className="me-2 bodyThree "> Date</label>
              <DatePicker
                mode={"range"}
                value={picker}
                handleDateChange={handleDateChange}
                placeholder="All"
                setPicker={setPicker}
              />
            </div>
          </Col>
        </Row>
        <TableComponent
          data={taskAnalytics?.superadminGetTasksAnalytic?.data}
          metaData={taskAnalytics?.superadminGetTasksAnalytic?.meta}
          loading={taskAnalyticsLoading}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        <Row>
          <Col md={6}>
            <TaskCategory
              data={taskByCategoryData?.superadminTaskByCatorgiesAnalytic?.data}
            />
          </Col>
          <Col md={6}>
            <StaffTableComponent
              data={staffData?.superadminGetStaffsAnalytic?.data}
              handleChangeNextPage={() =>
                setStaffPageNumber(staffPageNumber + 1)
              }
              handleChangePrevPage={() => {
                if (staffPageNumber === 1) {
                  return;
                }
                setStaffPageNumber(staffPageNumber - 1);
              }}
              loading={staffDataLoading}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
