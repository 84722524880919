import "./styles.scss";

import React, { Dispatch, SetStateAction } from "react";

import OtpInput from "react-otp-input";

interface OtpInputFieldProps {
  otp: number;
  // handleChange: (e) => void;
  setOtp: Dispatch<SetStateAction<number>>;
}

export const OtpInputField = ({ otp, setOtp }: OtpInputFieldProps) => {
  return (
    <div className="otp">
      <OtpInput
        //@ts-ignore
        value={otp}
        //@ts-ignore
        onChange={setOtp}
        numInputs={6}
        shouldAutoFocus={true}
        renderSeparator={<span> </span>}
        renderInput={(props) => (
          <input {...props} type="number" className="opt-input" />
        )}
      />
    </div>
  );
};
