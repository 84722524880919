import React, { useEffect } from "react";

import { UPDATE_PASSWORD } from "queries/authQuery";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form } from "reactstrap";
import { CustomButton, PasswordInput } from "ReuseableComponents";

import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { updatePasswordSchema } from "../../validation";

interface inputProps {
  newPassword: string;
  oldPassword?: string;
  confirmPassword: string;
}

const defaultValues = {
  newPassword: "",
  oldPassword: "",
  confirmPassword: "",
};

export const ChangePassword = () => {
  //query
  const [
    updatePassword,
    {
      data: updatePasswordData,
      loading: updatePasswordLoading,
      error: updatePasswordError,
    },
  ] = useMutation(UPDATE_PASSWORD, {
    errorPolicy: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<inputProps>({
    defaultValues,
    resolver: yupResolver(updatePasswordSchema),
  });

  const onSubmit = (data: inputProps) => {
    updatePassword({
      variables: {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
    });
  };

  useEffect(() => {
    if (updatePasswordData) {
      toast.success("Password updated!");
    }
  }, [updatePasswordData]);

  return (
    <div className="change-password">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="oldPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <PasswordInput
                label="Old Password"
                placeholder="Old Password"
                icon={false}
                field={field}
                error={errors.oldPassword?.message}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <PasswordInput
                label="New Password"
                placeholder="New Password"
                icon={false}
                field={field}
                error={errors.newPassword?.message}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <PasswordInput
                label="Repeat Password"
                placeholder="Repeat Password"
                icon={false}
                field={field}
                error={errors.confirmPassword?.message}
              />
            )}
          />
        </div>
        <CustomButton
          btnName="Change Password"
          classNames="w-100 mt-4"
          loading={updatePasswordLoading}
        />
      </Form>
    </div>
  );
};
