import "./styles.scss";

import React from "react";

import americaIcon from "assets/icons/country/america.svg";
import japanIcon from "assets/icons/country/japan.svg";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

interface TranslationComponentProps {
  details: any;
}

export const TranslationComponent = ({
  details,
}: TranslationComponentProps) => {
  const getCountry = (inputString: string) => {
    return inputString?.toLowerCase() === "japanese"
      ? japanIcon
      : inputString?.toLowerCase() === "english"
      ? americaIcon
      : "";
  };

  return (
    <Card className="border-0 translation">
      <CardBody className="p-4">
        <Row>
          <Col md={6}>
            <div className="field">
              <Label className="bodyTwo">Translation from</Label>
              <div className="fieldInput bodyThree">
                <img
                  alt=""
                  src={getCountry(details.metaData.details.translateFrom.value)}
                  className="img-fluid me-2"
                />
                {details.metaData.details.translateFrom.value}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="field">
              <Label className="bodyTwo">Translation to</Label>
              <div className="fieldInput bodyThree">
                <img
                  alt=""
                  src={getCountry(details.metaData.details.translateTo.value)}
                  className="img-fluid me-2"
                />
                {details.metaData.details.translateTo.value}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <div className="field">
              <Label className="bodyTwo">Pages</Label>
              <div className="fieldInput bodyThree">
                {details.metaData.details.pages}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
