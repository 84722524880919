import "./styles.scss";

import { MouseEvent, useCallback, useEffect, useState } from "react";

import axios from "axios";
import { formatISO, startOfDay } from "date-fns";
import { statuses } from "DndComponents/data";
import { DragItem } from "DndComponents/DragItem/DragItem";
import { DropWrapper } from "DndComponents/DropWrapper/DropWrapper";
import {
  GET_ADMIN_ORDER_ANALYTICS,
  UPDATE_ADMIN_ORDER,
} from "queries/adminOrder";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { CustomButton, ModalComponent } from "ReuseableComponents";
import {
  getFullName,
  getReverseTaskStatus,
  getTaskUrl,
  removeDuplicateArrays,
  useUserContext,
  useUserRole,
} from "utils";

import { useMutation } from "@apollo/client";

import { AdminDashboardWrapper } from "./AdminDashboardWrapper/AdminDashboardWrapper";
import { AddTask } from "./Components/AddTask/AddTask";
import { DashboardCard } from "./Components/DashboardCard/DashboardCard";

export const AdminDashboard = () => {
  //hooks
  const { userRole } = useUserRole();
  const navigate = useNavigate();

  const { user } = useUserContext();

  const [items, setItems] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newDataPage, setNewDataPage] = useState(1);
  const [newDataLoading, setNewDataLoading] = useState(false);
  const [newDataTotal, setNewDataTotal] = useState(0);
  const [progressDataPage, setProgressDataPage] = useState(1);
  const [progressDataLoading, setProgressDataLoading] = useState(false);
  const [progressDataTotal, setProgressDataTotal] = useState(0);

  const [completeDataPage, setCompleteDataPage] = useState(1);
  const [completesDataLoading, setCompleteDataLoading] = useState(false);
  const [completeDataTotal, setCompleteDataTotal] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState({} as any);

  const [adminUpdateOrder, { data: orderUpdateData }] = useMutation(
    UPDATE_ADMIN_ORDER,
    {
      errorPolicy: "all",
    }
  );

  // const [] = useLazyQuery(GET)

  const progressData = useCallback(async () => {
    setProgressDataLoading(true);
    try {
      const getMyOrdersInProgress = await axios.post(
        `/admin/orders/my?paginationInput[limit]=10&paginationInput[page]=${progressDataPage}`,
        {
          status: "IN_PROGRESS",
        }
      );
      setProgressDataTotal(getMyOrdersInProgress.data.meta.total);
      let myOrderDataInProgress = getMyOrdersInProgress.data.data.map(
        (data: {
          order: {
            serviceName: string;
            createdAt: string;
            id: string;
            task: {
              type: string;
              name: string;
              icon: string;
              id: string;
            };
            metaData: any;
            amount: string;
            status: string;
            customer: {
              id: string;
              profileImage: string;
              firstName: string;
              middleName: string;
              lastName: string;
            };
          };
          admin: {
            id: string;
          };
          id: string;
          updatedAt: string;
        }) => {
          return {
            id: data.id,
            customerId: data.order.customer.id,
            taskId: data.id,
            taskName: data.order.task.type.replace(/_/g, " ").toLowerCase(),
            amount: data.order.amount,
            name: data.order.task.name,
            icon: data.order.task.icon,
            customerImg: data.order.customer.profileImage,
            createdAt: data.order.createdAt,
            updatedAt: data.updatedAt,

            customerName: getFullName(
              data.order.customer.firstName || "",
              data.order.customer.middleName || "",
              data.order.customer.lastName || ""
            ),
            orderType: "admin",
            status: "in progress",
            totalItems: getMyOrdersInProgress.data.meta.total,
            serviceName: data.order.serviceName,
            metaData: data.order.metaData,
            isAdmin:
              userRole === "ADMIN"
                ? user?.id === data.admin.id
                  ? true
                  : false
                : false,
          };
        }
      );

      setItems((prevState) => [...prevState, ...myOrderDataInProgress]);
      setProgressDataLoading(false);
    } catch (error) {
      setProgressDataLoading(false);
    }
  }, [progressDataPage, user?.id, userRole]);

  const newData = useCallback(async () => {
    setNewDataLoading(true);
    try {
      const getMyOrdersNew = await axios.get(
        `/admin/orders?paginationInput[limit]=10&paginationInput[page]=${newDataPage}`
      );
      setNewDataTotal(getMyOrdersNew.data.meta.total);

      let ordersData = getMyOrdersNew.data.data.map(
        (data: {
          createdAt: string;
          task: {
            id: string;
            type: string;
            name: string;
            icon: string;
          };
          metaData: any;
          id: string;
          amount: string;
          status: string;
          customer: {
            id: string;
            profileImage: string;
            firstName: string;
            middleName: string;
            lastName: string;
          };
          serviceName: string;
          updatedAt: string;
        }) => {
          return {
            id: data.id,
            taskId: data.task.id,
            customerId: data.customer.id,
            taskName: data.task.type.replace(/_/g, " ").toLowerCase(),
            amount: data.amount,
            name: data.task.name,
            icon: data.task.icon,
            customerImg: data.customer.profileImage,
            customerName: getFullName(
              data.customer.firstName,
              data.customer.middleName,
              data.customer.lastName
            ),
            orderType: "newOrder",
            status: "new",
            totalItems: getMyOrdersNew.data.meta.total,
            serviceName: data.serviceName,
            metaData: data.metaData,
            updatedAt: data.updatedAt,
          };
        }
      );

      setItems((prevState) => [...prevState, ...ordersData]);
      setNewDataLoading(false);
    } catch (error) {
      setNewDataLoading(false);
    }
  }, [newDataPage]);

  useEffect(() => {
    if (progressDataPage) {
      progressData();
    }
  }, [progressDataPage, orderUpdateData, progressData]);

  useEffect(() => {
    if (newDataPage) {
      newData();
    }
  }, [newDataPage, newData]);

  useEffect(() => {
    const progressData = async () => {
      setCompleteDataLoading(true);
      try {
        const getMyOrdersInProgress = await axios.post(
          `/admin/orders/my?paginationInput[limit]=10&paginationInput[page]=${completeDataPage}`,
          {
            dateRange: {
              from: formatISO(startOfDay(new Date()), {
                representation: "complete",
              }),
              to: formatISO(new Date(), { representation: "complete" }),
            },
            status: "DONE",
            // ,
            // "status":"IN_PROGRESS"
            // * Status -> DONE, IN_PROGRESS
          }
        );

        setCompleteDataTotal(getMyOrdersInProgress.data.meta.total);

        let myOrderDataInProgress = getMyOrdersInProgress.data.data.map(
          (data: {
            order: {
              completedAt: string;
              serviceName: string;
              createdAt: string;
              id: string;
              task: {
                id: string;
                type: string;
                name: string;
                icon: string;
              };
              amount: string;
              status: string;
              metaData: any;
              customer: {
                id: string;
                profileImage: string;
                firstName: string;
                middleName: string;
                lastName: string;
              };
            };
            admin: {
              id: string;
            };
            id: string;
            updatedAt: string;
          }) => {
            return {
              id: data.id,
              taskId: data.order.task.id,
              customerId: data.order.customer.id,
              taskName: data.order.task.type.replace(/_/g, " ").toLowerCase(),
              amount: data.order.amount,
              name: data.order.task.name,
              icon: data.order.task.icon,
              customerImg: data.order.customer.profileImage,
              createdAt: data.order.createdAt,
              updatedAt: data.order.completedAt,
              customerName: getFullName(
                data.order.customer.firstName || "",
                data.order.customer.middleName || "",
                data.order.customer.lastName || ""
              ),
              orderType: "admin",
              status: "completed",
              totalItems: getMyOrdersInProgress.data.meta.total,
              serviceName: data.order.serviceName,
              metaData: data.order.metaData,
              isAdmin:
                userRole === "ADMIN"
                  ? user?.id === data.admin.id
                    ? true
                    : false
                  : false,
            };
          }
        );

        setItems((prevState) => [...prevState, ...myOrderDataInProgress]);
        setCompleteDataLoading(false);
      } catch (error) {
        setCompleteDataLoading(false);
      }
    };
    if (completeDataPage && user?.id && userRole) {
      progressData();
    }
  }, [completeDataPage, orderUpdateData, user?.id, userRole]);

  // useEffect(() => {
  //   if (userRole && userRole === "ADMIN") {
  //     fetchData();
  //   }
  // }, [userRole]);

  const onDrop = async (item: any, monitor: any, status: any) => {
    // setItems((prevState) => {
    //   const newItems = prevState
    //     .filter((i) => i.id !== item.id)
    //     .concat({ ...item, status });

    //   return [...newItems];
    // });

    if (status === item.status) {
      return;
    }
    if (status === "new") {
      return toast.error("You cannot move in progress task to new");
    }

    if (status === "completed") {
      setModalData(item);
      return setShowModal(true);
    }

    if (item.status === "new") {
      try {
        setItems((prevState) => {
          const newItems = prevState.filter((i) => i.id !== item.id);
          // .concat({ ...item, status });

          return [...newItems];
        });
        if (item.orderType === "newOrder") {
          const post = await axios.post("/admin/orders", {
            orderId: item.id,
          });

          const getOrderId = await axios.get(`/admin/orders/${post.data.id}`);

          let data = {
            id: getOrderId.data.id,
            taskId: getOrderId.data.order.task.id,
            customerId: getOrderId.data.order.customer.id,
            taskName: getOrderId.data.order.task.type
              .replace(/_/g, " ")
              .toLowerCase(),
            amount: getOrderId.data.order.amount,
            name: getOrderId.data.order.task.name,
            icon: getOrderId.data.order.task.icon,
            customerImg: getOrderId.data.order.customer.profileImage,
            createdAt: getOrderId.data.order.createdAt,
            updatedAt: getOrderId.data.updatedAt,

            customerName: getFullName(
              getOrderId.data.order.customer.firstName || "",
              getOrderId.data.order.customer.middleName || "",
              getOrderId.data.order.customer.lastName || ""
            ),
            orderType: "admin",
            status: "in progress",
            totalItems: progressDataTotal,
            serviceName: getOrderId.data.order.serviceName,
            metaData: getOrderId.data.order.metaData,
            isAdmin: true,
          };

          setItems((prevState) => [...prevState, data]);

          // progressData();
          newData();
        } else {
          adminUpdateOrder({
            variables: {
              id: item.id,
              status: getReverseTaskStatus(status),
            },
          }).then(async () => {});
        }
      } catch (error) {}
    } else {
      adminUpdateOrder({
        variables: {
          id: item.id,
          status: getReverseTaskStatus(status),
        },
        refetchQueries: [GET_ADMIN_ORDER_ANALYTICS],
      }).then(async () => {});
    }
  };

  const moveItem = (dragIndex: any, hoverIndex: any) => {
    const item = items[dragIndex];
    setItems((prevState) => {
      const newItems = prevState.filter((i, idx) => idx !== dragIndex);
      newItems.splice(hoverIndex, 0, item);
      return [...newItems];
    });
  };

  const dragColSelector = document.querySelector("#dragCol");

  const handleScroll = (e?: any, status?: string) => {
    if (
      e.target.scrollHeight - e.target.scrollTop ===
      e.target.offsetHeight - 1
    ) {
      if (status === "in progress") {
        let filterData = items.filter((item) => item.status === status);

        if (filterData.length >= progressDataTotal) {
          return;
        }
        setProgressDataPage(progressDataPage + 1);
      }
      if (status === "completed") {
        let filterData = items.filter((item) => item.status === status);

        if (filterData.length >= completeDataTotal) {
          return;
        }
        setCompleteDataPage(completeDataPage + 1);
      }
      if (status === "new") {
        let filterData = items.filter((item) => item.status === status);

        if (filterData.length >= newDataTotal) {
          return;
        }
        setNewDataPage(newDataPage + 1);
      }
    }
  };

  const handleDone = () => {
    adminUpdateOrder({
      variables: {
        id: modalData.id,
        status: "DONE",
      },
      refetchQueries: [GET_ADMIN_ORDER_ANALYTICS],
    });
    setModalData({});
    setShowModal(false);
    setItems((prevState) => {
      const newItems = prevState
        .filter((i) => i.id !== modalData.id)
        .concat({ ...modalData, status: "completed" });

      return [...newItems];
    });
  };

  const handleAddFiles = () => {
    navigate(getTaskUrl(modalData, "05"));
  };

  useEffect(() => {
    dragColSelector?.addEventListener("scroll", handleScroll);
    return () => {
      dragColSelector?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AdminDashboardWrapper>
      <div className="adminDashboard">
        <Row>
          {statuses.map((s: { color: string; status: string }, id) => {
            return (
              <Col md={3} sm={4} key={id}>
                <div
                  id="dragCol"
                  className="dragCol position-relative"
                  style={{
                    borderTop: `3px  solid ${s.color}`,

                    height: "calc(100vh - 160px)",
                    overflowY: "scroll",
                  }}
                  onScroll={(e: MouseEvent<HTMLDivElement>) =>
                    handleScroll(e, s.status)
                  }
                >
                  <h2
                    className="capsOne mb-2 position-sticky top-0 pt-3"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {" "}
                    {s.status.toUpperCase()}{" "}
                  </h2>

                  {loading ? (
                    ""
                  ) : (
                    <DropWrapper onDrop={onDrop} status={s.status}>
                      <div className="dragCol-col">
                        {items.length > 0 &&
                          removeDuplicateArrays(items)
                            .filter((item: any) => item.status === s.status)
                            .map((item: any, idx: number) => {
                              return (
                                <Link
                                  to={getTaskUrl({
                                    ...item,
                                    isAdmin: s.status !== "new" ? true : false,
                                  })}
                                  key={item.id}
                                  className="text-decoration-none"
                                >
                                  <DragItem
                                    item={item}
                                    index={idx}
                                    moveItem={moveItem}
                                    status={s}
                                  />
                                </Link>
                              );
                            })}
                      </div>
                    </DropWrapper>
                  )}
                  {(s.status === "in progress" && progressDataLoading) ||
                  (s.status === "new" && newDataLoading) ||
                  (s.status === "completed" && completesDataLoading) ? (
                    // <Spinner className="dragCol-loader" />
                    <div className="overlay dragCol-loader" />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            );
          })}
          <Col md={3} sm={6}>
            <div className="mt-4 mt-md-0">
              <DashboardCard />
              {/* <button
                onClick={() => setShow(true)}
                className="mt-3 addTaskManual"
              >
                <img alt="" src={addIcon} className="img-fluid me-2" />
                Add Task Manually
              </button> */}
              <CustomButton
                btnName="Add Task Manually"
                classNames="w-100 mt-3"
                handleBtnClick={() => setShow(true)}
              />
            </div>
          </Col>
        </Row>
        <AddTask showModal={show} setShowModal={setShow} />
      </div>
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        showFooter
        modalTitle="Finish Task?"
        btnPrimaryText="Attach Files"
        btnSecondaryText="Yes"
        btnPrimaryClick={handleAddFiles}
        secPrimaryClick={handleDone}
      >
        <span className="bodyTwo">
          Are you sure you want to finish this task without any attachments?
        </span>
      </ModalComponent>
    </AdminDashboardWrapper>
  );
};
