import "./styles.scss";

import React, { ComponentProps, InputHTMLAttributes } from "react";

import eyeIcon from "assets/icons/eye.svg";
import eyeCloseIcon from "assets/icons/eyeClose.svg";
import security from "assets/icons/security.svg";
import { FormFeedback, Input } from "reactstrap";

interface IPasswordInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  field?: ComponentProps<typeof Input>["field"];
  icon?: ComponentProps<typeof Input>["icon"];
}

export const PasswordInput = ({
  invalid,
  placeholder,
  disabled,
  field,
  label,
  error,
  icon,
}: IPasswordInput) => {
  const [type, setType] = React.useState("password");

  const togglePassword = () => {
    setType(type === "password" ? "text" : "password");
  };

  return (
    <div className="passwordInputWrapper">
      <label className="label bodyTwo">{label}</label>
      <div className="inputField">
        {icon && (
          <img src={security} className="img-fluid inputIcon" alt="security" />
        )}
        <Input
          className={`input ${icon && "iconWithInput"} ${
            error && "errorInput"
          }`}
          type={type}
          invalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
          {...field}
          autoComplete="password"
        />

        {type === "password" ? (
          <img
            src={eyeCloseIcon}
            className="img-fluid eye"
            onClick={togglePassword}
            alt="eye"
          />
        ) : (
          <img
            src={eyeIcon}
            alt="eye"
            className="img-fluid eye"
            onClick={togglePassword}
          />
        )}
      </div>
      {error && <FormFeedback className="d-block">{error}</FormFeedback>}
    </div>
  );
};
