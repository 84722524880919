import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Card, Form, Label } from "reactstrap";
import { CustomButton, InputText, ReactSelect } from "ReuseableComponents";
import { getStatusVal, useUserRole } from "utils";

interface FormComponentProps {
  pageFormData: {
    name: string;
  }[];
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const FormComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: FormComponentProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [formList, setFormList] = useState(Array<[]>);
  const [formValues, setFormValues] = useState({} as any);

  const getTypeParams = searchParams.get("type");

  const getTaskTypeParams = searchParams.get("taskType");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParam = searchParams.get("serviceName");

  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  useEffect(() => {
    if (serviceNameParam) {
      let filterData: any = pageFormData?.find(
        (item) => item.name === serviceNameParam
      );

      setFormList(filterData?.details);

      setFormValues({
        service: "Design Only",
        sides: "Single",
      });
    }
  }, [serviceNameParam, pageFormData]);
  useEffect(() => {
    if (translationPostData?.metadata?.details) {
      setFormValues(translationPostData?.metadata.details);
    }
  }, [translationPostData?.metadata?.details]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]:
        e.target.name === "pieces" ? Number(e.target.value) : e.target.value,
    });
  };

  const handleSelectChange = (e: any, name: string) => {
    if (e?.value) {
      setFormValues({
        ...formValues,
        [name]: e?.value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: "",
      });
    }
  };

  const handleSelectColorChange = (e: any, name: string, key: string) => {
    if (e?.value) {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          [key]: e?.value,
        },
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          [key]: "",
        },
      });
    }
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTranslationPostData({
      ...translationPostData,
      metadata: {
        ...translationPostData.metadata,
        availableSample: {
          name: serviceNameParam,
          imageUrl: "",
        },
        details: formValues,
      },
    });

    navigate(
      `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParam}&tab=02&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ""
      }&isAdmin=${isAdminParams}`
    );
  };

  return (
    <div>
      <Card className="border-0 p-4">
        <div className="mb-4 d-flex">
          <h4 className="titleFive"> {serviceNameParam} </h4>
        </div>
        <Form>
          {formList &&
            Object.keys(formList).length > 0 &&
            Object.keys(formList).map((item, idx) => {
              //@ts-ignore
              let dataFormList = formList[item];
              let inputType = Array.isArray(dataFormList)
                ? "select"
                : //@ts-ignore
                typeof dataFormList === "string" ||
                  typeof dataFormList === "number"
                ? "text"
                : typeof dataFormList === "object" && item === "color"
                ? "color"
                : "";

              switch (inputType) {
                case "text":
                  return (
                    <div className="mb-4" key={idx}>
                      <InputText
                        label={item}
                        placeholder=""
                        name={item}
                        onChange={handleTextChange}
                        value={formValues[item] || ""}
                        disabled={
                          statusParams === "completed" ||
                          !getStatusVal(
                            userRole,
                            orderIdParams || "",
                            isAdminParams || ""
                          ) ||
                          serviceNameParam === "Digital Invitation"
                            ? true
                            : false
                        }
                      />
                    </div>
                  );

                case "select":
                  let optionValue = dataFormList?.map((item: string) => {
                    return {
                      label: item,
                      value: item,
                    };
                  });
                  if (item === "service") {
                  }
                  return (
                    <div className="mb-4" key={idx}>
                      <Label
                        className="form-label text-capitalize  bodyThree"
                        for={item}
                      >
                        {item}
                      </Label>

                      <ReactSelect
                        isMulti={false}
                        options={optionValue}
                        isClearable={true}
                        onChange={(e: any) => handleSelectChange(e, item)}
                        placeholder="Select from options"
                        value={
                          formValues && formValues[item]
                            ? {
                                label: formValues?.[item] || "All",
                                value: formValues?.[item] || "All",
                              }
                            : ""
                        }
                        disabled={
                          statusParams === "completed" ||
                          !getStatusVal(
                            userRole,
                            orderIdParams || "",
                            isAdminParams || ""
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                  );

                case "color":
                  if (serviceNameParam === "Digital Invitation") {
                    return (
                      <>
                        <div className="mb-4" key={idx}>
                          <InputText
                            label={item}
                            placeholder=""
                            name={item}
                            onChange={handleTextChange}
                            value={"Color"}
                            disabled={true}
                          />
                        </div>
                      </>
                    );
                  } else {
                    let colorOptVal = dataFormList?.[formValues?.sides]?.map(
                      (item: string) => {
                        return {
                          label: item,
                          value: item,
                        };
                      }
                    );
                    return (
                      <div className="mb-4" key={idx}>
                        <Label
                          className="form-label text-capitalize  bodyThree"
                          for={item}
                        >
                          {item}
                        </Label>

                        <ReactSelect
                          isMulti={false}
                          options={colorOptVal}
                          isClearable={true}
                          onChange={(e: any) => handleSelectChange(e, item)}
                          placeholder="Select from options"
                          value={
                            formValues && formValues[item]
                              ? {
                                  label: formValues?.[item] || "All",
                                  value: formValues?.[item] || "All",
                                }
                              : ""
                          }
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                    );
                  }
                default:
                  return null;
              }
            })}
          <InputText
            type="textarea"
            label="Notes"
            placeholder="Notes "
            name="note"
            field={{
              rows: 6,
            }}
            onChange={handleTextChange}
            value={formValues["note"] || ""}
            disabled={
              statusParams === "completed" ||
              !getStatusVal(userRole, orderIdParams || "", isAdminParams || "")
                ? true
                : false
            }
          />
        </Form>
      </Card>

      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mt-4 d-flex justify-content-end">
            <CustomButton
              btnName="Continue"
              classNames="px-5"
              handleBtnClick={handleSubmit}
            />
          </div>
        )}
    </div>
  );
};
