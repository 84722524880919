import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Card, Label } from "reactstrap";
import { CustomButton, PictureUpload } from "ReuseableComponents";
import {
  getStatusVal,
  splitByCapitalLetter,
  useFileUpload,
  useUserRole,
} from "utils";

interface UploadComponentProps {
  pageFormData?: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const UploadComponent = ({
  translationPostData,
  pageFormData,
  setTranslationPostData,
}: UploadComponentProps) => {
  const [fileList, setFileList] = useState({} as any);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { userRole } = useUserRole();

  const getTypeParams = searchParams.get("type");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParam = searchParams.get("serviceName");
  const isAdminParams = searchParams.get("isAdmin");

  const { uploadFile, uploadDataLoading, deleteFile } = useFileUpload();

  useEffect(() => {
    if (translationPostData.metadata?.upload) {
      setFileList(translationPostData.metadata?.upload);
    }
  }, [translationPostData.metadata?.upload]);

  const removeFile = (url?: string, parentName?: string, listName?: string) => {
    deleteFile({
      variables: {
        fileUrl: url,
      },
    }).then((res) => {
      let data = fileList;
      if (parentName && listName) {
        data[parentName][listName] = "";
      } else if (parentName && !listName) {
        data[parentName] = "";
      }
      setFileList(data);
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    parentName?: string
  ) => {
    try {
      uploadFile({
        variables: {
          image: e.target.files?.[0],
          prefix: "ORDER",
        },
      }).then((res) => {
        if (parentName) {
          setFileList({
            ...fileList,
            [parentName]: {
              ...fileList[parentName],
              [e.target.name]: res.data?.uploadFile,
            },
          });
        } else {
          setFileList({
            ...fileList,
            [e.target.name]: res.data?.uploadFile,
          });
        }
      });
    } catch (error) {}
  };

  const onSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      setTranslationPostData({
        ...translationPostData,
        metadata: {
          ...translationPostData.metadata,
          upload: fileList,
        },
      });
      navigate(
        `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParam}&tab=03&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
          orderIdParams ? `&orderId=${orderIdParams}` : ``
        }&isAdmin=${isAdminParams}`
      );
    },
    [
      fileList,
      getTypeParams,
      navigate,
      orderIdParams,
      pathname,
      statusParams,
      setTranslationPostData,
      userIdParams,
      translationPostData,
      taskIdParams,
      serviceNameParam,
      isAdminParams,
    ]
  );

  const getUploadDisplay = (item: string) => {
    switch (serviceNameParam) {
      case "Passport (Stole/Lost)":
        return (
          <div>
            {typeof pageFormData.upload[item] === "object" ? (
              <div>
                {" "}
                {item === "citizenship" || item === "passport" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="d-flex">
                      {Object.keys(pageFormData.upload[item]).map(
                        (list, idx) => {
                          return (
                            <div className="w-100 me-3" key={idx}>
                              <PictureUpload
                                name={list}
                                onChange={(e) => handleChange(e, item)}
                                filePreview={fileList?.[item]?.[list]}
                                handleDeleteClick={() =>
                                  removeFile(
                                    fileList?.[item]?.[list],
                                    item,
                                    list
                                  )
                                }
                                disabled={
                                  statusParams === "completed" ||
                                  !getStatusVal(
                                    userRole,
                                    orderIdParams || "",
                                    isAdminParams || ""
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Label className="bodyTwo text-capitalize">
                                {" "}
                                {splitByCapitalLetter(list)}{" "}
                              </Label>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div>
                {item === "policeReport" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="w-100 me-3">
                      <PictureUpload
                        name={item}
                        onChange={(e) => handleChange(e)}
                        filePreview={fileList?.[item]}
                        handleDeleteClick={() =>
                          removeFile(fileList?.[item], item)
                        }
                        disabled={statusParams === "completed" ? true : false}
                      />
                      <Label className="bodyTwo text-capitalize">
                        {" "}
                        {splitByCapitalLetter(item)}{" "}
                      </Label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        );

      case "Passport Renew (Adult)":
        return (
          <div>
            {typeof pageFormData.upload[item] === "object" ? (
              <div>
                {item === "citizenship" || item === "passport" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="d-flex">
                      {Object.keys(pageFormData.upload[item]).map(
                        (list, idx) => {
                          return (
                            <div className="w-100 me-3" key={idx}>
                              <PictureUpload
                                name={list}
                                onChange={(e) => handleChange(e, item)}
                                filePreview={fileList?.[item]?.[list]}
                                handleDeleteClick={() =>
                                  removeFile(
                                    fileList?.[item]?.[list],
                                    item,
                                    list
                                  )
                                }
                                disabled={
                                  statusParams === "completed" ||
                                  !getStatusVal(
                                    userRole,
                                    orderIdParams || "",
                                    isAdminParams || ""
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Label className="bodyTwo text-capitalize">
                                {" "}
                                {splitByCapitalLetter(list)}{" "}
                              </Label>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        );

      case "Passport Renew (Baby)":
        return (
          <div>
            {typeof pageFormData.upload[item] === "object" ? (
              <div>
                {" "}
                {item === "fathersCitizenship" ||
                item === "mothersCitizenship" ||
                item === "passport" ||
                item === "zairyuCard" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="d-flex">
                      {Object.keys(pageFormData.upload[item]).map(
                        (list, idx) => {
                          return (
                            <div className="w-100 me-3" key={idx}>
                              <PictureUpload
                                name={list}
                                onChange={(e) => handleChange(e, item)}
                                filePreview={fileList?.[item]?.[list]}
                                handleDeleteClick={() =>
                                  removeFile(
                                    fileList?.[item]?.[list],
                                    item,
                                    list
                                  )
                                }
                                disabled={
                                  statusParams === "completed" ||
                                  !getStatusVal(
                                    userRole,
                                    orderIdParams || "",
                                    isAdminParams || ""
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Label className="bodyTwo text-capitalize">
                                {" "}
                                {splitByCapitalLetter(list)}{" "}
                              </Label>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div>
                {item === "birthCertificate" ||
                item === "nabalakParichayaPatra" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="w-100 me-3">
                      <PictureUpload
                        name={item}
                        onChange={(e) => handleChange(e)}
                        filePreview={fileList?.[item]}
                        handleDeleteClick={() =>
                          removeFile(fileList?.[item], item)
                        }
                        disabled={statusParams === "completed" ? true : false}
                      />
                      <Label className="bodyTwo text-capitalize">
                        {" "}
                        {splitByCapitalLetter(item)}{" "}
                      </Label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        );

      case "New Passport (Baby)":
        return (
          <div>
            {typeof pageFormData.upload[item] === "object" ? (
              <div>
                {" "}
                {item === "fathersCitizenship" ||
                item === "mothersCitizenship" ||
                item === "zairyuCard" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="d-flex">
                      {Object.keys(pageFormData.upload[item]).map(
                        (list, idx) => {
                          return (
                            <div className="w-100 me-3" key={idx}>
                              <PictureUpload
                                name={list}
                                onChange={(e) => handleChange(e, item)}
                                filePreview={fileList?.[item]?.[list]}
                                handleDeleteClick={() =>
                                  removeFile(
                                    fileList?.[item]?.[list],
                                    item,
                                    list
                                  )
                                }
                                disabled={
                                  statusParams === "completed" ||
                                  !getStatusVal(
                                    userRole,
                                    orderIdParams || "",
                                    isAdminParams || ""
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Label className="bodyTwo text-capitalize">
                                {" "}
                                {splitByCapitalLetter(list)}{" "}
                              </Label>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div>
                {item === "birthCertificate" ||
                item === "marriageCertificate" ? (
                  <div>
                    <h4 className="titleFour text-capitalize mb-3">
                      {" "}
                      {splitByCapitalLetter(item)}{" "}
                    </h4>
                    <div className="w-100 me-3">
                      <PictureUpload
                        name={item}
                        onChange={(e) => handleChange(e)}
                        filePreview={fileList?.[item]}
                        handleDeleteClick={() =>
                          removeFile(fileList?.[item], item)
                        }
                        disabled={statusParams === "completed" ? true : false}
                      />
                      <Label className="bodyTwo text-capitalize">
                        {" "}
                        {splitByCapitalLetter(item)}{" "}
                      </Label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Card className="border-0 p-4">
        {pageFormData?.upload &&
          Object.keys(pageFormData?.upload).map((item, i) => {
            return (
              <div key={i}>
                {getUploadDisplay(item)}
                {/* <h4 className="titleFour text-capitalize mb-3">
                  {" "}
                  {splitByCapitalLetter(item)}{" "}
                </h4>
                <div className="d-flex w-100 justify-content-between">
                  {typeof pageFormData.upload[item] === "string" ? (
                    <div className="w-100 me-3">
                      <PictureUpload
                        name={item}
                        onChange={(e) => handleChange(e)}
                        filePreview={fileList?.[item]}
                        handleDeleteClick={() =>
                          removeFile(fileList?.[item], item)
                        }
                        disabled={statusParams === "completed" ? true : false}
                      />
                      <Label className="bodyTwo">
                        {" "}
                        {splitByCapitalLetter(item)}{" "}
                      </Label>
                    </div>
                  ) : (
                    Object.keys(pageFormData.upload[item]).map((list, idx) => {
                      return (
                        <div className="w-100 me-3" key={idx}>
                          <PictureUpload
                            name={list}
                            onChange={(e) => handleChange(e, item)}
                            filePreview={fileList?.[item]?.[list]}
                            handleDeleteClick={() =>
                              removeFile(fileList?.[item]?.[list], item, list)
                            }
                            disabled={
                              statusParams === "completed" ||
                              !getStatusVal(
                                userRole,
                                orderIdParams || "",
                                isAdminParams || ""
                              )
                                ? true
                                : false
                            }
                          />
                          <Label className="bodyTwo text-capitalize">
                            {" "}
                            {splitByCapitalLetter(list)}{" "}
                          </Label>
                        </div>
                      );
                    })
                  )}
                </div> */}
              </div>
            );
          })}
      </Card>
      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mt-4 d-flex justify-content-end">
            <CustomButton
              btnName="Continue"
              classNames="px-5"
              handleBtnClick={(e: MouseEvent<HTMLButtonElement>) => onSubmit(e)}
            />
          </div>
        )}
    </div>
  );
};
