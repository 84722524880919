import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Card,
  Form,
  Label,
} from 'reactstrap';
import {
  CustomButton,
  InputText,
  ReactSelect,
} from 'ReuseableComponents';
import {
  getStatusVal,
  splitByCapitalLetter,
  useUserRole,
} from 'utils';

interface FormComponentProps {
  pageFormData: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

const provinceOptions = [
  {
    label: "Koshi",
    value: "1",
  },
  {
    label: "Madhesh",
    value: "2",
  },
  {
    label: "Bagmati",
    value: "3",
  },
  {
    label: "Gandaki",
    value: "4",
  },
  {
    label: "Lumbini",
    value: "5",
  },
  {
    label: "Karnali",
    value: "6",
  },
  {
    label: "Sudurpashchim",
    value: "7",
  },
];

export const FormComponent = ({
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: FormComponentProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { userRole } = useUserRole();

  const getTypeParams = searchParams.get("type");

  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const serviceNameParam = searchParams.get("serviceName");
  const isAdminParams = searchParams.get("isAdmin");

  const [formValues, setFormValues] = useState({} as any);

  const [locationData, setLocationData] = useState([] as any);
  const [municipalityData, setMunicipalityData] = useState([] as any);

  const [districtOptions, setDistrictOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [municipalityOptions, setMunicipalityOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const getProvienceDistrict = async () => {
    const response = await axios.get("/nepal-location/provinces/districts");
    setLocationData(response.data);

    const municipalityRes = await axios.get("/nepal-location/municipalities/");

    setMunicipalityData(municipalityRes.data);
  };

  useEffect(() => {
    getProvienceDistrict();
  }, []);

  useEffect(() => {
    if (locationData) {
      setFormValues({
        ...formValues,
        addressInNepal: {
          ...formValues.addressInNepal,
          province: provinceOptions[0].value,
        },
      });
    }
  }, [locationData]);

  useEffect(() => {
    if (formValues?.addressInNepal?.province) {
      setFormValues({
        ...formValues,
        addressInNepal: {
          ...formValues.addressInNepal,
          district: locationData[formValues.addressInNepal.province]?.[0],
        },
      });

      setDistrictOptions(
        locationData?.[formValues.addressInNepal.province]?.map(
          (item: string) => {
            return {
              label: item,
              value: item,
            };
          }
        )
      );
    }
  }, [formValues?.addressInNepal?.province, locationData]);

  useEffect(() => {
    if (formValues?.addressInNepal?.district) {
      setFormValues({
        ...formValues,
        addressInNepal: {
          ...formValues.addressInNepal,

          ["municipality/VDC"]:
            municipalityData[formValues.addressInNepal.district]?.[0],
        },
      });

      setMunicipalityOptions(
        municipalityData?.[formValues.addressInNepal["district"]]?.map(
          (item: string) => {
            return {
              label: item,
              value: item,
            };
          }
        )
      );
    }
  }, [formValues?.addressInNepal?.district, municipalityData]);

  useEffect(() => {
    if (translationPostData?.metadata?.details) {
      setFormValues(translationPostData?.metadata?.details);
    }
  }, [translationPostData?.metadata?.details]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    parentName: string
  ) => {
    setFormValues({
      ...formValues,
      [parentName]: {
        //@ts-ignore
        ...formValues[parentName],
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSelectChange = (e: any, name: string, parentName: string) => {
    if (e?.value) {
      setFormValues({
        ...formValues,
        // [name]: e?.value,
        [parentName]: {
          //@ts-ignore
          ...formValues[parentName],
          [name]: e?.value,
        },
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: "",
      });
    }
  };

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTranslationPostData({
      ...translationPostData,
      metadata: {
        ...translationPostData.metadata,
        availableSample: {
          name: serviceNameParam,
          imageUrl: "",
        },
        details: formValues,
      },
    });

    navigate(
      `${pathname}?type=${getTypeParams}&serviceName=${serviceNameParam}&tab=02&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ``
      }&isAdmin=${isAdminParams}`
    );
  };

  return (
    <div>
      <Card className="border-0 p-4">
        <div className="mb-4 d-flex">
          <h4 className="titleFive"> {serviceNameParam} </h4>
        </div>

        <Form>
          {pageFormData &&
            Object.keys(pageFormData).map((item, idx) => {
              return (
                <div className="mb-3" key={idx}>
                  <h4 className="text-capitalize capsTwo">
                    {" "}
                    {splitByCapitalLetter(item)}{" "}
                  </h4>
                  {item !== "addressInNepal" ? (
                    Object.keys(pageFormData[item]).map((data, i) => {
                      return (
                        <div className="mb-4" key={i}>
                          <InputText
                            label={splitByCapitalLetter(data)}
                            placeholder=""
                            name={data}
                            onChange={(e) => handleChange(e, item)}
                            value={formValues?.[item]?.[data] || ""}
                            disabled={
                              statusParams === "completed" ||
                              !getStatusVal(
                                userRole,
                                orderIdParams || "",
                                isAdminParams || ""
                              )
                                ? true
                                : false
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="mb-4">
                        <Label
                          className="form-label text-capitalize  bodyThree"
                          for={"Province"}
                        >
                          {"Province"}
                        </Label>
                        <ReactSelect
                          isMulti={false}
                          options={provinceOptions}
                          isClearable={false}
                          onChange={(e: any) =>
                            handleSelectChange(e, "province", "addressInNepal")
                          }
                          placeholder="Select from options"
                          value={
                            formValues &&
                            formValues["addressInNepal"]?.["province"]
                              ? {
                                  label:
                                    formValues?.["addressInNepal"]?.[
                                      "province"
                                    ] || "All",
                                  value:
                                    formValues?.["addressInNepal"]?.[
                                      "province"
                                    ] || "All",
                                }
                              : ""
                          }
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <Label
                          className="form-label text-capitalize  bodyThree"
                          for={"District"}
                        >
                          {"District"}
                        </Label>
                        <ReactSelect
                          isMulti={false}
                          options={districtOptions}
                          isClearable={false}
                          onChange={(e: any) =>
                            handleSelectChange(e, "district", "addressInNepal")
                          }
                          placeholder="Select from options"
                          value={
                            formValues &&
                            formValues["addressInNepal"]?.["district"]
                              ? {
                                  label:
                                    formValues?.["addressInNepal"]?.[
                                      "district"
                                    ] || "All",
                                  value:
                                    formValues?.["addressInNepal"]?.[
                                      "district"
                                    ] || "All",
                                }
                              : ""
                          }
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <Label
                          className="form-label text-capitalize  bodyThree"
                          for={"municipality/vdc"}
                        >
                          {"Municipality / vdc"}
                        </Label>
                        <ReactSelect
                          isMulti={false}
                          options={municipalityOptions}
                          isClearable={false}
                          onChange={(e: any) =>
                            handleSelectChange(
                              e,
                              "municipality/VDC",
                              "addressInNepal"
                            )
                          }
                          placeholder="Select from options"
                          value={
                            formValues &&
                            formValues["addressInNepal"]?.["municipality/VDC"]
                              ? {
                                  label:
                                    formValues?.["addressInNepal"]?.[
                                      "municipality/VDC"
                                    ] || "All",
                                  value:
                                    formValues?.["addressInNepal"]?.[
                                      "municipality/VDC"
                                    ] || "All",
                                }
                              : ""
                          }
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <InputText
                          label={"Ward Number"}
                          placeholder=""
                          name={"wardNo"}
                          onChange={(e) => handleChange(e, item)}
                          value={formValues?.[item]?.["wardNo"] || ""}
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <InputText
                          label={"Tole Name"}
                          placeholder=""
                          name={"toleName"}
                          onChange={(e) => handleChange(e, item)}
                          value={formValues?.[item]?.["toleName"] || ""}
                          disabled={
                            statusParams === "completed" ||
                            !getStatusVal(
                              userRole,
                              orderIdParams || "",
                              isAdminParams || ""
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </Form>
      </Card>
      {statusParams !== "completed" &&
        getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
          <div className="mt-4 d-flex justify-content-end">
            <CustomButton
              btnName="Continue"
              classNames="px-5"
              handleBtnClick={(e: MouseEvent<HTMLButtonElement>) => onSubmit(e)}
            />
          </div>
        )}
    </div>
  );
};
