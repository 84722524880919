import "./styles.scss";

import React, { Dispatch, MouseEvent, SetStateAction, useState } from "react";

import { ChevronLeft, X } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModalComponent } from "ReuseableComponents";
import { splitByCapitalLetter } from "utils";

interface OrderModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  pageFormData: any;
  visaPostData: any;
  setVisaPostData: Dispatch<SetStateAction<object>>;
  setVisaPageFormData: any;
  visaPageFormData: any;
}

interface ServiceValueProps {
  name: string;
  services: {
    name: string;
  }[];
  country: {
    name: string;
  }[];
}

interface ServicesTypeProps {
  name: string;
  details?: any;
  type: {
    name: string;
  }[];
}

export const OrderModal = ({
  showModal,
  setShowModal,
  pageFormData,
  visaPostData,
  setVisaPostData,
  setVisaPageFormData,
  visaPageFormData,
}: OrderModalProps) => {
  const [servicesValue, setServicesValue] = useState<ServiceValueProps>();

  const [servicesType, setServicesType] = useState<ServicesTypeProps>();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const statusParams = searchParams.get("status");
  const taskTypeParams = searchParams.get("type");
  const tabParams = searchParams.get("tab");
  const serviceNameParams = searchParams.get("serviceName");
  const serviceTypeParams = searchParams.get("serviceType");
  const serviceTypeListParams = searchParams.get("serviceTypeKList");

  const handleSelectType = (e: MouseEvent<HTMLButtonElement>, name: string) => {
    e.preventDefault();
    let typeData = pageFormData.type.find(
      (item: { name: string }) => item.name === name
    );

    setServicesValue(typeData);
    setSearchParams({
      type: taskTypeParams || "",
      serviceName: name.split(" ").join("") || "",
      tab: tabParams || "",
      taskId: taskIdParams || "",
      userId: userIdParams || "",
      status: statusParams || "",
    });
  };

  const handleSelectService = (
    e: MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    e.preventDefault();

    if (serviceNameParams === "JapanVisa") {
      let serviceData = servicesValue?.services.find(
        (item: any) => item.name === name
      ) as any;
      if (serviceData?.type) {
        setServicesType(serviceData);

        setSearchParams({
          type: taskTypeParams || "",
          serviceName: serviceNameParams || "",
          serviceType: name,
          tab: tabParams || "",
          taskId: taskIdParams || "",
          userId: userIdParams || "",
          status: statusParams || "",
        });

        let data = {
          upload: serviceData.upload,
          details: null,
        };
        if (serviceData.details) {
          data = {
            ...data,
            details: serviceData.details,
          };
        }
        setVisaPageFormData(data);
      }
    } else {
      setSearchParams({
        type: taskTypeParams || "",
        serviceName: serviceNameParams || "",
        serviceType: name,
        tab: tabParams || "",
        taskId: taskIdParams || "",
        userId: userIdParams || "",
        status: statusParams || "",
      });
      if (serviceNameParams) {
        setVisaPostData({
          ...visaPostData,
          metadata: {
            [serviceNameParams.split(" ").join("")]: {
              country: {
                name,
              },
            },
          },
        });
      }
    }
  };

  const handleSelectServiceType = (
    e: MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    e.preventDefault();
    setSearchParams({
      type: taskTypeParams || "",
      serviceName: serviceNameParams || "",
      serviceType: serviceTypeParams || "",
      serviceTypeList: name,
      tab: servicesType?.details ? "01" : "02",
      taskId: taskIdParams || "",
      userId: userIdParams || "",
      status: statusParams || "",
    });
    if (servicesValue && servicesType) {
      setVisaPostData({
        ...visaPostData,
        metadata: {
          [servicesValue.name.split(" ").join("")]: {
            [servicesType.name]: {
              type: {
                name,
              },
            },
          },
        },
      });

      setShowModal(false);
    }
  };

  const handleGoBack = () => {
    if (!serviceNameParams) {
      return;
    } else if (serviceNameParams && !serviceTypeParams) {
      setSearchParams({
        type: taskTypeParams || "",
        tab: tabParams || "",
        taskId: taskIdParams || "",
        userId: userIdParams || "",
        status: statusParams || "",
      });
    } else if (
      serviceNameParams &&
      serviceTypeParams &&
      !serviceTypeListParams
    ) {
      setSearchParams({
        type: taskTypeParams || "",
        serviceName: serviceNameParams || "",
        tab: tabParams || "",
        taskId: taskIdParams || "",
        userId: userIdParams || "",
        status: statusParams || "",
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <ModalComponent
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Select visa type"
      classNames="orderModal"
      showModalTitle={false}
    >
      <div className="mb-4 d-flex justify-content-between w-100">
        <h4 className="titleFour">Select the service you want </h4>
        <X className="cursorPointer" onClick={handleClose} />
      </div>
      {serviceNameParams && (
        <button onClick={handleGoBack} className="bg-transparent border-0">
          <ChevronLeft />
        </button>
      )}
      <div className="d-flex align-items-center py-4 flex-wrap modalWrapper">
        {serviceNameParams === "JapanVisa"
          ? serviceTypeParams && servicesType && servicesType.type.length > 0
            ? servicesType.type.map((item: any) => {
                return (
                  <button
                    className="bg-transparent border-0 p-5 w-100 me-3 boxShadow text-capitalize modalWrapper-btn "
                    onClick={(e) => handleSelectServiceType(e, item.name)}
                  >
                    {item.name}
                  </button>
                );
              })
            : serviceNameParams &&
              servicesValue &&
              servicesValue.services?.length > 0
            ? servicesValue.services.map((item) => {
                return (
                  <button
                    className="bg-transparent border-0 p-5 w-100 me-3 boxShadow text-capitalize modalWrapper-btn "
                    onClick={(e) => handleSelectService(e, item.name)}
                  >
                    {splitByCapitalLetter(item.name)}
                  </button>
                );
              })
            : ""
          : serviceNameParams === "AbroadVisa" &&
            servicesValue &&
            servicesValue.country?.length > 0 &&
            servicesValue.country.map((item: any) => {
              return (
                <button
                  className="bg-transparent border-0 p-5  me-3 boxShadow text-capitalize w-50 modalWrapper-btn "
                  onClick={(e) => handleSelectService(e, item.name)}
                >
                  {splitByCapitalLetter(item.name)}
                </button>
              );
            })}
        {!serviceNameParams &&
          pageFormData?.type.map((item: any) => {
            return (
              <button
                className="bg-transparent border-0 p-5 w-100 me-3 boxShadow text-capitalize modalWrapper-btn "
                onClick={(e) => handleSelectType(e, item.name)}
              >
                {splitByCapitalLetter(item.name)}
              </button>
            );
          })}
      </div>
    </ModalComponent>
  );
};
