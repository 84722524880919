import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import axios from "axios";
import { GET_ADMIN_CLIENTS } from "queries/adminClient";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form, Label } from "reactstrap";
import { CustomButton, ModalComponent, ReactSelect } from "ReuseableComponents";
import { selectDropDownMenu } from "utils";
import * as Yup from "yup";

import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

interface AddTaskProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleAdd?: () => void;
}

interface inputFiledProps {
  category: {
    label: string;
    value: string;
  } | null;
  customer: {
    label: string;
    value: string;
  } | null;
}

type OptionProps = {
  value: string;
  label: string;
};

const validationSchema = Yup.object().shape({
  category: Yup.object().nullable().required("Please select an option"),
  customer: Yup.object().nullable().required("Please select an option"),
});

export const AddTask = ({
  showModal,
  setShowModal,
  handleAdd,
}: AddTaskProps) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  //var
  const navigate = useNavigate();

  //state
  const [customerOption, setCustomerOption] = useState<OptionProps[]>([]);
  const [customerPage, setCustomerPage] = useState(1);
  const [categoryOption, setCategoryOption] = useState<OptionProps[]>([]);

  const [taskData, setTaskData] = useState([] as any);

  //query

  const { data: clientData } = useQuery(GET_ADMIN_CLIENTS, {
    variables: {
      limit: 10,
      page: customerPage,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/admin/tasks");
        let categoryData = await response.data.data.map(
          (item: { name: string; id: string }) => {
            return {
              label: item.name,
              value: item.id,
            };
          }
        );
        setTaskData(response.data.data);
        setCategoryOption(categoryData);
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (clientData) {
      const getMenu = selectDropDownMenu(
        clientData.adminGetClients.data,
        customerOption
      );
      setCustomerOption(getMenu);
    }
  }, [clientData]);

  const handleScroll = () => {
    if (clientData?.adminGetClients?.meta.page >= customerPage) {
      setCustomerPage(customerPage + 1);
    }
  };

  const onHandleSubmit = (data: any) => {
    navigate(
      `/create-order?type=${data.category?.label.toLowerCase()}&tab=01&taskId=${
        data?.category?.value
      }&userId=${data.customer?.value}&status=new`
    );
  };

  // useEffect(() => {
  //   if (watch("category.label")?.toLowerCase() === "visa") {
  //     let filterTaskData = taskData.filter(
  //       (item: any) =>
  //         item.name.toLowerCase() === watch("category.label").toLowerCase()
  //     );
  //   }
  // }, [watch("category"), taskData]);

  return (
    <ModalComponent
      showModal={showModal}
      setShowModal={setShowModal}
      btnPrimaryText="Yes, remove"
    >
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <div className="mb-4">
          <Label className="form-label  bodyThree" for="category">
            Category
          </Label>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <ReactSelect
                isMulti={false}
                options={categoryOption}
                field={field}
                isClearable={true}
                error={errors?.category?.message}
                placeholder="Select from an option"
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Label className="form-label  bodyThree" for="customer">
            Customer
          </Label>
          <Controller
            name="customer"
            control={control}
            render={({ field }) => (
              <ReactSelect
                isMulti={false}
                options={customerOption}
                field={field}
                isClearable={true}
                onMenuScrollToBottom={handleScroll}
                error={errors?.customer?.message}
                placeholder="Select from an option"
              />
            )}
          />
        </div>
        <CustomButton btnName="Add task" classNames="px-5 float-right" />
      </Form>
    </ModalComponent>
  );
};
