import "./styles.scss";

import React from "react";

interface IAuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: IAuthLayoutProps) => {
  return (
    <div className="authLayout">
      <div className="container-fluid h-100">{children}</div>
    </div>
  );
};
