import "./styles.scss";

import React, { ChangeEvent, MouseEvent, useState } from "react";
import ReactDOM from "react-dom";

import uploadArrowIcon from "assets/icons/uploadArrow.svg";
import { XCircle } from "lucide-react";
import { Label, Spinner } from "reactstrap";
import { CustomButton } from "ReuseableComponents/Button/CustomButton";
import { IconButton } from "ReuseableComponents/IconButton/IconButton";
import { ModalComponent } from "ReuseableComponents/Modal/Modal";

interface PictureUploadProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  filePreview?: string;
  loading?: boolean;
  handleReplaceClick?: (fileUrl?: string) => void;
  handleDeleteClick?: (fileUrl?: string) => void;
  disabled?: boolean;
}

interface ModalProps {
  children?: React.ReactNode;
  image: string | undefined;

  handleShowModal: () => void;
}

// Modal component
const Modal = ({ children, image, handleShowModal }: ModalProps) => {
  const modalRoot = document.getElementById("modal-root"); // Assuming there's a div with id 'modal-root' in your HTML

  // if (!modalRoot) {
  //   return null;
  // }

  return ReactDOM.createPortal(
    <div className="modal-portal">
      <div className="modal-portal-content d-flex align-items-center justify-content-center h-100">
        <div className="content-close-btn position-absolute top-0 right-5 ">
          <IconButton handleClick={handleShowModal}>
            <XCircle color="#ffff" size={36} />
          </IconButton>
        </div>
        <img alt="preview-img" src={image} className="img-fluid" />
      </div>
    </div>,
    document.body
  );
};

export const PictureUpload = ({
  onChange,
  name,
  filePreview,
  loading,
  handleReplaceClick,
  handleDeleteClick,
  disabled,
}: PictureUploadProps) => {
  const [showPortal, setShowPortal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const handleViewImage = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPortal(true);
  };

  const handleShowDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(true);
  };

  return (
    <div className="pictureUpload">
      {loading ? (
        <Spinner />
      ) : filePreview ? (
        <div className="filePreview w-full">
          <div className="filePreviewOverlay">
            <div>
              <CustomButton
                btnName="View"
                classNames="secondary px-3 me-4"
                handleBtnClick={handleViewImage}
              />
              <CustomButton
                btnName="Delete"
                classNames="secondary px-3"
                handleBtnClick={!disabled ? handleShowDeleteConfirmModal : null}
              />
            </div>
          </div>
          <img
            alt="file sample"
            className=""
            src={filePreview}
            style={{
              height: 336,
              width: "100%",
            }}
          />
        </div>
      ) : (
        <Label className="border-1 h-100 w-100 d-flex flex-col justify-content-center align-items-center cursorPointer">
          <div className="d-flex flex-column align-items-center">
            <img
              alt="upload"
              src={uploadArrowIcon}
              className="img-fluid "
              style={{
                width: 20,
                height: 20,
              }}
            />
            <p className="labelTwo"> Tap to upload a file </p>
          </div>
          <input
            name={name}
            type="file"
            className="d-none"
            onChange={onChange}
            disabled={disabled}
          />
        </Label>
      )}
      {showPortal && (
        <Modal
          image={filePreview}
          handleShowModal={() => setShowPortal(false)}
        />
      )}

      <ModalComponent
        showModal={showDeleteConfirmModal}
        setShowModal={setShowDeleteConfirmModal}
        showFooter
        modalTitle="Delete File?"
        btnPrimaryText="Yes, remove"
        btnSecondaryText="Go back"
        btnPrimaryClick={() => {
          if (handleDeleteClick) {
            handleDeleteClick();
          }
          setShowDeleteConfirmModal(false);
        }}
      >
        <span className="bodyTwo">
          Are you sure you want to remove this file?
        </span>
      </ModalComponent>
    </div>
  );
};
