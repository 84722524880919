import React, { Dispatch, SetStateAction } from "react";

import { X } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModalComponent } from "ReuseableComponents";
import { splitByCapitalLetter } from "utils";

interface PassportModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  pageFormData: any;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const PassportModal = ({
  showModal,
  setShowModal,
  pageFormData,
  translationPostData,
  setTranslationPostData,
}: PassportModalProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const statusParams = searchParams.get("status");
  const taskTypeParams = searchParams.get("type");
  const tabParams = searchParams.get("tab");

  const navigate = useNavigate();

  const handleSelectType = (e: any, name: string) => {
    setSearchParams({
      type: taskTypeParams || "",
      serviceName: name || "",
      tab: tabParams || "",
      taskId: taskIdParams || "",
      userId: userIdParams || "",
      status: statusParams || "",
    });

    setShowModal(false);
    setTranslationPostData({
      ...translationPostData,
      metadata: {
        availableSample: {
          name: name,
        },
      },
    });
  };

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <ModalComponent
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Select the service you want"
      classNames="orderModal"
      showModalTitle={false}
    >
      <div className="mb-4 d-flex justify-content-between w-100">
        <h4 className="titleFour">Select the service you want </h4>
        <X className="cursorPointer" onClick={handleClose} />
      </div>
      <div className="d-flex align-items-center pb-4 flex-wrap modalWrapper">
        {pageFormData?.availableSample.map((item: any, idx: number) => {
          return (
            <button
              key={idx}
              className="bg-transparent border-0 p-5 w-100 me-3 boxShadow text-capitalize modalWrapper-btn labelThree"
              onClick={(e) => handleSelectType(e, item.name)}
            >
              {splitByCapitalLetter(
                item.name.replace("(", "").replace(")", "")
              )}
            </button>
          );
        })}
      </div>
    </ModalComponent>
  );
};
