import React, { useEffect, useState } from "react";

import axios from "axios";
import { OrderTaskBtn, UserCard } from "Components";
import { format } from "date-fns";
import { GET_ADMIN_CLIENTS_BY_ID } from "queries/adminClient";
import { GET_SUPER_ADMIN_CLIENTS_BY_ID } from "queries/superAdminClient";
import { useSearchParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getFullName, getReverseTaskStatus, useUserRole } from "utils";

import { useQuery } from "@apollo/client";

import { DeliveryComponent } from "../Common/Delivery";
import { FilesComponent } from "../Common/Files";
import { DetailsComponent } from "./Details";
import { PassportModal } from "./PassportModal/PassportModal";
import { PassportTabBar } from "./PassportTabBar/PassportTabBar";
import { PaymentComponent } from "./Payment";
import { UploadComponent } from "./Upload";

interface DesignPageProps {
  pageFormData: {
    availableSample: {
      name: string;
      imageUrl: string;
    }[];
    delivery: object;
    payment: object;
  };
  price: string;
}

export const PassportPage = ({ pageFormData, price }: DesignPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filesUrl, setFilesUrl] = useState<File[]>([]);

  const [translationPostData, setTranslationPostData] = useState({});

  const getTaskTab = searchParams.get("tab");
  const getTaskIdParams = searchParams.get("taskId");
  const getUserIdParams = searchParams.get("userId");
  const statusParams = searchParams.get("status");
  const orderId = searchParams.get("orderId");
  const typeParams = searchParams.get("type");

  const serviceNameParam = searchParams.get("serviceName");

  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  const [showModal, setShowModal] = useState(true);

  const [completedDataVal, setCompletedDataVal] = useState({});

  const [inProgressOrderId, setInProgressOrderId] = useState("");

  const [uploadFiles, setUploadFiles] = useState<Array<[]>>([]);

  //query
  const { data: clientData } = useQuery(
    userRole === "SUPERADMIN"
      ? GET_SUPER_ADMIN_CLIENTS_BY_ID
      : GET_ADMIN_CLIENTS_BY_ID,
    {
      variables: {
        userId: getUserIdParams,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    let postData = {
      taskId: getTaskIdParams,
      customerId: getUserIdParams,
      status: getReverseTaskStatus(statusParams || ""),
      amount: price,
    };

    setTranslationPostData(postData);
  }, [price, getTaskIdParams, getUserIdParams, statusParams]);

  useEffect(() => {
    if (!serviceNameParam) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [serviceNameParam]);

  const orderUrl = () => {
    if (userRole === "SUPERADMIN") {
      return "/superadmin/clients/orders";
    }
    if (
      (userRole === "ADMIN" && statusParams === "new") ||
      isAdminParams === "false"
    ) {
      return "admin/clients/orders";
    }

    return "/admin/orders";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = orderUrl();
        const response = await axios.get(`${url}/${orderId}`);
        const taskData =
          statusParams === "new"
            ? response.data
            : userRole === "SUPERADMIN" || isAdminParams === "false"
            ? response.data
            : response.data.order;

        setSearchParams({
          type: typeParams || " ",
          serviceName: serviceNameParam || "",
          tab: getTaskTab || "",
          taskId: taskData.task.id || "",
          userId: taskData.customer.id || "",
          status: statusParams || "",
          orderId: response.data.id || "",
          isAdmin: isAdminParams || "",
        });

        let postData = {
          taskId: getTaskIdParams,
          customerId: getUserIdParams,
          status: getReverseTaskStatus(statusParams || ""),
          amount: price,
          metadata: taskData.metaData,
        };

        setTranslationPostData(postData);
        setCompletedDataVal({
          admin: response.data.admin,
          updatedAt: response.data.updatedAt,
          createdAt: response.data.createdAt,
          order: taskData,
        });
        setInProgressOrderId(taskData.id);
        setUploadFiles(taskData.files);
      } catch (error) {}
    };
    if (
      orderId &&
      userRole &&
      getTaskIdParams &&
      getUserIdParams &&
      statusParams
      // price
    ) {
      fetchData();
    } else {
    }
  }, [
    orderId,
    getTaskIdParams,
    getUserIdParams,
    statusParams,
    price,
    userRole,
  ]);

  const getKeyData = () => {
    if (userRole === "SUPERADMIN") {
      return "superadminGetClientById";
    } else {
      return "adminGetClientById";
    }
  };

  const renderCompTab = () => {
    switch (getTaskTab) {
      case "01":
        return (
          <DetailsComponent
            pageFormData={pageFormData}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
          />
        );

      case "02":
        return (
          <UploadComponent
            pageFormData={pageFormData}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
          />
        );
      case "03":
        return (
          <DeliveryComponent
            pageFormData={pageFormData?.delivery}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
          />
        );
      case "04":
        return (
          <PaymentComponent
            pageFormData={pageFormData?.payment}
            translationPostData={translationPostData}
            setTranslationPostData={setTranslationPostData}
            inProgressOrderId={inProgressOrderId}
          />
        );
      case "05":
        return (
          <FilesComponent
            filesUrl={filesUrl}
            setFilesUrl={setFilesUrl}
            uploadFiles={uploadFiles}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col md={4}>
            <UserCard
              fullName={getFullName(
                clientData?.[getKeyData()]?.firstName || "",
                clientData?.[getKeyData()]?.middleName || "",
                clientData?.[getKeyData()]?.lastName || ""
              )}
              email={clientData?.[getKeyData()]?.email}
              userId={clientData?.[getKeyData()]?.id}
              joinedDate={clientData?.[getKeyData()]?.createdAt || new Date()}
              address={`${clientData?.[getKeyData()]?.address?.banchi || ""} ${
                clientData?.[getKeyData()]?.address?.country || ""
              }`}
              contact={clientData?.[getKeyData()]?.phone}
              userImage={clientData?.[getKeyData()]?.profileImage}
              joinedCount={
                clientData?.[getKeyData()]?.createdAt
                  ? format(
                      new Date(clientData?.[getKeyData()]?.createdAt || ""),
                      "YYY"
                    )
                  : ""
              }
              totalCount={clientData?.[getKeyData()]?.orderCount}
              userName={clientData?.[getKeyData()]?.userName}
              showActions={false}
              showDelete={userRole === "SUPERADMIN"}
              // editClick={() => navigate(`/clients/${clientId}/edit`)}
              showEdit={true}
              showJoinedCount={true}
              showTodayCount={false}
              showTotalCount={true}
            />
          </Col>
          <Col md={6}>
            {!showModal && (
              <>
                <PassportTabBar />
                {renderCompTab()}
              </>
            )}
          </Col>
          <Col md={2}>
            <OrderTaskBtn
              showRejectBtn={orderId && isAdminParams === "true" ? true : false}
              showStartBtn={
                statusParams === "new" && orderId && isAdminParams === "false"
                  ? true
                  : false
              }
              filesUrl={filesUrl}
              completedDataVal={completedDataVal}
            />
          </Col>
        </Row>
      </div>
      <PassportModal
        showModal={showModal}
        setShowModal={setShowModal}
        pageFormData={pageFormData}
        translationPostData={translationPostData}
        setTranslationPostData={setTranslationPostData}
      />
    </>
  );
};
