import { createUploadLink } from "apollo-upload-client";
import { toast } from "react-toastify";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "utils/localStorage/localStorage";

import {
  ApolloClient,
  ApolloLink,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink();

const appUrl = process.env.REACT_APP_API_URL;

const httpLink = createUploadLink({
  uri: appUrl,
});

const authLink = new ApolloLink((operation, forward) => {
  const tokenValue = getLocalStorage("token");

  const parsedValue = tokenValue ? JSON.parse(tokenValue) : null;
  operation.setContext({
    headers: {
      authorization: parsedValue?.access_token
        ? `Bearer ${parsedValue?.access_token}`
        : "",
      ...operation.getContext().headers,
    },
  });

  return forward(operation);
});

const getNewToken = () =>
  new Promise((resolve, reject) => {
    //get new access token
    // removeLocalStorage("token");
    const tokenValue = getLocalStorage("token");

    const parsedValue = tokenValue ? JSON.parse(tokenValue) : null;

    const newToken = new ApolloClient({
      uri: appUrl,
      cache: new InMemoryCache(),
      headers: {
        authorization: parsedValue?.refresh_token
          ? `Bearer ${parsedValue?.refresh_token}`
          : "",
      },
    });
    newToken
      .query({
        query: gql`
          query {
            refreshAccessToken {
              access_token
              refresh_token
            }
          }
        `,
      })
      .then((res) => {
        resolve(res.data.refreshAccessToken);
        newToken.resetStore();
      })
      .catch((error) => {
        removeLocalStorage("token");
        removeLocalStorage("userType");
        //remove local storage item after refresh token expires
        window.location.href = "/login"; // redirect to logion after

        reject(error);
      });
  });

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.message === "Unauthorized") {
          getNewToken().then((res: unknown) => {
            const { access_token, refresh_token } = res as any;
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            setLocalStorage("token", {
              access_token: access_token,
              refresh_token: refresh_token,
              isAuthenticated: true,
            });
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: access_token ? `Bearer ${access_token}` : "",
              },
            });

            forward(operation); // retry api link
            window.location.reload();
          });
        } else {
          toast.error(err.message);
        }
      }
    }
  }
);

const client = new ApolloClient<NormalizedCacheObject>({
  //@ts-ignore
  link: ApolloLink.from([errorLink, retryLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
