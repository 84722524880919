import React, { Dispatch, SetStateAction, useState } from "react";

import eyeIcon from "assets/icons/eye.svg";
import { Table } from "Components";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { DefaultImg, IconButton, ModalComponent } from "ReuseableComponents";

// import { columns } from "./columns";

interface TableComponentProps {
  data: Array<[]>;
  loading: boolean;
  rowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  metaData?: {
    limit: number;
    page: number;
    total: number;
  };
  currentPage: number;
}

export const TableComponent = ({
  data,
  loading,
  metaData,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}: // rowsPerPage,
TableComponentProps) => {
  //state
  const [showModal, setShowModal] = useState(false);
  const [viewData, setViewData] = useState("");

  const handleView = (data: string) => {
    setViewData(data);
    setShowModal(true);
  };

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
  };

  //columns
  const columns: Array<object> = [
    {
      name: "Date",
      selector: (row: { createdAt: string }) => row.createdAt,
      format: (row: { createdAt: string }) => {
        return format(new Date(row.createdAt), "dd MMMM, yyyy");
      },
    },
    {
      name: "firstName",
      cell: (row: {
        user: {
          firstName: string;
          lastName: string;
          middleName: string;
          profileImage: string;
          id: string;
        };
      }) => {
        return (
          <div className="d-flex align-items-center">
            <Link
              to={`/clients/${row.user.id}`}
              className="d-flex align-items-center text-decoration-none text-secondary"
            >
              {row?.user?.profileImage ? (
                <img
                  src={row?.user?.profileImage}
                  alt=""
                  className="img-fluid"
                  style={{
                    borderRadius: "50%",
                    height: 28,
                    width: 28,
                    marginRight: 12,
                  }}
                />
              ) : (
                <DefaultImg
                  className="p-2 me-2"
                  style={{
                    height: 28,
                    width: 28,
                  }}
                />
              )}

              <p>
                {" "}
                {`${row?.user?.firstName || ""} ${
                  row?.user?.middleName || ""
                } ${row?.user?.lastName || ""} `}{" "}
              </p>
            </Link>
          </div>
        );
      },
    },
    {
      name: "email",
      selector: (row: { user: { email: string } }) => row.user.email,
    },
    {
      name: "message",
      maxwidth: "250px",
      selector: (row: { message: string }) => row.message,
    },
    {
      name: "actions",
      maxwidth: "50px",
      cell: (row: { message: string }) => {
        return (
          <div>
            <IconButton
              className="icon"
              handleClick={() => handleView(row.message)}
            >
              <img src={eyeIcon} alt="view" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        totalCount={metaData?.total || 0}
        pagination={true}
        handlePagination={handlePagination}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        variant="secondary"
        showHeader={false}
        showSubHeader={false}
        loading={loading}
        setCurrentPage={setCurrentPage}
      />
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Feedback"
      >
        <span className="bodyTwo">{viewData}</span>
      </ModalComponent>
    </>
  );
};
