import React, { Dispatch, SetStateAction, useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Form, Label, Row } from "reactstrap";
import { CustomButton, InputText, ReactSelect } from "ReuseableComponents";
import { getStatusVal, useUserRole } from "utils";

interface DetailsComponentProps {
  pageFormData: object;
  translationPostData: any;
  setTranslationPostData: Dispatch<SetStateAction<object>>;
}

export const DetailsComponent = ({
  pageFormData,
  setTranslationPostData,
  translationPostData,
}: DetailsComponentProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const taskTypeParams = searchParams.get("type");
  const taskIdParams = searchParams.get("taskId");
  const userIdParams = searchParams.get("userId");
  const orderIdParams = searchParams.get("orderId");
  const statusParams = searchParams.get("status");
  const isAdminParams = searchParams.get("isAdmin");

  const { userRole } = useUserRole();

  useEffect(() => {
    if (translationPostData) {
      setValue("pages", translationPostData.metadata?.details?.pages);
      setValue(
        "translateFrom",
        translationPostData.metadata?.details?.translateFrom
          ? {
              label: translationPostData.metadata?.details?.translateFrom,
              value: translationPostData.metadata?.details?.translateFrom,
            }
          : {
              label: "Japanese",
              value: "Japanese",
            }
      );
      setValue(
        "translateTo",
        translationPostData.metadata?.details?.translateTo
          ? {
              label: translationPostData.metadata?.details?.translateTo,
              value: translationPostData.metadata?.details?.translateTo,
            }
          : {
              label: "Nepali",
              value: "Nepali",
            }
      );
    } else {
    }
  }, [translationPostData, setValue]);

  useEffect(() => {
    setValue("translateFrom", {
      label: "Japanese",
      value: "Japanese",
    });
    setValue("translateTo", {
      label: "Nepali",
      value: "Nepali",
    });
  }, []);

  const onsubmit = async (data: any) => {
    if (data.pages === "") {
      return toast.error("Page filed value missing");
    }

    setTranslationPostData({
      ...translationPostData,
      metadata: {
        ...translationPostData.metadata,
        details: {
          translateFrom: data?.translateFrom?.value,
          translateTo: data?.translateTo?.value,
          pages: data.pages,
        },
      },
    });
    navigate(
      `${pathname}?type=${taskTypeParams}&tab=02&taskId=${taskIdParams}&userId=${userIdParams}&status=${statusParams}${
        orderIdParams ? `&orderId=${orderIdParams}` : ""
      }&isAdmin=${isAdminParams}`
    );
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onsubmit)}>
        <Card className="border-0 p-4">
          <Row>
            {pageFormData &&
              Object.keys(pageFormData).map((item) => {
                // @ts-ignore
                let translateData = pageFormData[item];
                let inputType = Array.isArray(translateData)
                  ? "select"
                  : "text";

                switch (inputType) {
                  case "select":
                    return (
                      <Col md={6}>
                        <div className="mb-4">
                          <Label
                            className="form-label text-capitalize  bodyThree"
                            for="roles"
                          >
                            {item}
                          </Label>
                          <Controller
                            name={item}
                            control={control}
                            render={({ field }) => {
                              let optionValue = translateData.map(
                                (item: { name: string }) => {
                                  return {
                                    label: item.name,
                                    value: item.name,
                                  };
                                }
                              );
                              return (
                                <ReactSelect
                                  isMulti={false}
                                  options={optionValue}
                                  field={field}
                                  isClearable={true}
                                  error={errors.roles?.message}
                                  disabled={
                                    statusParams === "completed" ||
                                    !getStatusVal(
                                      userRole,
                                      orderIdParams || "",
                                      isAdminParams || ""
                                    )
                                      ? true
                                      : false
                                  }
                                />
                              );
                            }}
                          />
                        </div>
                      </Col>
                    );

                  case "text":
                    return (
                      <div className="mb-4">
                        <Controller
                          name={item}
                          control={control}
                          defaultValue={""}
                          render={({ field }: any) => {
                            return (
                              <InputText
                                label={item}
                                placeholder=""
                                field={field}
                                // error={errors.phone?.message}
                                invalid={!!errors.phone}
                                disabled={
                                  statusParams === "completed" ||
                                  !getStatusVal(
                                    userRole,
                                    orderIdParams || "",
                                    isAdminParams || ""
                                  )
                                    ? true
                                    : false
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              })}
          </Row>
        </Card>
        {statusParams !== "completed" &&
          getStatusVal(userRole, orderIdParams || "", isAdminParams || "") && (
            <div className="mt-4 d-flex justify-content-end">
              <CustomButton btnName="Continue" classNames="px-5" />
            </div>
          )}
      </Form>
    </div>
  );
};
